import React, { Component } from "react";
import Select, { components } from "react-select";
import { MEDIA_BASE_URL } from "./Helper";

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <img
      src={MEDIA_BASE_URL + props.data.icon}
      style={{ width: 35 }}
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
);

export default class SelectWIthIcon extends Component {
  render() {
    return (
      <Select
        closeMenuOnSelect={false}
        isMulti={this.props.isMulti || false}
        options={this.props.options}
        components={{ Option: IconOption }}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        value={this.props.value}
      />
    );
  }
}
