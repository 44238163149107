import React, { Component } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import {
  excel_preview,
  pdf_preview,
  Up_doc_icon_img,
  word_preview,
} from "../../../assets/images";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import { getFileExtension } from "../../../utils/Helper";
import _ from "lodash";
import config from "../../../config/apiHeader";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class ImageProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      lead_hash_id: "",

      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
     image1:"",
     image2:"",
     chessis:"",
     model_class:"",
     mfd:"",
    };
  }

  componentDidMount = async () => {

    
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let { params } = this.props.match;
    
  };

 

  handleDocument = async (e) => {
    e.persist();

    let { image1 } = this.state;
    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg"];
    if (file !== undefined) {
      let extension = getFileExtension(file);
      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        image1=file;
      } else {
        message.error("The file format is not supported");
      }
    }
    this.setState({ image1 });
  };


  handleDocument_two = async (e) => {
    e.persist();

    let { image2 } = this.state;
    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg"];
    if (file !== undefined) {
      let extension = getFileExtension(file);
      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        image2=file;
      } else {
        message.error("The file format is not supported");
      }
    }
    this.setState({ image2 });
  };

  uploadDocuments = () => {
    let {
        image1,
        image2,
      
    } = this.state;

    let formData = new FormData();

    formData.append("image1", image1);
    formData.append("image2", image2);

    if(image1 && image2){
    this.setState({ isLoading: true });
    
    axios
      .post("https://pydevapi.heavymachinerycare.co.in/api/upload_img/", formData, config)
      .then((res) => {
        this.setState({ isLoading: false })
        console.log("response........",res)
        if(res){
            this.setState({ chessis: res.data.Result.chessis });
            this.setState({ model_class: res.data.Result.model_class });
            this.setState({ mfd: res.data.Result.mfd });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
    }else{
        message.error("Please upload two images");
    }
  };

  render() {
    let { chessis, model_class, mfd  } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.payment_invoice;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
               
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel">
                    <div className="ds_header">
                      <h2>Upload Image</h2>
                    </div>

                    <Form.Group>
                      
                 
                    

                       
                          
                            <Form.Control
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={this.handleDocument}
                              
                            />
                             <Form.Control
                             className="mt-4"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={this.handleDocument_two}
                              
                            />
                         
                    
                    </Form.Group>
                    <div className="d-flex sub_container">
                      <button
                        type="submit"
                        className="submit_but"
                        onClick={this.uploadDocuments}
                      >
                        {language.submit_btn}
                      </button>
                    </div>

                    <div className="mt-4">
                        <p>
                        Chessis : <strong>{chessis}</strong><br/> 
                        Model : <strong>{model_class}</strong><br/>
                        MFD : <strong>{mfd}</strong>
                        </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(ImageProcessing);
