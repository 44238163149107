import React, { Component } from "react";
import { Form, Button, Container } from "react-bootstrap";
import "./signup.css";
import { Login_img, user_ico, email_ico } from "../../../assets/images";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import CountryCode from "../../../data/CountryCode";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import validation from "../../../components/Validate/validator";
import AutoCompleteLocation from "../../../utils/AutoCompleteLocation";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import LocationPopup from "../../../utils/LocationPopup";
import { isNumber } from "@amcharts/amcharts5/.internal/core/util/Type";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      country_code: "",
      password: "",
      cpassword: "",
      phoneValue: "",
      error: {},
      isLoading: false,
      temp_id: "",
      label: "",
      address: {},
      isEmail: false,
    };
  }

  componentDidMount = () => {
    let { email, temp_id } = this.props.match.params;
    if (email && !isNaN(temp_id)) {
      let phoneValue = "";
      let isEmail = false;
      if (email.includes("@")) {
        isEmail = true;
      } else {
        isEmail = false;
        phoneValue = email;
        email = "";
      }
      this.setState({ phoneValue, temp_id, isEmail, email });
    } else {
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  handleChange = (e) => {
    e.persist();

    let { error } = this.state;
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    var valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });

    if (name === "password") {
      if (this.state.cpassword && this.state.cpassword !== value) {
        error.cpassword = "Confirm Password should be same as password";
      } else {
        error.cpassword = "";
      }
      this.setState({ error });
    }
  };

  handlePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      this.setState({ phoneValue: value });

      // let mobile=value && formatPhoneNumber(value)
      // let internatonal = value && formatPhoneNumberIntl(value);
      // let countryCode = internatonal.split(" ");
      // let mobile = value.split(countryCode[0]);

      // console.log("mobile-", mobile);
      // console.log("countryCode-", countryCode);
      // console.log("formatPhoneNumber(value)", formatPhoneNumber(value));
      // console.log("isValidPhoneNumber ", isValidPhoneNumber(value));
      // console.log("parsePhoneNumber ", parsePhoneNumber(value));

      let number = parsePhoneNumber(value);
      if (number) {
        let country_code = "+" + number.countryCallingCode;
        let mobile = number.number;
        this.setState({ country_code, mobile });
      }
      if (isValidPhoneNumber(value)) {
        error.mobile = "";
      } else {
        error.mobile = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error.mobile = "";
      this.setState({ error, mobile: "" });
    }
  };

  handleGetLocation = (value) => {
    if (value) {
      let { error } = this.state;
      error.label = "";
      this.setState({
        label: value.label,
        address: value,
        error,
      });
    }
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  signupFormSubmit = (e) => {
    e.preventDefault();
    let { label, error, address } = this.state;
    if (!label) {
      error.label = "Address is required";
      this.setState({ error });
      return;
    } else {
      error.label = "";
      this.setState({ error });
    }

    if (this.isValidForm(error)) {
      const formData = {
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        temp_id: this.state.temp_id,
        address: label,
        lat: address.lat,
        long: address.long,
        state: address.state,
        city: address.city,
        email: this.state.email,
        mobile: this.state.phoneValue,
      };

      this.setState({ isLoading: true });
      axios
        .post("/basic-info-update", formData)
        .then((res) => {
          if (res.data.status === "1") {
            localStorage.setItem("api-token", res.data.data.api_token_web);
            localStorage.setItem("userdata", JSON.stringify(res.data.data));
            this.getProfileDetails(res.data.data, res.data.message);
          } else {
            this.setState({ isLoading: false });
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ isLoading: false });
          message.error("Something went wrong!!");
        });
    }
  };

  getProfileDetails = (userData, msg) => {
    const formData = {
      hash_id: userData.hash_id,
    };
    const config = {
      headers: { api_token: localStorage.getItem("api-token") },
    };

    axios
      .post("/profile-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        message.success(msg);
        if (res.data.status === "1" && res.data.message === "Success") {
          let { media_base_url } = res.data.data;
          localStorage.setItem("media_base_url", media_base_url);
          res.data.data.profile_data.forEach((profile) => {
            if (profile.profile_type === "1") {
              localStorage.setItem("basicProfile", JSON.stringify(profile));
            } else if (profile.profile_type === "2") {
              localStorage.setItem("sellerProfile", JSON.stringify(profile));
            }
          });
        }
        window.location.pathname = "dashboard";
        const timer = setTimeout(() => {
          this.props.history.replace("", null);
        }, 1000);
        clearTimeout(timer);
      })
      .catch(() => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        localStorage.clear();
      });
  };

  render() {
    let { error } = this.state;
    let { loading, language } = this.props.language;

    let mobile_login = {};

    if (!loading && language) {
      mobile_login = language.mobile_login;
      language = language.sign_up;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <LocationPopup show={!localStorage.getItem("country")} />

        <section className="moblie_login_partts">
          <div className="mobile_login_inner_partts">
            <Container>
              <div className="mobile_logininner_box">
                <div className="mobile_box_left">
                  <h2>{mobile_login.welcome_to_hmc}</h2>
                  <p>{mobile_login.welcome_to_hmc_desc}</p>
                  <div className="login_boximg">
                    <img alt="HMC" src={Login_img} />
                  </div>
                </div>
                <div className="mobile_box_right">
                  <div className="mobile_right_inner_box">
                    <h2>{language.sign_up_h}</h2>
                    <Form onSubmit={this.signupFormSubmit}>
                      <Form.Group
                        className="login_box_partts"
                        controlId="firstname"
                      >
                        <Form.Label>
                          {language.label_first_name}
                          <span className="required">*</span>
                        </Form.Label>
                        <div className="react_textfield">
                          <Form.Control
                            type="text"
                            className="control_field"
                            placeholder={language.plh_enter_your_first_name}
                            name="firstname"
                            value={this.state.firstname}
                            required
                            validaterule={["required", "isName"]}
                            validatemsg={["Fisrt name is required"]}
                            onChange={this.handleChange}
                          />
                          <p className="invalid_input">
                            {error.firstname &&
                              language.errormsg_first_name_is_required}
                          </p>
                          <span className="text_111">
                            <img alt="HMC" src={user_ico} />
                          </span>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="login_box_partts"
                        controlId="lastname"
                      >
                        <Form.Label>
                          {language.label_last_name}
                          <span className="required">*</span>
                        </Form.Label>
                        <div className="react_textfield">
                          <Form.Control
                            type="text"
                            className="control_field"
                            placeholder={language.plh_enter_your_last_name}
                            name="lastname"
                            value={this.state.lastname}
                            required
                            validaterule={["required", "isName"]}
                            validatemsg={["Last name is required"]}
                            onChange={this.handleChange}
                          />
                          <p className="invalid_input">
                            {error.lastname &&
                              language.errormsg_last_name_is_required}
                          </p>
                          <span className="text_111">
                            <img alt="HMC" src={user_ico} />
                          </span>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="login_box_partts"
                        controlId="mobile"
                      >
                        <Form.Label>
                          {language.label_phone_number}
                          {!this.state.isEmail && (
                            <span className="required">*</span>
                          )}
                        </Form.Label>
                        <PhoneInput
                          className="phone_field"
                          defaultCountry="IN"
                          placeholder={language.plh_enter_your_mobile_number}
                          countries={CountryCode}
                          value={this.state.phoneValue}
                          onChange={this.handlePhoneChange}
                          disabled={!this.state.isEmail}
                        />
                        <p className="invalid_input">
                          {error.mobile &&
                            language.errormsg_enter_valod_mobile_number}
                        </p>
                      </Form.Group>

                      <Form.Group
                        className="login_box_partts"
                        controlId="email"
                      >
                        <Form.Label>
                          {language.label_email}
                          {this.state.isEmail && (
                            <span className="required">*</span>
                          )}
                        </Form.Label>
                        <div className="react_textfield">
                          <Form.Control
                            type="email"
                            className="control_field"
                            placeholder={language.plh_enter_your_email}
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            readOnly={this.state.isEmail}
                          />
                          <p className="invalid_input">{error.email}</p>
                          <span className="text_111">
                            <img alt="HMC" src={email_ico} />
                          </span>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="login_box_partts"
                        controlId="label"
                      >
                        <Form.Label>
                          {language.label_address}
                          <span className="required">*</span>
                        </Form.Label>
                        <div className="react_textfield">
                          <AutoCompleteLocation
                            handleGetLocation={this.handleGetLocation}
                            value={this.state.label}
                          />

                          <p className="invalid_input">{error.label}</p>
                        </div>
                      </Form.Group>

                      <Button
                        type="submit"
                        className="login_submit_but commn_button"
                      >
                        {language.sign_up_btn}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(Signup);
