import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import { feedback_icon, Back_Arrow } from "../../../assets/images";
import "./feedback.css";
import { css } from "emotion";
// import ScrollToBottom from "react-scroll-to-bottom";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import { LeadTypes } from "../../../utils/LeadTypes";

export default class FeedBackHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      isLoading: false,

      call_from: "",
      dashboard_id: "",
      lead_assignee_act_id: "",
      lead_id: "",
      feedback_list: [],
    };
  }

  componentDidMount = async () => {
    //window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    let { params } = this.props.match;
    if (params) {
      let { call_from, dashboard_id, lead_assignee_act_id, lead_id } = params;
      await this.setState({
        call_from,
        dashboard_id,
        lead_assignee_act_id,
        lead_id,
      });
      this.getFeedbackHistory();
    }
  };

  getFeedbackHistory = () => {
    this.setState({ isLoading: true });

    let { hash_id, call_from, dashboard_id, lead_assignee_act_id, lead_id } =
      this.state;

    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      user_hash_id: hash_id,
      lead_assignee_act_id,
      call_from,
      dashboard_id,
      lead_id,
      medium: "web",
      section_name: "lm_get_all_feedback_history",
    };

    axios
      .post("/get-lead-feedback-history", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { feedback_list } = res.data.data;
          this.setState({ feedback_list });
          if (feedback_list.length <= 0) {
            message.warning("No Feedback Found");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  callBacktoParent = () => {
    if (this.props.location.state) {
      switch (this.props.location.state.lead_types) {
        case LeadTypes.MY_ASSIGN_LEAD:
          this.props.history.push({
            pathname: "/my-assigned-lead",
            state: {
              currentPage: this.props.location.state.currentPage,
            },
          });
          break;
        case LeadTypes.MY_LEAD:
          this.props.history.goBack();
          break;
        case LeadTypes.PRE_ASSIGN_LEAD:
          this.props.history.push({
            pathname: "/all-leads",
            state: {
              tabkey: "preassigned",
              currentPage: this.props.location.state.currentPage,
            },
          });
          break;
        case LeadTypes.REVIEW_LEAD:
          this.props.history.push({
            pathname: "/all-leads",
            state: {
              tabkey: "review",
              currentPage: this.props.location.state.currentPage,
            },
          });
          break;
        case LeadTypes.ASSIGN_LEAD:
          this.props.history.push({
            pathname: "/all-leads",
            state: {
              tabkey: "assigned",
              currentPage: this.props.location.state.currentPage,
            },
          });
          break;
        default:
          // TODO:GOTO HOME PAGE
          break;
      }
    } else {
      // TODO:GOTO HOME PAGE
    }
  };

  render() {
    let { feedback_list } = this.state;
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12">
                  <div className="status_inner_partts">
                    <div className="comn_lead_heading d-flex align-center">
                      <span className="mr-3 pt-1 back_arrow">
                        <img
                          alt="HMC"
                          src={Back_Arrow}
                          onClick={this.callBacktoParent}
                        />
                      </span>
                      <h2>Feedback History</h2>
                    </div>
                    {/* <ScrollToBottom className={ROOT_CSS}> */}
                    <div className="added_parts">
                      {feedback_list &&
                        feedback_list.map((feedback, index) => {
                          let create_date = moment(feedback.created_at).format(
                            "Do MMM YYYY - h:mm A"
                          );
                          return (
                            <div className="added_inner_partts" key={index}>
                              <div className="added_inner_parts_heading">
                                <div className="add_heading_inner">
                                  <span>
                                    <span className="feed_back_icon">
                                      <img alt="HMC" src={feedback_icon} />
                                    </span>
                                    {feedback.creator_name} added on
                                  </span>
                                  <span>{create_date}</span>
                                </div>
                              </div>

                              <div className="added_inner_cont">
                                <div className="added_inner_heading">
                                  {feedback.subject}
                                </div>
                                <div className="added_tag_partts">
                                  {feedback.feedback_tags &&
                                    feedback.feedback_tags.map((tag, key) => {
                                      return (
                                        <div
                                          className="tagger"
                                          key={key}
                                          style={{
                                            background: tag.tag_back_color,
                                            color: tag.tag_title_color,
                                          }}
                                        >
                                          {tag.tag_title}
                                        </div>
                                      );
                                    })}
                                </div>
                                <div className="doc_attached">
                                  {feedback.attachments.length > 0 && (
                                    <>
                                      <h2>Doc Attached :</h2>
                                      <ol className="doc_attached_inner">
                                        {feedback.attachments.map((doc, kk) => {
                                          return (
                                            <li key={kk}>
                                              <a
                                                href={doc.attachment_url}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <label className="doc_title">
                                                  {doc.attachment_title}
                                                </label>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ol>

                                      {/* <div className="doc_attached_inner">
                                          

                                          <div className="doc_attached_part">
                                            <div>
                                              <img src={word_preview} />
                                            </div>
                                            <label>Certificate</label>
                                          </div>

                                          <div className="doc_attached_part">
                                            <div>
                                              <img src={excel_preview} />
                                            </div>
                                            <label>Certificate</label>
                                          </div>
                                        </div> */}
                                    </>
                                  )}
                                  {feedback.comments && (
                                    <div className="feed_comnts">
                                      <h2>Comments :</h2>

                                      <div className="feedback_comment mb-3 ">
                                        {feedback.comments}
                                      </div>
                                    </div>
                                  )}

                                  <div className="feed_comnts">
                                    <h2>
                                      {feedback.contact_person && (
                                        <>
                                          Contact Person :-{" "}
                                          {feedback.contact_person}
                                        </>
                                      )}
                                      <span className="ml-5">
                                        {feedback.financer_name && (
                                          <span>
                                            Financer Name :-{" "}
                                            {feedback.financer_name}
                                          </span>
                                        )}
                                      </span>
                                    </h2>
                                  </div>

                                  <div className="feed_comnts">
                                    <h2>
                                      {feedback.date && (
                                        <>Date :- {feedback.date}</>
                                      )}
                                      <span className="ml-5">
                                        {feedback.disbrusment_sub_status && (
                                          <span>
                                            Sub status :-{" "}
                                            {feedback.disbrusment_sub_status ===
                                            "1"
                                              ? "Waiting"
                                              : "Done"}
                                          </span>
                                        )}
                                      </span>
                                    </h2>
                                  </div>

                                  {feedback.assignee_name && (
                                    <div className="assign_to">
                                      {feedback.creator_name} assign to{" "}
                                      {feedback.assignee_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {/* </ScrollToBottom> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}

const ROOT_CSS = css({
  height: 500,
  //width: 400
});
