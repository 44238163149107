import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { ToastContainer } from "react-toastify";

export default function layout({ children }) {
  return (
    <>
      <Header />
      <ToastContainer
        className="Toastify__toast-container"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
      <Footer />
    </>
  );
}
