import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  Back_Arrow,
  cross_preview,
  excel_preview,
  pdf_preview,
  word_preview,
  Upload_img_icon_img,
  Up_doc_icon_img,
  prof_icon_menu,
} from "../../../assets/images";
import "./timeline.css";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import moment from "moment";
import config from "../../../config/apiHeader";
import LoadingOverlay from "react-loading-overlay";
import { LeadTypes } from "../../../utils/LeadTypes";
import CustomPopup from "../../../utils/CustomPopup";
import ReactDatePicker from "react-datepicker";
import { PlaceHolder } from "../../../utils/PlaceHolder";
import _ from "lodash";
import { MEDIA_BASE_URL, getFileExtension } from "../../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_hash_id: "",
      isLoading: false,

      call_from: "",
      dashboard_id: "",
      lead_assignee_act_id: "",
      lead_id: "",
      all_report: [],
      lead_basic_details: {},

      lead_header: [],
      financer_list: [],
      contact_list: [],
      contact_person_list: [],

      // EDIT TIMELINE FILEDS
      heading_type: -1,
      get_sub_header: [],
      editTimelinePopup: false,
      appac_no: "",
      approved_amount: "",
      asking_amount: "",
      asset_value: "",
      assign_to: "",
      assignee_name: "",
      attachments: [],
      brokerage_rate: "",
      cloasing_date: "",
      comments: "",
      contact_person: "",
      contact_person_id: "",
      creator_id: "",
      creator_name: "",
      date: "",
      disbrusment_sub_status: "",
      disbrust_amount: "",
      docs_label: "",
      down_payment: "",
      feedback_tags: [],
      financer_contact_id: "",
      financer_name: "",
      hash_id: "",
      hmc_percentage: "",
      id: "",
      loan_interest: "",
      notify_by: "",
      status: "",
      sub_header_id: "",
      sub_header_title: "",
      subject: "",
      sub_status: "",
      select_contact_person: "",
      select_financer_name: "",

      error: {},

      // EDIT DOCUMENTS
      old_attachment: [],
      old_images: [],
      old_docs: [],
      isAddedDocument: false,
      equipment_images: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        user_hash_id: userdata.hash_id,
      });
    }

    let { params } = this.props.match;
    if (params) {
      let { call_from, dashboard_id, lead_assignee_act_id, lead_id } = params;
      this.setState(
        {
          call_from,
          dashboard_id,
          lead_assignee_act_id,
          lead_id,
        },
        async () => {
          let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
          if (sub_menus) {
            this.getFeedbackHistory();

            this.interval = setInterval(() => {
              let data = localStorage.getItem("notification_lead_details");
              if (data) {
                let newValue = JSON.parse(data);
                if (parseInt(lead_id) === newValue.lead_id) {
                  let { all_report } = this.state;
                  all_report.unshift(newValue);
                  this.setState({ all_report });
                }
              }
              localStorage.removeItem("notification_lead_details");
            }, 1500);
          } else {
            message.error(
              "Access Denied: Your account does not have sufficient permissions to perform the requested action."
            );
            this.props.history.push({
              pathname: "/",
            });
          }

          // window.addEventListener("storage", (event) => {
          //   console.error("AVIJIT SAMANTA EVENT ", event);
          //   let { newValue } = event;
          //   if (newValue) {
          //     newValue = JSON.parse(newValue);
          //     if (lead_id == newValue.lead_id) {
          //       console.error("IN IF ", newValue.lead_id);

          //       let { all_report } = this.state;
          //       all_report.unshift(newValue);
          //       this.setState({ all_report });
          //     }
          //   }
          // });
        }
      );
    }

    this.getLeadHeaderList();
    this.getContactList();
    this.getFinancerList();
    this.getContactPersonList();
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getFeedbackHistory = () => {
    this.setState({ isLoading: true });

    let {
      user_hash_id,
      call_from,
      dashboard_id,
      lead_assignee_act_id,
      lead_id,
    } = this.state;

    const formData = {
      user_hash_id,
      lead_assignee_act_id,
      call_from,
      dashboard_id,
      lead_id,
      medium: "web",
      section_name: "lm_get_all_feedback_history",
    };

    axios
      .post("/get-lead-feedback-history", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { daily_report_list, lead_basic_details, lead_all_document } =
            res.data.data;

          let feedback_list = [];

          res.data.data.feedback_list.forEach((feadback) => {
            if (
              feadback.only_admin_view === 0 ||
              feadback.creator_id == localStorage.getItem("lead_user") ||
              localStorage.getItem("lead_user_role") === "1"
            ) {
              feedback_list.push(feadback);
            }
          });

          let all_report = feedback_list.concat(daily_report_list);

          all_report = all_report.concat(lead_all_document);

          all_report.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          this.setState({ all_report, lead_basic_details });
          if (all_report.length <= 0) {
            message.warning("No Report Found");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getLeadHeaderList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_timeline_header_list",
    };

    axios
      .post("/lead-header-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let lead_header = [];
          res.data.data.lead_header.forEach((header) => {
            lead_header.push({
              label: header.header_label,
              value: header.id,
              header,
            });
          });
          this.setState({ lead_header });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getContactList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_assigned_get_contact_list",
    };

    axios
      .post("/contact-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let contact_list = res.data.data.map((contact) => {
            return {
              label:
                contact.contact_type === "1"
                  ? contact.first_name + " " + contact.last_name
                  : contact.company_name,
              value: contact.user_id,
              contact,
            };
          });
          this.setState({ contact_list });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getFinancerList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_timeline_get_financer_list",
    };

    axios
      .post("/get-financer-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ financer_list: res.data.data });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getContactPersonList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_timeline_get_contact_person",
    };
    axios
      .post("/get-contact-person-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let contact_person_list = res.data.data.map((person) => {
            return {
              name: person.first_name + " " + person.last_name,
              id: person.id,
            };
          });

          this.setState({ contact_person_list });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  callBacktoParent = () => {
    if (this.props.location.state) {
      switch (this.props.location.state.lead_types) {
        case LeadTypes.MY_ASSIGN_LEAD:
          this.props.history.push({
            pathname: "/my-assigned-lead",
            state: {
              currentPage: this.props.location.state.currentPage,
              formData: this.props.location.state.formData,
            },
          });
          break;
        case LeadTypes.MY_LEAD:
          this.props.history.goBack();
          break;
        case LeadTypes.PRE_ASSIGN_LEAD:
          this.props.history.push({
            pathname: "/all-leads",
            state: {
              tabkey: "preassigned",
              currentPage: this.props.location.state.currentPage,
              formData: this.props.location.state.formData,
            },
          });
          break;
        case LeadTypes.REVIEW_LEAD:
          this.props.history.push({
            pathname: "/all-leads",
            state: {
              tabkey: "review",
              currentPage: this.props.location.state.currentPage,
              formData: this.props.location.state.formData,
            },
          });
          break;
        case LeadTypes.ASSIGN_LEAD:
          this.props.history.push({
            pathname: "/all-leads",
            state: {
              tabkey: "assigned",
              currentPage: this.props.location.state.currentPage,
              formData: this.props.location.state.formData,
            },
          });
          break;
        default:
          // TODO:GOTO HOME PAGE
          break;
      }
    } else {
      // GOTO HOME PAGE
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  setEditTimelineDetails = (timeline) => {
    let { lead_header, contact_person_list, financer_list } = this.state;

    for (const [key, value] of Object.entries(timeline)) {
      this.setState({ [key]: value ? value : "" });
      if (key === "subject") {
        lead_header.forEach((header) => {
          if (header.label === value) {
            let get_sub_header = header.header.get_sub_header;

            this.setState({ get_sub_header });

            if (timeline.sub_header_id) {
              let sub_status = _.findIndex(get_sub_header, (o) => {
                return o.id === timeline.sub_header_id;
              });
              this.setState({ sub_status });
            }

            switch (header.value) {
              // LOGGED IN
              case 6:
                this.setState({ heading_type: 1 });
                return;
              // DISBRUSMENT
              case 7:
                this.setState({ heading_type: 2 });
                return;
              // CLOSED
              case 8:
                this.setState({ heading_type: 3 });
                return;
              // DEAD
              case 9:
                this.setState({ heading_type: 4 });
                return;
              default:
                this.setState({ heading_type: -1 });
                return;
            }
          }
        });
      } else if (key === "contact_person_id" && value) {
        let select_contact_person = _.findIndex(contact_person_list, (o) => {
          return o.id === value;
        });
        this.setState({ select_contact_person });
      } else if (key === "financer_contact_id" && value) {
        let select_financer_name = _.findIndex(financer_list, (o) => {
          return o.id === value;
        });
        this.setState({ select_financer_name });
      }
    }
    this.setState({ editTimelinePopup: true });
  };

  handleOnChange = (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "sub_header_id") {
      let { error } = this.state;
      if (value) {
        error.sub_header_id = "";
        let sub_header = this.state.get_sub_header[value];
        this.setState({
          sub_header_id: sub_header.id,
          sub_header_title: sub_header.sub_header_label,
          error,
          sub_status: value,
        });
      } else {
        this.setState({ sub_header_id: "", sub_header_title: "" });
      }
      return;
    }

    if (name === "financer_contact_id") {
      let { financer_name, financer_contact_id } = this.state;

      const financer = this.state.financer_list[value];

      financer_name = financer.company_name;
      financer_contact_id = financer.id;
      this.setState({
        financer_name,
        financer_contact_id,
        select_financer_name: value,
      });
      return;
    }

    if (name === "contact_person_id") {
      let { contact_person, contact_person_id } = this.state;

      const person = this.state.contact_person_list[value];
      contact_person = person.name;
      contact_person_id = person.id;

      this.setState({
        contact_person,
        contact_person_id,
        select_contact_person: value,
      });
      return;
    }

    this.setState({ [name]: value });
  };

  editTimeLineClose = () => {
    this.setState({
      editTimelinePopup: false,
      heading_type: -1,
      get_sub_header: [],
      appac_no: "",
      approved_amount: "",
      asking_amount: "",
      asset_value: "",
      assign_to: "",
      assignee_name: "",
      attachments: [],
      brokerage_rate: "",
      cloasing_date: "",
      comments: "",
      contact_person: "",
      contact_person_id: "",
      creator_id: "",
      creator_name: "",
      date: "",
      disbrusment_sub_status: "",
      disbrust_amount: "",
      docs_label: "",
      down_payment: "",
      feedback_tags: [],
      financer_contact_id: "",
      financer_name: "",
      hash_id: "",
      hmc_percentage: "",
      id: "",
      loan_interest: "",
      notify_by: "",
      status: "",
      sub_header_id: "",
      sub_header_title: "",
      subject: "",
      sub_status: "",
      select_contact_person: "",
      select_financer_name: "",

      error: {},
    });
  };

  updateTimelineStatus = () => {
    let {
      user_hash_id,
      error,
      get_sub_header,

      heading_type,

      appac_no,
      approved_amount,
      asking_amount,
      asset_value,
      assign_to,
      assignee_name,
      attachments,
      brokerage_rate,
      cloasing_date,
      comments,
      contact_person,
      contact_person_id,
      creator_id,
      creator_name,
      date,
      disbrusment_sub_status,
      disbrust_amount,
      docs_label,
      down_payment,
      feedback_tags,
      financer_contact_id,
      financer_name,
      hash_id,
      hmc_percentage,
      id,
      loan_interest,
      notify_by,
      status,
      sub_header_id,
      sub_header_title,
      subject,
    } = this.state;

    if (!subject) {
      error.subject = "Please enter header";
      this.setState({ error });
      return;
    } else {
      error.subject = "";
      this.setState({ error });
    }

    if (get_sub_header && get_sub_header.length > 0) {
      if (!sub_header_id) {
        error.sub_header_id = "Please select sub header";
        this.setState({ error });
        return;
      } else {
        error.sub_header_id = "";
        this.setState({ error });
      }
    } else {
      error.sub_header_id = "";
      this.setState({ error });
    }

    if ((heading_type === 1 || heading_type === 2) && !financer_contact_id) {
      error.financer_contact_id = "This field required";
      this.setState({ error });
      return;
    } else {
      error.financer_contact_id = "";
      this.setState({ error });
    }

    if ((heading_type === 4 || heading_type === 3) && !comments) {
      error.comments = "Please enter comments";
      this.setState({ error });
      return;
    } else {
      error.comments = "";
      this.setState({ error });
    }

    const formData = {
      user_hash_id,
      appac_no,
      approved_amount,
      asking_amount,
      asset_value,
      assign_to,
      assignee_name,
      attachments,
      brokerage_rate,
      cloasing_date,
      comments,
      contact_person,
      contact_person_id,
      creator_id,
      creator_name,
      date,
      disbrusment_sub_status,
      disbrust_amount,
      docs_label,
      down_payment,
      feedback_tags,
      financer_contact_id,
      financer_name,
      lead_activity_hash_id: hash_id,
      hmc_percentage,
      id,
      loan_interest,
      notify_by,
      status,
      sub_header_id,
      sub_header_title,
      subject,
    };

    this.setState({ isLoading: true, editTimelinePopup: false });

    axios
      .post("lead-activity-update", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        this.editTimeLineClose();
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.getFeedbackHistory();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.editTimeLineClose();
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getLeadWiseClassColor = (id, title) => {
    switch (parseInt(id)) {
      case 6: // LOGGED IN
        return "logged_in";
      case 7: // DISBRUSMENT
        return "disbrusment";
      case 8: // LOST
        return "dead_or_lost";
      case 9: // DEAD
        return "dead_or_lost";

      default:
        if (title === "Commision Paid") return "commision_paid";
        return "added_lead";
    }
  };

  getPreviewType = (url) => {
    const extension = url.substring(url.lastIndexOf(".") + 1);
    if (extension === "docx" || extension === "doc") return word_preview;
    else if (extension === "pdf") return pdf_preview;
    return excel_preview;
  };

  getAllDocumentsLeadWise = (lead_hash_id) => {
    this.setState({ isLoading: true });
    const formData = {
      lead_hash_id,
      medium: "web",
      section_name: "lm_pre_assign_get_all_doc",
      call_from: "pree_assigned_list",
    };
    axios
      .post("/get-lead-all-document", formData, config)
      .then((res) => {
        this.setState({ isLoading: false, isAddedDocument: true });
        if (res.data.status === "1") {
          let old_attachment = [];
          let old_images = [];
          let old_docs = [];
          res.data.data.forEach((doc) => {
            old_attachment.push(doc.id);
            if (doc.attachment_type === "1") {
              old_images.push({
                url: doc.attachment_url,
                id: doc.id,
              });
            } else {
              let url = doc.attachment_url;
              let preview = this.getPreviewType(url);

              // if (url.endsWith(".doc") || url.endsWith(".docx")) {
              //   preview = word_preview;
              // } else if (url.endsWith(".pdf")) {
              //   preview = pdf_preview;
              // } else {
              //   preview = excel_preview;
              // }

              old_docs.push({
                url,
                id: doc.id,
                name: doc.attachment_title,
                preview,
              });
            }
          });

          this.setState({ old_attachment, old_images, old_docs });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, isAddedDocument: true });

        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  handleDocument = async (e) => {
    e.persist();

    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    let files = e.target.files;

    let validExtension = ["doc", "docx", "pdf", "xls", "xlsx"];

    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);

        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          let preview;
          if (extension === "docx" || extension === "doc") {
            preview = word_preview;
          } else if (extension === "pdf") {
            preview = pdf_preview;
          } else {
            preview = excel_preview;
          }
          equipment_docs.push(file);

          equipment_docs_preview.push({ name: file.name, preview });
          attachment_title.push(file.name);

          this.setState({
            equipment_docs,
            equipment_docs_preview,
          });
        } else {
          message.error(file.name + " is not a valid document");
        }
      }
    });
  };

  handleImages = async (e) => {
    e.persist();

    let { equipment_images } = this.state;

    let files = e.target.files;
    let validExtension = ["png", "jpg", "jpeg"];
    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          equipment_images.push(file);
        } else {
          message.error(file.name + " is not a valid image");
        }
      }
      await this.setState({ equipment_images });
    });
  };

  deleteOldImage = async (id, pos) => {
    let { old_attachment, old_images } = this.state;
    old_images.splice(pos, 1);

    let index = old_attachment.indexOf(id);
    old_attachment.splice(index, 1);

    await this.setState({ old_images, old_attachment });
  };

  deleteImage = async (pos) => {
    let { equipment_images } = this.state;
    equipment_images.splice(pos, 1);
    await this.setState({ equipment_images });
  };

  closeAddDocumentPopup = (isAddedDocument) => {
    this.setState({
      isAddedDocument,
      lead_id: "",
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
    });
  };

  leadDocumentUpload = () => {
    this.setState({ isAddedDocument: false });
    let {
      lead_id,
      equipment_docs,
      attachment_title,
      old_attachment,
      user_hash_id,
    } = this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    old_attachment.forEach((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });
    attachment_title.forEach((title, key) => {
      formData.append(`attachment_title[${key}]`, title);
    });
    formData.append("attachment_type", 2);
    formData.append("medium", "web");
    formData.append("section_name", "lm_pre_assign_doc_upload");
    formData.append("lead_assignee_act_id", "");
    formData.append("hash_id", user_hash_id);

    equipment_docs.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.setState({ isLoading: true });

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.leadImageUpload();

        if (res.data.status === "1") {
          // message.success("Document Upload Successfully");

          this.setState({
            equipment_docs: [],
            equipment_docs_preview: [],
            attachment_title: [],
            old_docs: [],
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  leadImageUpload = () => {
    let { equipment_images, lead_id, old_attachment, user_hash_id } =
      this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    formData.append("attachment_title", []);
    formData.append("attachment_type", 1);
    formData.append("medium", "web");
    formData.append("section_name", "lm_pre_assign_doc_upload");
    formData.append("lead_assignee_act_id", "");
    formData.append("hash_id", user_hash_id);

    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    equipment_images.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.setState({ isLoading: true });

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.getFeedbackHistory();
          message.success("Image Upload Successfully");
          this.closeAddDocumentPopup(false);
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let {
      all_report,
      error,
      get_sub_header,
      contact_person_list,
      financer_list,
      lead_basic_details,
      equipment_images,
      equipment_docs_preview,
      old_images,
      old_docs,
    } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.timeline;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {/* EDIT STATUS */}
        <CustomPopup
          title="Edit Status"
          show={this.state.editTimelinePopup}
          onClose={this.editTimeLineClose}
          className="el_madal"
        >
          <div className="custom_popuped heading_popuped">
            <Form.Group className="mb-3" controlId="subject">
              <Form.Label>
                Status Label<span className="required">*</span>
              </Form.Label>

              <Form.Control
                type="text"
                name="subject"
                value={this.state.subject}
                readOnly
                disabled
              />
            </Form.Group>

            {get_sub_header.length > 0 && (
              <Form.Group className="mb-3" controlId="sub_status">
                <Form.Label>
                  Sub Status <span className="required">*</span>
                </Form.Label>
                <div className="heading_tag">
                  <Form.Control
                    as="select"
                    name="sub_header_id"
                    onChange={this.handleOnChange}
                    value={this.state.sub_status}
                  >
                    <option value="">Select Status</option>
                    {get_sub_header.map((header, key) => {
                      return (
                        <option value={key} key={key}>
                          {header.sub_header_label}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
                <p className="invalid_input">{error.sub_header_id}</p>
              </Form.Group>
            )}

            {/* LOGED IN  */}
            {this.state.heading_type === 1 && (
              <>
                <Form.Group className="mb-3" controlId="financer_name">
                  <Form.Label>
                    Financer Name <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="financer_contact_id"
                    onChange={this.handleOnChange}
                    value={this.state.select_financer_name}
                  >
                    <option value="">Select Financer Name</option>
                    {financer_list.map((financer, key) => {
                      return (
                        <option value={key} key={key}>
                          {financer.company_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <p className="invalid_input">{error.financer_contact_id}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="contact_person">
                  <Form.Label>Asginee Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="contact_person_id"
                    onChange={this.handleOnChange}
                    value={this.state.select_contact_person}
                  >
                    <option value="">Select Asginee Name</option>
                    {contact_person_list.map((person, key) => {
                      return (
                        <option value={key} key={key}>
                          {person.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="asset_value">
                  <Form.Label>Asset Value</Form.Label>
                  <Form.Control
                    type="text"
                    name="asset_value"
                    value={this.state.asset_value}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.AMMOUNT}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="hmc_percentage">
                  <Form.Label>HMC Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    name="hmc_percentage"
                    value={this.state.hmc_percentage}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.INTEREST_RATE}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="asking_amount">
                  <Form.Label>Asking Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="asking_amount"
                    value={this.state.asking_amount}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.AMMOUNT}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="approved_amount">
                  <Form.Label>Approved Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="approved_amount"
                    value={this.state.approved_amount}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.AMMOUNT}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="down_payment">
                  <Form.Label>Down Payment</Form.Label>
                  <Form.Control
                    type="text"
                    name="down_payment"
                    value={this.state.down_payment}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.AMMOUNT}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="loan_interest">
                  <Form.Label>Loan Interest</Form.Label>
                  <Form.Control
                    type="text"
                    name="loan_interest"
                    value={this.state.loan_interest}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.INTEREST_RATE}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="brokerage_rate">
                  <Form.Label>HMC Brokerage Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="brokerage_rate"
                    value={this.state.brokerage_rate}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.AMMOUNT}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="disbrust_amount">
                  <Form.Label>Disbrust Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="disbrust_amount"
                    value={this.state.disbrust_amount}
                    onChange={this.handleOnChange}
                    placeholder={PlaceHolder.AMMOUNT}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="cloasing_date">
                  <Form.Label>Expected Closing Date</Form.Label>
                  <ReactDatePicker
                    selected={
                      this.state.cloasing_date !== "" &&
                      // this.state.cloasing_date !== null &&
                      new Date(this.state.cloasing_date)
                    }
                    className="react_date"
                    dateFormat="dd-MM-yyyy"
                    onChange={(cloasing_date) => {
                      this.setState({
                        cloasing_date:
                          moment(cloasing_date).format("YYYY-MM-DD"),
                      });
                    }}
                    placeholderText={PlaceHolder.DATE}
                  />
                </Form.Group>
              </>
            )}

            {/* DISBURSMENT */}
            {this.state.heading_type === 2 && (
              <>
                <Form.Group className="mb-3" controlId="financer_name">
                  <Form.Label>
                    Financer Name <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="financer_contact_id"
                    onChange={this.handleOnChange}
                    value={this.state.select_financer_name}
                  >
                    <option value="">Select Financer Name</option>
                    {financer_list.map((financer, key) => {
                      return (
                        <option value={key} key={key}>
                          {financer.company_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <p className="invalid_input">{error.financer_contact_id}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="appac_no">
                  <Form.Label>Reference No</Form.Label>
                  <Form.Control
                    type="text"
                    name="appac_no"
                    onChange={this.handleOnChange}
                    placeholder="Reference No"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="date">
                  <Form.Label>Date</Form.Label>
                  <ReactDatePicker
                    selected={
                      this.state.date !== "" && new Date(this.state.date)
                    }
                    className="react_date"
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      this.setState({
                        date: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                    placeholderText={PlaceHolder.DATE}
                  />
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-3" controlId="comment">
              <Form.Label>
                Comments
                {(this.state.heading_type === 4 ||
                  this.state.heading_type === 3) && (
                  <span className="required">*</span>
                )}
              </Form.Label>

              <Form.Control
                as="textarea"
                rows="3"
                name="comments"
                value={this.state.comments}
                placeholder="Your Comment"
                onChange={this.handleOnChange}
              />
              <p className="invalid_input">{error.comments}</p>
            </Form.Group>
          </div>

          <div className="status_footer">
            <Button
              type="submit"
              className="status_update"
              onClick={this.updateTimelineStatus}
            >
              Update
            </Button>
          </div>
        </CustomPopup>

        {/* EDIT DOCUMENT */}
        <CustomPopup
          show={this.state.isAddedDocument}
          title="Edit or Add Documents / Images"
          onClose={this.closeAddDocumentPopup}
          isCenter={true}
          className="el_modal"
        >
          {/* IMAGE */}
          <Form.Group>
            <Form.Label>Add Equipment images</Form.Label>
            <div className="upload_image_box">
              {old_images &&
                old_images.map((image, key) => {
                  return (
                    <div className="image_box" key={key}>
                      <img src={MEDIA_BASE_URL + image.url} alt="HMC" />
                      {/* <span
                          className="close_preview"
                          onClick={() => {
                            this.deleteOldImage(image.id, key);
                          }}
                        >
                          <img src={cross_preview} alt="HMC" />
                        </span> */}
                    </div>
                  );
                })}

              {equipment_images &&
                equipment_images.map((preview, index) => {
                  return (
                    <div className="image_box" key={index}>
                      <img src={URL.createObjectURL(preview)} alt="HMC" />
                      <span
                        className="close_preview"
                        onClick={() => {
                          this.deleteImage(index);
                        }}
                      >
                        <img src={cross_preview} alt="HMC" />
                      </span>
                    </div>
                  );
                })}

              <div className="upload_box">
                <div className="upload_btn_wrappererer">
                  <button className="btnner">
                    <img src={Upload_img_icon_img} />
                  </button>

                  <Form.Control
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    name="images"
                    multiple
                    onChange={this.handleImages}
                  />
                </div>
              </div>
            </div>
          </Form.Group>

          {/* DOCUMENT */}
          <Form.Group>
            <Form.Label>Add Document</Form.Label>
            <div className="cert_box">
              {old_docs &&
                old_docs.map((doc, index) => {
                  return (
                    <div className="cert_box_inner" key={index}>
                      <div className="cret_file">
                        <img src={doc.preview} alt="HMC" />
                      </div>
                      <Form.Label>{doc.name}</Form.Label>
                    </div>
                  );
                })}
              {equipment_docs_preview &&
                equipment_docs_preview.map((doc, index) => {
                  return (
                    <div className="cert_box_inner" key={index}>
                      <div className="cret_file">
                        <img src={doc.preview} alt="HMC" />
                      </div>
                      <Form.Label>{doc.name}</Form.Label>
                      <div className="click_drop"></div>
                    </div>
                  );
                })}

              <div className="cert_box_upload">
                <div className="upload_btn_wrappererer">
                  <button className="btnner">
                    <img src={Up_doc_icon_img} />
                  </button>
                  <Form.Control
                    type="file"
                    accept=".doc, .docx, .pdf, .xls,.xlsx"
                    onChange={this.handleDocument}
                    multiple
                  />
                </div>
              </div>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-end sub_container">
            <button
              type="submit"
              className="submit_but"
              onClick={this.leadDocumentUpload}
            >
              Submit
            </button>
          </div>
        </CustomPopup>

        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12">
                  <div className="time_line_inner">
                    <div className="time_prev">
                      <span className="back_arrowsw">
                        <img
                          alt="HMC"
                          src={Back_Arrow}
                          onClick={this.callBacktoParent}
                          width={20}
                        />
                      </span>
                      <h2>{language.timeline_h}</h2>
                    </div>

                    <div className="created_by mb-3">
                      <label>{language.created_by}</label>
                      <div className="lc_user d-flex align-items-center">
                        <div className="lcu_image">
                          <img
                            src={
                              lead_basic_details.img_url
                                ? MEDIA_BASE_URL + lead_basic_details.img_url
                                : prof_icon_menu
                            }
                            width={40}
                            height={40}
                            className="rounded-circle"
                          />
                        </div>
                        <div className="lcu_name">
                          <h5 className="mb-0">
                            {lead_basic_details.creator_name}
                          </h5>
                        </div>
                      </div>
                    </div>

                    {/* LEAD BASIC DETAILS */}
                    <div className="lead_info">
                      <Row>
                        <div className="li_item col-md-4">
                          <dl>
                            <dt>{language.luid_label}</dt>
                            <dd>{lead_basic_details.luid}</dd>
                          </dl>
                        </div>
                        <div className="li_item col-md-4">
                          <dl>
                            <dt>{language.customer_name_label}</dt>
                            <dd>
                              {lead_basic_details.customer_name},(
                              {lead_basic_details.lead_type === "1"
                                ? language.customer
                                : lead_basic_details.lead_type === "2"
                                ? language.dealer
                                : language.financer}
                              )
                            </dd>
                          </dl>
                        </div>
                        <div className="li_item col-md-4">
                          <dl>
                            <dt>{language.customer_phone_label}</dt>
                            <dd>{lead_basic_details.customer_phone}</dd>
                          </dl>
                        </div>
                        <div className="li_item col-md-4">
                          <dl>
                            <dt>{language.equipment_name_label}</dt>
                            <dd>{lead_basic_details.euipment_name}</dd>
                          </dl>
                        </div>
                        <div className="li_item col-md-4">
                          <dl>
                            <dt>{language.customer_address_label}</dt>
                            <dd>{lead_basic_details.customer_address}</dd>
                          </dl>
                        </div>
                        <div className="li_item col-md-4">
                          <dl>
                            <dt>{language.equipment_category_label}</dt>
                            <span className="badge">
                              {lead_basic_details.euipment_category === "1"
                                ? language.new_equipment
                                : language.resale_equipment}
                            </span>
                          </dl>
                        </div>
                      </Row>
                    </div>

                    <div className="time_line_inner_inner">
                      <div className="ul_top">
                        <span className="ul_top_inner"></span>
                      </div>
                      <ul>
                        {all_report &&
                          all_report.map((report, key) => {
                            let create_date = moment(report.created_at).format(
                              "Do MMM YYYY - h:mm A"
                            );
                            let isDailyReport =
                              report.hash_id.includes("daily_report");
                            let isDocument =
                              report.hash_id.includes("lead_attach");
                            let attachment = [];
                            if (isDailyReport) {
                              attachment = report.all_attachment?.map(
                                (value) => {
                                  return {
                                    name: value.attachment_title,
                                    url: value.attachment_url,
                                  };
                                }
                              );
                            } else if (isDocument) {
                              attachment[0] = {
                                name: report.attachment_title,
                                url: report.attachment_url,
                              };
                            } else {
                              attachment = report.attachments?.map((value) => {
                                return {
                                  name: value.attachment_title,
                                  url: value.attachment_url,
                                };
                              });
                            }
                            return (
                              <li key={key}>
                                <label>
                                  {create_date}{" "}
                                  {/* <span className="li_type">Added Lead</span> */}
                                </label>
                                {/* DAILY REPORT */}
                                {isDailyReport ? (
                                  <div className="adddwed_doc dailyreport">
                                    <h2>
                                      <span>{report.creator_name}</span>{" "}
                                      {report.heading}
                                    </h2>

                                    {report.all_attachment &&
                                      report.all_attachment?.length > 0 && (
                                        <div className="doc_partts">
                                          {report.all_attachment?.map(
                                            (doc, key) => {
                                              return (
                                                <a
                                                  className="innnner_doc"
                                                  key={key}
                                                  href={doc.attachment_url}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {doc.attachment_title}
                                                </a>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}

                                    <div className="extra_field">
                                      <ul>
                                        {report.start_location && (
                                          <li>
                                            {language.start_location}:
                                            <span>{report.start_location}</span>
                                          </li>
                                        )}

                                        {report.end_location && (
                                          <li>
                                            {language.end_location}:
                                            <span>{report.end_location}</span>
                                          </li>
                                        )}

                                        {report.travel_cost && (
                                          <li>
                                            {language.travel_cost}:
                                            <span>{report.travel_cost}</span>
                                          </li>
                                        )}

                                        {report.travel_date && (
                                          <li>
                                            {language.travel_date}:
                                            <span>{report.travel_date}</span>
                                          </li>
                                        )}

                                        {report.travel_mode && (
                                          <li>
                                            {language.travel_mode}:
                                            <span>{report.travel_mode}</span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>

                                    {report.note && (
                                      <>
                                        <div className="commnet_parttts">
                                          {report.creator_name}{" "}
                                          {language.added_a_comment}
                                        </div>
                                        <div className="kyc_updated">
                                          {report.note}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : isDocument ? (
                                  <div className="adddwed_doc added_lead">
                                    <div className="time_line_edit">
                                      <h2>
                                        <span>{report.creator_name}</span>{" "}
                                        {report.attachment_type === "1"
                                          ? language.added_new_image
                                          : language.added_new_document}
                                      </h2>

                                      <button
                                        type="button"
                                        className="edit_btn_timeline ml-3"
                                        onClick={() => {
                                          this.getAllDocumentsLeadWise(
                                            lead_basic_details.hash_id
                                          );
                                        }}
                                      >
                                        {/* <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        /> */}
                                        {language.edit_btn}
                                      </button>
                                    </div>

                                    <div className="doc_partts">
                                      {attachment?.map((doc, key) => {
                                        return (
                                          <a
                                            className="doc_view_inner"
                                            key={key}
                                            href={doc.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {report.attachment_type === "1" ? (
                                              <div className="image_preview">
                                                <img
                                                  className="img-fluid"
                                                  src={MEDIA_BASE_URL + doc.url}
                                                  alt="HMC"
                                                />
                                              </div>
                                            ) : (
                                              <div className="doc_preview">
                                                <img
                                                  src={this.getPreviewType(
                                                    doc.url
                                                  )}
                                                  alt="HMC"
                                                />
                                              </div>
                                            )}
                                            {doc.name}
                                          </a>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`adddwed_doc ${this.getLeadWiseClassColor(
                                      report.subject_id,
                                      report.subject
                                    )}`}
                                  >
                                    <div className="time_line_edit">
                                      <h2>
                                        <span>{report.creator_name}</span>{" "}
                                        {report.sub_header_title
                                          ? report.subject +
                                            " as " +
                                            report.sub_header_title
                                          : report.subject}
                                      </h2>

                                      {(report.subject_id === "6" ||
                                        report.subject_id === "7") &&
                                        report.creator_id ==
                                          localStorage.getItem("lead_user") && (
                                          <button
                                            type="button"
                                            className="edit_btn_timeline ml-3"
                                            onClick={() => {
                                              this.setEditTimelineDetails(
                                                report
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                            />
                                            {language.edit_btn}
                                          </button>
                                        )}
                                    </div>

                                    {report.feedback_tags?.length > 0 && (
                                      <div className="added_tag_partts">
                                        {report.feedback_tags?.map(
                                          (tag, key) => {
                                            return (
                                              <div
                                                className="tagger"
                                                key={key}
                                                style={{
                                                  background:
                                                    tag.tag_back_color,
                                                  color: tag.tag_title_color,
                                                }}
                                              >
                                                {tag.tag_title}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}

                                    {attachment && attachment?.length > 0 && (
                                      <div className="doc_partts">
                                        {attachment?.map((doc, key) => {
                                          return (
                                            <a
                                              className="innnner_doc"
                                              key={key}
                                              href={doc.url}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {doc.name}
                                            </a>
                                          );
                                        })}
                                      </div>
                                    )}

                                    <div className="mt-4">
                                      {report.appac_no && (
                                        <div className="others_filed">
                                          <span>{language.reference_no}</span>
                                          {report.appac_no}
                                        </div>
                                      )}

                                      {report.date && (
                                        <div className="others_filed">
                                          <span>{language.date}</span>
                                          {report.date}
                                        </div>
                                      )}

                                      {report.cloasing_date && (
                                        <div className="others_filed">
                                          <span>{language.cloasing_date}</span>
                                          {report.cloasing_date}
                                        </div>
                                      )}

                                      {report.contact_person && (
                                        <div className="others_filed">
                                          <span>{language.asginee_name}</span>
                                          {report.contact_person}
                                        </div>
                                      )}

                                      {report.financer_name && (
                                        <div className="others_filed">
                                          <span>{language.financer_name}</span>
                                          {report.financer_name}
                                        </div>
                                      )}

                                      {report.approved_amount && (
                                        <div className="others_filed">
                                          <span>
                                            {language.approved_amount}
                                          </span>
                                          {report.approved_amount}
                                        </div>
                                      )}

                                      {report.asking_amount && (
                                        <div className="others_filed">
                                          <span>{language.asking_amount}</span>
                                          {report.asking_amount}
                                        </div>
                                      )}

                                      {report.asset_value && (
                                        <div className="others_filed">
                                          <span>{language.asset_value}</span>
                                          {report.asset_value}
                                        </div>
                                      )}

                                      {report.brokerage_rate && (
                                        <div className="others_filed">
                                          <span>{language.brokerage_rate}</span>
                                          {report.brokerage_rate}
                                        </div>
                                      )}

                                      {report.disbrust_amount && (
                                        <div className="others_filed">
                                          <span>
                                            {language.disbrust_amount}
                                          </span>
                                          {report.disbrust_amount}
                                        </div>
                                      )}

                                      {report.hmc_percentage && (
                                        <div className="others_filed">
                                          <span>{language.hmc_Percentage}</span>
                                          {report.hmc_percentage}%
                                        </div>
                                      )}

                                      {report.loan_interest && (
                                        <div className="others_filed">
                                          <span>{language.loan_interest}</span>
                                          {report.loan_interest}%
                                        </div>
                                      )}
                                    </div>

                                    {report.assignee_name && (
                                      <div className="assign_to">
                                        {language.current_lead_assinged_to}{" "}
                                        {report.assignee_name}
                                      </div>
                                    )}

                                    {report.comments && (
                                      <>
                                        <div className="commnet_parttts">
                                          {report.creator_name}{" "}
                                          {language.added_a_comment}
                                        </div>
                                        <div className="kyc_updated">
                                          {report.comments}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          })}

                        {/* <li>
                          <label>
                            Oct 2, 2022 . <span>11.02 Pm</span>
                          </label>
                          <div className="adddwed_doc">
                            <h2>
                              Current Lead assinged to <span>Vijay Kumar</span>
                            </h2>
                          </div>
                        </li>

                        <li>
                          <label>
                            Oct 2, 2022 . <span>11 Pm</span>
                          </label>
                          <div className="adddwed_doc reverify">
                            <h2>
                              <span>Spandita </span> reverify a lead
                            </h2>
                            <div className="commnet_parttts">
                              Spandita added a comment
                            </div>
                            <div className="doc_partts">
                              <div className="innnner_doc">Pan.pdf</div>
                              <div className="innnner_doc">Aadhar.jpg</div>
                            </div>

                            <div className="kyc_updated">
                              Lead is ok, Planing to buy before Oct 20, 2022
                            </div>
                            <div className="kyc_verify">
                              <span>Spandita</span> assigned to{" "}
                              <span>Vijay Kumar</span> for KYC verification
                            </div>
                          </div>
                        </li>

                        <li>
                          <label>
                            Oct 2, 2022 . <span>11.02 Pm</span>
                          </label>
                          <div className="adddwed_doc">
                            <h2>Lead assinged to escalator 1 for reverify</h2>
                          </div>
                        </li>

                        <li>
                          <label>
                            Oct 2, 2022 . <span>11 Pm</span>
                          </label>
                          <div className="adddwed_doc esccalator">
                            <h2>
                              <span>Parneet</span> verify lead as escalator 1
                            </h2>
                            <div className="commnet_parttts">
                              Parneet added a comment
                            </div>
                            <div className="doc_partts">
                              <div className="innnner_doc">Pan.pdf</div>
                              <div className="innnner_doc">Aadhar.jpg</div>
                            </div>

                            <div className="kyc_updated">
                              Customer Neque porro quisquam est qui dolorem
                              ipsum quia dolor sit ame.
                            </div>
                            <div className="verify_done">
                              <span></span>
                              <img src={Verify_Done} />{" "}
                              <span>escalator 1 done</span>
                            </div>

                            <div className="verify_done">
                              <div className="cust">
                                Parneet verify done
                                <img src={Verify_Done} />{" "}
                              </div>
                              <span>John doe</span>
                            </div>
                            <div className="kyc_read_more">
                              <Link to="#">Read More</Link>
                            </div>
                          </div>
                        </li>

                        <li>
                          <label>
                            Oct 1, 2022 . <span>2 Pm</span>
                          </label>
                          <div className="adddwed_doc">
                            <h2>
                              Lead assinged to escalator 1 for verification
                            </h2>
                          </div>
                        </li>

                        <li>
                          <label>
                            Oct 1, 2022 . <span>11 Pm</span>
                          </label>
                          <div className="adddwed_doc">
                            <h2>Lead created by Spandita</h2>
                            <div className="verify_done">
                              <div className="cust">
                                Customer Name
                                <img src={Verify_Done} />{" "}
                              </div>
                              <span>John doe</span>
                            </div>

                            <div className="verify_done">
                              <div className="cust">
                                Requirment
                                <img src={Verify_Done} />{" "}
                              </div>
                              <span>Requirments description</span>
                            </div>
                          </div>
                        </li>
                         */}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(TimeLine);
