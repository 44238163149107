import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Collapse } from "antd";

export default class Seo extends Component {
  render() {
    const { Panel } = Collapse;
    let { footer_faq_data, seo_data } = this.props;
    return (
      <section className="seo_area">
        <Container fluid>
          <div
            className="seo_cms"
            dangerouslySetInnerHTML={{ __html: seo_data?.footer_data }}
          />

          {footer_faq_data.length > 0 && (
            <div className="seo_faqs">
              <Collapse accordion defaultActiveKey={["0"]}>
                {footer_faq_data.map((faq, key) => {
                  return (
                    <Panel header={faq.question} key={key}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: faq.answer,
                        }}
                      ></p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          )}
        </Container>
      </section>
    );
  }
}
