import { flg_in, flg_sa } from "../assets/images";

const LANGUAGE_DATA = {
  IN: {
    label: "India",
    value: "IN",
    icon: flg_in,
    language: "en",
  },
  SA: {
    label: "Saudi",
    value: "SA",
    icon: flg_sa,
    language: "ar",
  },
};

export default LANGUAGE_DATA;
