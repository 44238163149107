import {
  COUNTRY_CODE_FAILED,
  COUNTRY_CODE_REQUEST,
  COUNTRY_CODE_SUCCESS,
} from "../constant/LanguageConstants";

export const CountryReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_CODE_REQUEST:
      return { loading: true };

    case COUNTRY_CODE_SUCCESS:
      return { loading: false, country_code_list: action.payload };

    case COUNTRY_CODE_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
