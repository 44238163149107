import React, { Component } from "react";
import { Container } from "react-bootstrap";
import {
  playstore_black,
  ios_store_black,
  androidQR,
  iosQR,
  mobile_app_heading,
  web_video_thumnail,
} from "../../assets/images";
import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";
import "./HomeNew.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class MobileApp extends Component {
  render() {
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.home;
    } else {
      language = {};
    }
    return (
      <section className="mobile_app_1">
        <div className="mobile_app_left_part">
          <Container fluid>
            <div className="mobile_app_text">
              <div className="mobile_app_left_text">
                <div className="commn_heading">
                  <h2>{language.download_hmc_mobile_app}</h2>
                </div>
                <p className="paragraph">
                  {language.download_hmc_mobile_app_desc}
                </p>
                <div className="mobile_app_buton">
                  <div className="mobile_upper">
                    <div className="qrCode">
                      <img src={androidQR} alt="Android QR Code" />
                    </div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wgt.hmc"
                      target="_blank"
                    >
                      <img src={playstore_black} alt="Play Store" />
                    </a>
                  </div>
                  <div className="mobile_lower">
                    <div className="qrCode">
                      <img src={iosQR} alt="iOS QR Code" />
                    </div>
                    <a
                      href="https://apps.apple.com/in/app/heavy-machinery-care/id1582959119"
                      target="_blank"
                    >
                      <img src={ios_store_black} alt="iOS Store" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="mobile_app_right_text">
                {/* <img
                  src={hmc_mobile_app_download}
                  alt="HMC Mobile App Download"
                /> */}
                <Carousel controls={false}>
                  <Carousel.Item>
                    <ReactPlayer
                      url="https://vimeo.com/836071227"
                      width="100%"
                      height="390px"
                      playing
                      //playIcon={<button>Play</button>}
                      light={web_video_thumnail}
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="mobile_app_heading">
                <img src={mobile_app_heading} alt="HMC" />
              </div>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}

export default connect(mapStateToProps)(MobileApp);
