import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import "./popup.css";
import { PlaceHolder } from "./PlaceHolder";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import MultiSelectTags from "../pages/leadmanagement/MultiSelectTags";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class StatusChanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadHeader: [],
      get_sub_header: [],
      show: false,
      contact_list: [],

      selected_status_tags: [],
      subject: "",
      subject_id: "",
      sub_header_id: "",
      sub_header_title: "",

      sub_status: "",

      tags: [],
      docs_label: "",
      lead_assignee_activity_id: null,
      assigne_contact_id: "",
      date: "",
      financer_name: "",
      financer_contact_id: "",
      contact_person: "",
      contact_person_id: "",
      disbrusment_sub_status: "",
      asking_amount: "",
      disbrust_amount: "",
      approved_amount: "",
      down_payment: "",
      brokerage_rate: "",
      loan_interest: "",
      cloasing_date: "",
      appac_no: "",
      hmc_percentage: "",
      asset_value: "",

      heading_type: 0, // 0 -> default, 1 -> loged in, 2 -> disbrusment, 3 -> closed, 4 -> dead

      error: {},

      assignee_name: "",
      assignee_phone: "",
      assign_to: "",
      notify_by: [],
      comments: "",

      local_financer: "",
      only_admin_view: 0,
    };
  }

  clearData = () => {
    this.setState({
      sub_header_id: "",
      sub_header_title: "",
      financer_name: "",
      financer_contact_id: "",
      local_financer: "",
      contact_person: "",
      contact_person_id: "",
      asset_value: "",
      hmc_percentage: "",
      asking_amount: "",
      approved_amount: "",
      down_payment: "",
      loan_interest: "",
      brokerage_rate: "",
      disbrust_amount: "",
      cloasing_date: "",
      appac_no: "",
      date: "",
      tags: [],
      selected_status_tags: [],
      comments: "",
      only_admin_view: 0,
    });
  };

  handleClose = () => {
    this.setState({
      leadHeader: [],
      get_sub_header: [],
      show: false,
      contact_list: [],

      selected_status_tags: [],
      subject: "",
      subject_id: "",
      sub_header_id: "",
      sub_header_title: "",

      sub_status: "",

      tags: [],
      docs_label: "",
      lead_assignee_activity_id: null,
      assigne_contact_id: "",
      date: "",
      financer_name: "",
      financer_contact_id: "",
      contact_person: "",
      contact_person_id: "",
      disbrusment_sub_status: "",
      asking_amount: "",
      disbrust_amount: "",
      approved_amount: "",
      down_payment: "",
      brokerage_rate: "",
      loan_interest: "",
      cloasing_date: "",
      appac_no: "",
      hmc_percentage: "",
      asset_value: "",

      heading_type: 0,

      error: {},

      assignee_name: "",
      assignee_phone: "",
      assign_to: "",
      notify_by: [],
      comments: "",

      local_financer: "",
      only_admin_view: 0,
    });
    this.props.onClose(false);
  };

  handleSelectData = async (value, key) => {
    if (value) {
      await this.setState({
        [key]: value.label,
      });
      let { error } = this.state;
      if (key === "subject") {
        error.subject = "";
        if (value.__isNew__) {
          await this.setState({
            subject_id: "",
            error,
            get_sub_header: [],
            heading_type: 0,
            sub_status: "",
          });
        } else {
          error.comments = "";
          let get_sub_header = value.header.get_sub_header;
          await this.setState({
            subject_id: value.value,
            error,
            get_sub_header,
            heading_type: 0,
            sub_status: "",
          });

          // FOR LOGGED IN
          if (value.value === 6) {
            await this.setState({ heading_type: 1 });
            return;
          }
          //    FOR Disbrusment
          if (value.value === 7) {
            this.props.getSelectedFinancer();
            await this.setState({ heading_type: 2 });
            return;
          }

          //    FOR CLOSED
          if (value.value === 8) {
            await this.setState({ heading_type: 3 });
            return;
          }

          //    FOR DEAD
          if (value.value === 9) {
            await this.setState({ heading_type: 4 });
            return;
          }
        }
      }
    } else {
      await this.setState({
        subject_id: "",
        get_sub_header: [],
        heading_type: 0,
        financer_name: "",
        financer_contact_id: "",
        contact_person: "",
        contact_person_id: "",
        disbrusment_sub_status: "",
        date: "",
        cloasing_date: "",
        sub_header_id: "",
        sub_header_title: "",
        sub_status: "",
      });
    }
    this.clearData();
  };

  handleOnChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "sub_header_id") {
      let { error } = this.state;
      if (value) {
        error.sub_header_id = "";
        let sub_header = this.state.get_sub_header[value];
        await this.setState({
          sub_header_id: sub_header.id,
          sub_header_title: sub_header.sub_header_label,
          error,
          sub_status: value,
        });
      } else {
        await this.setState({ sub_header_id: "", sub_header_title: "" });
      }
      return;
    }

    if (name === "financer_contact_id") {
      let { financer_name, financer_contact_id } = this.state;

      const financer = this.props.financerList[value];

      financer_name = financer.company_name;
      financer_contact_id = financer.id;
      await this.setState({
        financer_name,
        financer_contact_id,
        local_financer: value,
      });
      return;
    }

    if (name === "contact_person_id") {
      let { contact_person, contact_person_id } = this.state;

      const person = this.props.contactPersonList[value];
      contact_person = person.first_name + " " + person.last_name;
      contact_person_id = person.id;

      await this.setState({ contact_person, contact_person_id });
      return;
    }

    this.setState({ [name]: value });
  };

  isDisabled = (id) => {
    const pos = parseInt(this.props.selected_header_id);
    switch (pos) {
      case 6: // FOR LOGGED IN
        return id <= 6 ? true : false;
      case 7: // FOR DISBRUSMENT
        return id <= 7 ? true : false;
      default:
        if (pos >= 8 && pos < 1000) {
          return id <= pos ? true : false;
        }
        return id === 7 ? true : false;
    }
  };

  render() {
    let { get_sub_header, error } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.update_status;
    } else {
      language = {};
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        backdrop="static"
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="popup-title">
            {language.update_lead_status_h}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom_popuped heading_popuped">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {language.status_label}
                  <span className="required">*</span>
                </Form.Label>
                <div className="heading_tag">
                  <CreatableSelect
                    isClearable
                    onChange={(value) => {
                      this.handleSelectData(value, "subject");
                    }}
                    options={this.props.leadHeader}
                    placeholder={language.select_status_abel_plh}
                    name="subject"
                    isOptionDisabled={(option) => {
                      return this.isDisabled(option.value);
                    }}
                  />
                </div>
                <p className="invalid_input">{error.subject}</p>
              </Form.Group>

              {get_sub_header.length > 0 && (
                <Form.Group className="mb-3" controlId="sub_status">
                  <Form.Label>
                    {language.sub_status_label}
                    <span className="required">*</span>
                  </Form.Label>
                  <div className="heading_tag">
                    <Form.Control
                      as="select"
                      name="sub_header_id"
                      onChange={this.handleOnChange}
                      value={this.state.sub_status}
                    >
                      <option value="">{language.select_status_plh}</option>
                      {get_sub_header.map((header, key) => {
                        return (
                          <option value={key} key={key}>
                            {header.sub_header_label}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                  <p className="invalid_input">{error.sub_header_id}</p>
                </Form.Group>
              )}

              {/* LOGED IN  */}
              {this.state.heading_type === 1 && (
                <>
                  <Form.Group className="mb-3" controlId="financer_name">
                    <Form.Label>
                      {language.financer_name_label}
                      <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="financer_contact_id"
                      onChange={this.handleOnChange}
                    >
                      <option value="">
                        {language.select_financer_name_plh}
                      </option>
                      {this.props.financerList?.map((financer, key) => {
                        return (
                          <option value={key} key={key}>
                            {financer.company_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <p className="invalid_input">{error.financer_contact_id}</p>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="contact_person">
                    <Form.Label>{language.asginee_name_label}</Form.Label>
                    <Form.Control
                      as="select"
                      name="contact_person_id"
                      onChange={this.handleOnChange}
                    >
                      <option value="">
                        {language.select_asginee_name_plh}
                      </option>
                      {this.props.contactPersonList?.map((person, key) => {
                        return (
                          <option value={key} key={key}>
                            {person.first_name + " " + person.last_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="asset_value">
                    <Form.Label>{language.asset_value_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="asset_value"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.AMMOUNT}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="hmc_percentage">
                    <Form.Label>{language.hmc_percentage_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="hmc_percentage"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.INTEREST_RATE}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="asking_amount">
                    <Form.Label>{language.asking_amount_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="asking_amount"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.AMMOUNT}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="approved_amount">
                    <Form.Label>{language.approved_amount_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="approved_amount"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.AMMOUNT}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="down_payment">
                    <Form.Label>{language.down_pyment_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="down_payment"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.AMMOUNT}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="loan_interest">
                    <Form.Label>{language.loan_interest}</Form.Label>
                    <Form.Control
                      type="text"
                      name="loan_interest"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.INTEREST_RATE}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="brokerage_rate">
                    <Form.Label>
                      {language.hmc_brokerage_amount_label}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="brokerage_rate"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.AMMOUNT}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="disbrust_amount">
                    <Form.Label>{language.disbrust_amount_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="disbrust_amount"
                      onChange={this.handleOnChange}
                      placeholder={PlaceHolder.AMMOUNT}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="cloasing_date">
                    <Form.Label>
                      {language.expected_closing_date_label}
                    </Form.Label>
                    <ReactDatePicker
                      selected={
                        this.state.cloasing_date !== "" &&
                        new Date(this.state.cloasing_date)
                      }
                      className="react_date"
                      dateFormat="dd-MM-yyyy"
                      onChange={(cloasing_date) => {
                        this.setState({
                          cloasing_date:
                            moment(cloasing_date).format("YYYY-MM-DD"),
                        });
                      }}
                      placeholderText={PlaceHolder.DATE}
                    />
                  </Form.Group>
                </>
              )}

              {/* DISBURSMENT */}
              {this.state.heading_type === 2 && (
                <>
                  <Form.Group className="mb-3" controlId="financer_name">
                    <Form.Label>
                      {language.financer_name_label}
                      <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="financer_contact_id"
                      onChange={this.handleOnChange}
                      value={
                        this.state.local_financer ||
                        this.props.selected_financer?.position
                      }
                    >
                      <option value="">
                        {language.select_financer_name_plh}
                      </option>
                      {this.props.financerList?.map((financer, key) => {
                        return (
                          <option value={key} key={key}>
                            {financer.company_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <p className="invalid_input">
                      {this.state.error.financer_contact_id}
                    </p>
                  </Form.Group>

                  {/* <Form.Group className="mb-3" controlId="contact_person">
                    <Form.Label>Asginee Name</Form.Label>
                    <Form.Control
                      as="select"
                      name="contact_person_id"
                      onChange={this.handleOnChange}
                    >
                      <option value="">Select Asginee Name</option>
                      {this.props.contactPersonList?.map((person, key) => {
                        return (
                          <option value={key} key={key}>
                            {person.first_name + " " + person.last_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group> */}

                  <Form.Group className="mb-3" controlId="appac_no">
                    <Form.Label>{language.reference_no_label}</Form.Label>
                    <Form.Control
                      type="text"
                      name="appac_no"
                      onChange={this.handleOnChange}
                      placeholder={language.reference_no_plh}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="date">
                    <Form.Label>{language.date_label}</Form.Label>
                    <ReactDatePicker
                      selected={
                        this.state.date !== "" && new Date(this.state.date)
                      }
                      className="react_date"
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        this.setState({
                          date: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                      placeholderText={PlaceHolder.DATE}
                    />
                  </Form.Group>
                </>
              )}

              {/* CLOSED */}
              {this.state.heading_type === 3 && (
                <>
                  <Form.Group className="mb-3" controlId="financer_name">
                    <Form.Label>{language.lost_to_label}</Form.Label>
                    <Form.Control
                      as="select"
                      name="financer_contact_id"
                      onChange={this.handleOnChange}
                    >
                      <option value="">{language.select_lost_to_plh}</option>
                      {this.props.financerList?.map((financer, key) => {
                        return (
                          <option value={key} key={key}>
                            {financer.company_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  {/* <Form.Group className="mb-3" controlId="contact_person">
                    <Form.Label>Asginee Name</Form.Label>
                    <Form.Control
                      as="select"
                      name="contact_person_id"
                      onChange={this.handleOnChange}
                    >
                      <option value="">Select Asginee Name</option>
                      {this.props.contactPersonList?.map((person, key) => {
                        return (
                          <option value={key} key={key}>
                            {person.first_name + " " + person.last_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group> */}

                  <Form.Group className="mb-3" controlId="date">
                    <Form.Label>{language.date_label}</Form.Label>
                    <ReactDatePicker
                      selected={
                        this.state.date !== "" && new Date(this.state.date)
                      }
                      className="react_date"
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        this.setState({
                          date: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                      placeholderText={PlaceHolder.DATE}
                    />
                  </Form.Group>
                </>
              )}

              {/* DEAD */}
              {this.state.heading_type === 4 && (
                <>
                  <Form.Group className="mb-3" controlId="date">
                    <Form.Label>{language.date_label}</Form.Label>
                    <ReactDatePicker
                      selected={
                        this.state.date !== "" && new Date(this.state.date)
                      }
                      className="react_date"
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        this.setState({
                          date: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                      placeholderText={PlaceHolder.DATE}
                    />
                  </Form.Group>
                </>
              )}

              {/* DEFAULT */}
              {this.state.heading_type === 0 && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{language.add_tags_label}</Form.Label>
                    <div className="heading_tag">
                      <MultiSelectTags
                        tags={this.props.allTags}
                        onChange={(value) => {
                          let tags = [];
                          let selected_status_tags = [];
                          value.map((tag) => {
                            return [
                              tags.push(tag.value),
                              selected_status_tags.push(tag),
                            ];
                          });

                          this.setState({
                            tags,
                            selected_status_tags,
                          });
                        }}
                        defaultTag={this.state.selected_status_tags}
                        // value={this.state.selected_status_tags}
                        placeholder={language.tags_plh}
                      />
                    </div>
                  </Form.Group>
                </>
              )}

              <Form.Group className="mb-3" controlId="comments">
                <Form.Label>
                  {language.comments_label}
                  {(this.state.heading_type === 4 ||
                    this.state.heading_type === 3) && (
                    <span className="required">*</span>
                  )}
                </Form.Label>

                <Form.Control
                  as="textarea"
                  rows="3"
                  name="comments"
                  value={this.state.comments}
                  placeholder={language.your_comment_plh}
                  onChange={this.handleOnChange}
                />

                <p className="invalid_input">{error.comments}</p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="comments">
                <Form.Check
                  type="checkbox"
                  id="1"
                  label={language.only_admin_can_view_label}
                  onChange={() => {
                    this.setState({
                      only_admin_view: this.state.only_admin_view === 0 ? 1 : 0,
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </div>

          <div className="status_footer">
            <Button
              type="submit"
              className="status_update"
              onClick={() => {
                // let { selected_financer } = this.props;

                // if (!this.state.local_financer && selected_financer) {
                //   this.setState({
                //     financer_contact_id: selected_financer.financer_contact_id,
                //     financer_name: selected_financer.financer_name,
                //   });
                // }

                let {
                  subject,

                  comments,

                  error,
                  get_sub_header,
                  sub_header_id,

                  financer_contact_id,
                  heading_type,
                  local_financer,
                  financer_name,
                } = this.state;

                let { selected_financer } = this.props;

                if (
                  (heading_type === 1 || heading_type === 2) &&
                  !local_financer &&
                  selected_financer
                ) {
                  financer_contact_id = selected_financer.financer_contact_id;
                  financer_name = selected_financer.financer_name;

                  this.setState({
                    financer_contact_id,
                    financer_name,
                  });
                }

                if (!subject) {
                  error.subject = "Please enter header";
                  this.setState({ error });
                  return;
                } else {
                  error.subject = "";
                  this.setState({ error });
                }

                if (get_sub_header && get_sub_header.length > 0) {
                  if (!sub_header_id) {
                    error.sub_header_id = "Please select sub header";
                    this.setState({ error });
                    return;
                  } else {
                    error.sub_header_id = "";
                    this.setState({ error });
                  }
                } else {
                  error.sub_header_id = "";
                  this.setState({ error });
                }

                if (
                  (heading_type === 1 || heading_type === 2) &&
                  !financer_contact_id
                ) {
                  error.financer_contact_id = "This field required";
                  this.setState({ error });
                  return;
                } else {
                  error.financer_contact_id = "";
                  this.setState({ error });
                }

                if ((heading_type === 4 || heading_type === 3) && !comments) {
                  error.comments = "Please enter comments";
                  this.setState({ error });
                  return;
                } else {
                  error.comments = "";
                  this.setState({ error });
                }

                const formData = this.state;
                formData.financer_contact_id = financer_contact_id;
                formData.financer_name = financer_name;

                this.props.updateStatus(formData);
                this.handleClose();
                this.clearData();
              }}
            >
              {language.update_btn}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default connect(mapStateToProps)(StatusChanges);
