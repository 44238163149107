import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./addequipment.css";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import axios from "../../config/axios";
import message from "../../utils/messages";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import Advertisiment from "./Advertisiment";
import CustomPopup from "../../utils/CustomPopup";
import config from "../../config/apiHeader";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class AddEquipmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isLoading: false,
      allIndustryList: [],
      allMachineTypeList: [],
      allBrandList: [],
      filteredMachineTypeList: [],
      allMachineModelList: [],
      industry_hash_id: "",
      equipment_type_hash_id: "",
      brand_hash_id: "",
      equipment_type: null,
      industry_type: null,
      brand_type: null,
      isOther: false,
      show: false,
      machineModelName: "",
      machine_details: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllMachineModelList();
    this.getAllIndustryList();
    this.getAllBrandList();
  }

  getAllMachineModelList = () => {
    this.setState({ isLoading: true });
    axios
      .post("/machine-model-list")
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let allMachineModelList = [];
          res.data.data.machines.map((machine) => {
            return allMachineModelList.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
              machine_details: machine,
            });
          });
          this.setState({ allMachineModelList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getAllIndustryList = () => {
    axios
      .get("/industry-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allIndustryList = [];
          res.data.data.industry.map((industry) => {
            return allIndustryList.push({
              value: industry.industry_hash_id,
              label: industry.industry_name,
            });
          });
          this.setState({ allIndustryList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getAllBrandList = () => {
    axios
      .get("brand-list", { call_from: "0" })
      .then((res) => {
        if (res.data.status === "1") {
          let allBrandList = [];
          res.data.data.brand.map((brand) => {
            return allBrandList.push({
              value: brand.brand_hash_id,
              label: brand.brand_name,
            });
          });
          this.setState({ allBrandList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getMachineTypeList = (id) => {
    this.setState({ isLoading: true });
    const formData = {
      industry_hash_id: id,
    };
    axios
      .post("/machine-type-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let allMachineTypeList = [];
          res.data.data.machine_types.map((machinetype) => {
            return allMachineTypeList.push({
              value: machinetype.machine_type_hash_id,
              label: machinetype.machine_type_name,
            });
          });
          this.setState({ allMachineTypeList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getFilteredMachineModelList = () => {
    this.setState({ isLoading: true });
    let { industry_hash_id, equipment_type_hash_id, brand_hash_id } =
      this.state;
    const formData = {
      model_name: "",
      brand_hash_id,
      equipment_type_hash_id,
      industry_hash_id,
    };
    axios
      .post("/machine-model-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          if (this.state.isOther) {
            let machine_details = res.data.data.machines[0];
            machine_details.industry_hash_id = industry_hash_id;
            this.props.history.push({
              pathname: "/add-equipment-two",
              state: { data: this.state, machine_details },
            });
          }
          let filteredMachineTypeList = [];
          res.data.data.machines.map((machine) => {
            return filteredMachineTypeList.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
              machine_details: machine,
            });
          });
          this.setState({ filteredMachineTypeList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  handleSelect = (key, v) => {
    this.setState({ [key]: v.value });
    if (key === "industry_hash_id") {
      if (
        v.value === "industry_lp5n761tvoebsghirc4w2qmzf" ||
        v.value === "industry_glr8y6enxifk0wjstbv41m9pd"
      ) {
        this.setState({ isOther: true });
      } else {
        this.getMachineTypeList(v.value);
        this.setState({ isOther: false });
      }
    }
    if (key === "machine_hash_id") {
      this.setState({ machine_details: v.machine_details });
      if (v.label === "Other") {
        this.setState({ show: true });
      } else {
        this.props.history.push({
          pathname: "/add-equipment-two",
          state: {
            data: this.state,
            machine_details: v.machine_details,
          },
        });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/add-equipment-two",
      state: { data: this.state, machine_details: this.state.machine_details },
    });
  };

  onSearchClick = () => {
    this.getFilteredMachineModelList();
    if (!this.state.isOther) {
      this.setState({ page: 2 });
    }
  };

  onClose = (show) => {
    this.setState({ show, machine_details: null, machineModelName: "" });
  };

  render() {
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.add_equipment;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <CustomPopup
                  title={language.enter_equipment_model_name}
                  show={this.state.show}
                  onClose={this.onClose}
                  isCenter={true}
                >
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                      <Form.Control
                        placeholder={language.type_your_model_name_plh}
                        value={this.state.machineModelName}
                        onChange={(e) => {
                          let { machine_details } = this.state;
                          machine_details.model_name = e.target.value;
                          this.setState({
                            machineModelName: e.target.value,
                            machine_details,
                          });
                        }}
                        name="machineModelName"
                        type="text"
                        required
                      />
                    </Form.Group>
                    <Button
                      type="submit"
                      className="submit_button commn_button"
                    >
                      {language.submit_btn}
                    </Button>
                  </Form>
                </CustomPopup>

                <Col lg="9" md="8" sm="12" className="ds_right">
                  <Row>
                    <Col lg="8" md="8" sm="12">
                      {this.state.page === 1 ? (
                        <div className="dashboard_right_panel">
                          <div className="ds_header">
                            <h2>{language.add_equipment_h}</h2>
                            <p>{language.add_equipment_desc}</p>
                          </div>
                          <div className="eqp_ind">
                            <div className="select_box_rent">
                              <div className="selboxind">
                                <Row>
                                  <Col lg="6" md="12" sm="12">
                                    <div className="sel_industry">
                                      <label>
                                        {language.select_industry_label}
                                      </label>
                                      <Select
                                        options={this.state.allIndustryList}
                                        value={this.state.industry_type}
                                        onChange={(v) => {
                                          this.handleSelect(
                                            "industry_hash_id",
                                            v
                                          );
                                          this.setState({
                                            equipment_type: null,
                                            equipment_type_hash_id: "",
                                            industry_type: v,
                                          });
                                        }}
                                        // className="select_inner_box"
                                        placeholder={
                                          language.select_industry_plh
                                        }
                                        name="industry_hash_id"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6" md="12" sm="12">
                                    <div className="sel_industry">
                                      <label>
                                        {language.type_of_equipment_label}
                                      </label>
                                      <Select
                                        value={this.state.equipment_type}
                                        options={this.state.allMachineTypeList}
                                        onChange={(v) => {
                                          this.handleSelect(
                                            "equipment_type_hash_id",
                                            v
                                          );
                                          this.setState({ equipment_type: v });
                                        }}
                                        // className="select_inner_box"
                                        placeholder={
                                          language.select_type_of_machine_plh
                                        }
                                        name="equipment_type_hash_id"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6" md="12" sm="12">
                                    <div className="sel_industry">
                                      <label>
                                        {language.equipment_brand_label}
                                      </label>
                                      <Select
                                        options={this.state.allBrandList}
                                        value={this.state.brand_type}
                                        onChange={(v) => {
                                          this.handleSelect("brand_hash_id", v);
                                          this.setState({ brand_type: v });
                                        }}
                                        // className="select_inner_box"
                                        placeholder={
                                          language.select_machine_brand_plh
                                        }
                                        name="brand_hash_id"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div className="search_but_add_eqp">
                                  <Button
                                    type="button"
                                    onClick={this.onSearchClick}
                                    className="btn_primary btn-sm"
                                  >
                                    {language.search_btn}
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="eqp_ind_or">
                              <span>{language.or_label}</span>
                            </div>
                            <Row>
                              <Col lg="12" md="12" sm="12">
                                <div className="sel_industry">
                                  <label>
                                    {language.equipment_model_name_label}
                                  </label>
                                  <Select
                                    options={this.state.allMachineModelList}
                                    onChange={(v) => {
                                      this.handleSelect("machine_hash_id", v);
                                    }}
                                    // className="select_inner_box"
                                    placeholder={language.select_model_name_plh}
                                    name="machine_hash_id"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        <>
                          {/* SEARCH EQUIPMENT */}
                          <div className="dashboard_right_panel">
                            <div className="ds_header">
                              <h2>{language.machine_model_name}</h2>
                              <p>{language.add_equipment_desc}</p>
                            </div>

                            <div className="eqp_ind">
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <div className="sel_industry">
                                    <label>
                                      {language.equipment_model_name_label}
                                    </label>
                                    <Select
                                      options={
                                        this.state.filteredMachineTypeList
                                      }
                                      onChange={(v) => {
                                        this.handleSelect("machine_hash_id", v);
                                      }}
                                      placeholder={
                                        language.select_model_name_plh
                                      }
                                      name="machine_hash_id"
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <div className="cancel_button">
                                <Button
                                  type="button"
                                  className="btn-dark"
                                  onClick={() => {
                                    this.setState({
                                      page: 1,
                                      machine_hash_id: "",
                                      machineModelName: "",
                                    });
                                  }}
                                >
                                  {language.cancel_btn}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                    <Advertisiment language={language} />
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(AddEquipmentPage);
