import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import validation from "../../../components/Validate/validator";
import config from "../../../config/apiHeader";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import AutoCompleteLocation from "../../../utils/AutoCompleteLocation";
import CountryCode from "../../../data/CountryCode";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { getFileExtension, MEDIA_BASE_URL } from "../../../utils/Helper";
import _ from "lodash";
import { prof_icon_menu, Search_Icon_Lead_Img } from "../../../assets/images";
import "./editprofile.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class EditBasicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",

      profile_type: "",
      profile_data: {}, // 1 -> basic, 2 -> seller, 3 -> byer, 4 -> rent

      error: {},

      isMobile: false,
      isEmail: false,

      alternate_phone: "",
      alternate_country_code: "",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    let { profile_type } = this.props.match.params;

    await this.setState({ profile_type });
    this.getProfileDetails();
  };

  getProfileDetails = () => {
    let { hash_id, isMobile, isEmail } = this.state;
    const formData = {
      hash_id,
    };
    this.setState({ isLoading: true });
    axios
      .post("/profile-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { profile_data } = res.data.data;
          let profileDetails;
          profile_data.forEach((profile) => {
            if (profile.profile_type === this.state.profile_type) {
              profileDetails = profile;
              return;
            }
          });

          isMobile = profileDetails.mobile ? true : false;
          isEmail = profileDetails.email ? true : false;

          let alternate_phone_no_country_code = [];

          let alternate_phone_no = [];

          profileDetails.alternate_phone_no.forEach((mobile) => {
            alternate_phone_no.push(mobile.phone_no);
            alternate_phone_no_country_code.push(mobile.country_code);
          });

          profileDetails.alternate_phone_no_country_code =
            alternate_phone_no_country_code;

          profileDetails.alternate_phone_no = alternate_phone_no;

          this.setState({ profile_data: profileDetails, isMobile, isEmail });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  handleImageChange = (e) => {
    e.persist();

    let { hash_id, profile_data } = this.state;

    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg"];
    if (file) {
      let extension = getFileExtension(file);
      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("user_hash_id", hash_id);

        this.setState({ isLoading: true });

        axios
          .post("/profile-image-upload", formData, config)
          .then((res) => {
            this.setState({ isLoading: false });
            if (res.data.status === "1") {
              message.success(res.data.message);
              profile_data.img_url = res.data.data.img_url;
              this.setState({
                profile_data,
              });
            } else {
              message.error(res.data.message);
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            console.error(err);
            message.error("Something went wrong!!");
          });
      } else {
        message.error("Enter Valid Image");
      }
    }
  };

  handleChange = (e) => {
    e.persist();

    let { error, profile_data, isEmail } = this.state;
    const { name, value } = e.target;

    profile_data[name] = value;

    this.setState({
      profile_data,
    });

    if (name === "email" && !value && !isEmail) {
      error[name] = "";
      this.setState({ error });
      return;
    }

    const valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  handleGetLocation = (value) => {
    if (value) {
      let { error, profile_data } = this.state;
      let { label, state, lat, long, city } = value;

      error.address = "";
      profile_data.address = label;
      profile_data.state = state;
      profile_data.lat = lat;
      profile_data.long = long;
      profile_data.city = city;

      this.setState({
        error,
        profile_data,
      });
    }
  };

  handleMobile = (value) => {
    let { error, profile_data, isMobile } = this.state;
    if (value) {
      let number = parsePhoneNumber(value);

      if (number) {
        let country_code = "+" + number.countryCallingCode;
        let mobile = number.nationalNumber;
        profile_data.country_code = country_code;
        profile_data.mobile = mobile;
        // this.setState({ profile_data });
      }

      if (isValidPhoneNumber(value)) {
        error.mobile = "";
      } else {
        error.mobile = "Enter valid mobile";
      }
      // this.setState({ error });
    } else {
      error.mobile = "";
      profile_data.country_code = "";
      profile_data.mobile = "";

      if (isMobile) {
        error.mobile = "Enter valid mobile";
      }
    }
    this.setState({
      error,
      profile_data,
    });
  };

  handleAlternatePhoneChange = (value) => {
    let { error } = this.state;

    if (value) {
      let number = parsePhoneNumber(value);

      if (number) {
        let alternate_country_code = "+" + number.countryCallingCode;
        this.setState({ alternate_phone: value, alternate_country_code });
      }

      if (isValidPhoneNumber(value)) {
        error.alternate_phone = "";
      } else {
        error.alternate_phone = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error.alternate_phone = "";
      this.setState({ error });
    }
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  updateProfile = () => {
    let { error, profile_data } = this.state;

    if (this.isValidForm(error)) {
      this.setState({ isLoading: true });

      axios
        .post("/profile-update", profile_data, config)
        .then((res) => {
          if (res.data.status === "1") {
            this.setProfileData(res.data.message);
          } else {
            this.setState({ isLoading: false });
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.error(err);
          message.error("Something went wrong!!");
        });
    }
  };

  setProfileData = (msg) => {
    let { hash_id } = this.state;
    const formData = {
      hash_id,
    };
    axios
      .post("/profile-details", formData, config)
      .then((res) => {
        message.success(msg);
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { profile_data } = res.data.data;

          profile_data.forEach((profile) => {
            if (profile.profile_type === "1") {
              localStorage.setItem("basicProfile", JSON.stringify(profile));
              return;
            }
          });
        }

        window.location.pathname = "profile";
      })
      .catch(() => {
        message.success(msg);

        this.setState({ isLoading: false });
        window.location.pathname = "profile";
      });
  };

  render() {
    let { profile_data, error } = this.state;
    let mobile = "";

    if (profile_data.country_code && profile_data.mobile) {
      mobile = profile_data.mobile.startsWith("+")
        ? profile_data.mobile
        : profile_data.country_code + profile_data.mobile;
    }

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.basic_profile;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel">
                    <div className="ds_header">
                      <h2>{language.basic_profile_details_h}</h2>
                    </div>
                    <div className="pe_form">
                      <div className="pu_area">
                        <div className="prf_holder">
                          <img
                            src={
                              profile_data.img_url
                                ? MEDIA_BASE_URL + profile_data.img_url
                                : prof_icon_menu
                            }
                            alt="HMC"
                          />

                          <label
                            className="custom_file"
                            htmlFor="inputGroupFile01"
                          >
                            <i className="fa fa-pencil" aria-hidden="true" />
                            <input
                              type="file"
                              onChange={this.handleImageChange}
                              className="custom-file-input"
                              id="inputGroupFile01"
                              accept="image/png, image/jpg, image/jpeg"
                              aria-describedby="inputGroupFileAddon01"
                            />
                          </label>
                        </div>
                      </div>

                      <Form>
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="group_partts"
                              controlId="formBasicText"
                            >
                              <Form.Label>
                                {language.first_name_label}
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={language.first_name_plh}
                                value={profile_data.first_name || ""}
                                onChange={this.handleChange}
                                name="first_name"
                                className="text_box"
                                validaterule={["required", "isName"]}
                                validatemsg={["Enter first name"]}
                              />
                              <span className="invalid_input">
                                {error.first_name &&
                                  language.enter_first_name_errormsg}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            <Form.Group
                              className="group_partts"
                              controlId="formBasicText"
                            >
                              <Form.Label>
                                {language.last_name_label}
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={language.last_name_plh}
                                value={profile_data.last_name || ""}
                                onChange={this.handleChange}
                                name="last_name"
                                className="text_box"
                                validaterule={["required", "isName"]}
                                validatemsg={["Enter last name"]}
                              />
                              <span className="invalid_input">
                                {error.last_name &&
                                  language.enter_last_name_errormsg}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            <Form.Group
                              className="group_partts"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                {language.email_label}
                                {this.state.isEmail && (
                                  <span className="required">*</span>
                                )}
                              </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder={language.email_plh}
                                value={profile_data.email || ""}
                                onChange={this.handleChange}
                                name="email"
                                className="text_box"
                                validaterule={["isEmail"]}
                                validatemsg={["Enter a valid email address"]}
                              />
                              <span className="invalid_input">
                                {error.email &&
                                  language.enter_valid_email_address_errormsg}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            <Form.Group
                              className="group_partts"
                              controlId="formBasicText"
                            >
                              <Form.Label>
                                {language.phone_number_label}
                                {this.state.isMobile && (
                                  <span className="required">*</span>
                                )}
                              </Form.Label>
                              <PhoneInput
                                className="phonenumber_field"
                                value={mobile}
                                onChange={this.handleMobile}
                                defaultCountry="IN"
                                countries={CountryCode}
                                name="mobile"
                                placeholder={language.type_phone_number_plh}
                              />
                              <span className="invalid_input">
                                {error.mobile &&
                                  language.enter_valid_mobile_errormsg}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group
                              controlId="dob"
                              className="group_partts"
                            >
                              <Form.Label>
                                {language.date_of_birth_label}
                              </Form.Label>

                              <ReactDatePicker
                                selected={
                                  profile_data.dob && new Date(profile_data.dob)
                                }
                                name="dob"
                                className="text_box"
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => {
                                  profile_data.dob =
                                    moment(date).format("YYYY-MM-DD");
                                  this.setState({ profile_data });
                                }}
                                placeholderText={language.date_of_birth_plh}
                                maxDate={new Date()}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={8}>
                            <Form.Group
                              controlId="text"
                              className="group_partts"
                            >
                              <Form.Label>
                                {language.address_label}
                                <span className="required">*</span>
                              </Form.Label>
                              <AutoCompleteLocation
                                handleGetLocation={this.handleGetLocation}
                                value={profile_data.address}
                              />
                              <span className="invalid_input">
                                {error.address}
                              </span>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            <Form.Group
                              className="group_partts"
                              controlId="formBasicText"
                            >
                              <Form.Label>
                                {language.alternate_phone_number_label}
                              </Form.Label>

                              <div className="eqp_name_fild">
                                <PhoneInput
                                  className="phonenumber_field"
                                  placeholder={
                                    language.alternate_type_phone_number_plh
                                  }
                                  value={this.state.alternate_phone}
                                  onChange={this.handleAlternatePhoneChange}
                                  defaultCountry="IN"
                                  countries={CountryCode}
                                  name="mobile"
                                />

                                <span
                                  className="alternate_phone_add"
                                  onClick={() => {
                                    let {
                                      alternate_phone,
                                      alternate_country_code,
                                    } = this.state;

                                    if (
                                      !error.alternate_phone &&
                                      alternate_phone.length >= 7
                                    ) {
                                      profile_data.alternate_phone_no.push(
                                        alternate_phone
                                      );
                                      profile_data.alternate_phone_no_country_code.push(
                                        alternate_country_code
                                      );

                                      this.setState({
                                        alternate_phone: "",
                                        alternate_country_code: "",
                                        profile_data,
                                      });
                                    }
                                  }}
                                >
                                  <img src={Search_Icon_Lead_Img} alt="HMC" />
                                </span>
                              </div>
                              <span className="invalid_input">
                                {error.alternate_phone &&
                                  language.enter_valid_mobile_errormsg}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="tag_name">
                          <ul>
                            {profile_data.alternate_phone_no?.map(
                              (value, key) => {
                                return (
                                  <li key={key}>
                                    {value}
                                    <span
                                      onClick={() => {
                                        profile_data.alternate_phone_no.splice(
                                          key,
                                          1
                                        );

                                        profile_data.alternate_phone_no_country_code.splice(
                                          key,
                                          1
                                        );

                                        this.setState({
                                          profile_data,
                                        });
                                      }}
                                    >
                                      X
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>

                        <Button
                          className="submit_form_home commn_button"
                          onClick={this.updateProfile}
                        >
                          {language.update_btn}
                        </Button>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(EditBasicProfile);
