import React, { Component } from "react";
import "./ContactUsPage.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import ContactPage from "./ContactPage";
import Seo from "../../components/seo/Seo";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../config/axios";
import { GetLanguageDetails } from "../../app/action/LanguageActions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

const mapActionToProps = (dispatch) => {
  return {
    getLanguageContent: (body) => dispatch(GetLanguageDetails(body)),
  };
};

class ContactUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      footer_faq_data: [],
      seo_data: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.getMetaData();

    // let { loading, language } = this.props.language;

    // if (!loading && !language) {
    //   this.props.getLanguageContent({});
    // }
  };

  getMetaData = () => {
    this.setState({ isLoading: true });
    axios
      .get(
        `/web-seo-content?page_key=contact_us&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { seo_data } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.contact_us;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {seo_data && (
          <Helmet>
            <title>{seo_data.meta_title}</title>
            <meta name="description" content={seo_data.meta_description} />
            <meta name="keywords" content={seo_data.meta_key} />
            <link rel="canonical" href={seo_data.canonical_url} />
          </Helmet>
        )}
        <div className="contactus_content">
          <div>
            <Container>
              <Row className="justify-content-center">
                <Col md={12}>
                  <div className="contact_text text-center">
                    <div className="commn_heading">
                      <h1>{language.get_in_expert_support_team}</h1>
                    </div>
                    <p>{language.get_in_expert_support_team_desc}</p>
                  </div>
                </Col>
              </Row>
            </Container>
            <ContactPage />
          </div>
        </div>
        <Seo {...this.state} />
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps, mapActionToProps)(ContactUsPage);
