import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { prof_icon_menu } from "../../assets/images";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import config from "../../config/apiHeader";
import axios from "../../config/axios";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import message from "../../utils/messages";
import "./myprofile.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",

      profile_data: [], // 1 -> basic, 2 -> seller, 3 -> byer, 4 -> rent
      user_data: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    this.getProfileDetails();
  };

  getProfileDetails = () => {
    let { hash_id } = this.state;
    const formData = {
      hash_id,
    };
    this.setState({ isLoading: true });
    axios
      .post("/profile-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { media_base_url } = res.data.data;
          localStorage.setItem("media_base_url", media_base_url);
          let { profile_data, user_data } = res.data.data;

          let data = [];
          profile_data.forEach((profile) => {
            if (profile.profile_type !== "3") data.push(profile);
            if (profile.profile_type === "1") {
              localStorage.setItem("basicProfile", JSON.stringify(profile));
            }
          });

          this.setState({ profile_data: data, user_data });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let { profile_data, user_data, hash_id } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.my_profiles;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel">
                    <h2>{language.my_profiles_h}</h2>
                  </div>
                  <Row>
                    {profile_data.map((profile, key) => {
                      let profile_name = "";

                      if (profile.profile_type === "1") {
                        profile_name = language.basic_profile;
                      } else if (profile.profile_type === "2") {
                        profile_name = language.seller_profile;
                      } else if (profile.profile_type === "3") {
                        profile_name = "Buyer Profile";
                      } else if (profile.profile_type === "4") {
                        profile_name = language.rent_profile;
                      } else if (profile.profile_type === "5") {
                        profile_name = language.parts_profile;
                      } else if (profile.profile_type === "6") {
                        profile_name = language.service_profile;
                      } else if (profile.profile_type === "7") {
                        profile_name = language.jobs_profile;
                      } else if (profile.profile_type === "8") {
                        profile_name = "Project Profile";
                      }

                      return (
                        <Col lg="4" md="6" sm="12" key={key}>
                          {profile.profile_type === "1" ? (
                            <Link
                              to={`/basic-profile/${hash_id}/${profile.profile_type}`}
                            >
                              <div className="buers_inner_profile text-center">
                                <div className="profile_image">
                                  <img
                                    alt="HMC"
                                    src={
                                      profile.img_url
                                        ? MEDIA_BASE_URL + profile.img_url
                                        : prof_icon_menu
                                    }
                                  />
                                </div>
                                <div className="profile_info">
                                  <div className="profile_type">
                                    <span>{profile_name}</span>
                                  </div>
                                  <h2>
                                    {profile.first_name || user_data.first_name}{" "}
                                    {profile.last_name || user_data.last_name}
                                  </h2>
                                  {profile.email && (
                                    <p>
                                      <i className="fa fa-envelope" />{" "}
                                      {profile.email}
                                    </p>
                                  )}
                                  {profile.mobile && (
                                    <p>
                                      <i className="fa fa-phone" />{" "}
                                      {profile.country_code
                                        ? profile.country_code +
                                          "" +
                                          profile.mobile
                                        : profile.mobile}
                                    </p>
                                  )}

                                  <div className="buyers_right">
                                    <i className="fa fa-check-circle-o" />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ) : (
                            <Link
                              to={`/edit-profile/${hash_id}/${profile.profile_type}`}
                            >
                              <div className="buers_inner_profile text-center">
                                <div className="profile_image">
                                  <img
                                    alt="HMC"
                                    src={
                                      profile.img_url
                                        ? MEDIA_BASE_URL + profile.img_url
                                        : prof_icon_menu
                                    }
                                  />
                                </div>
                                <div className="profile_info">
                                  <div className="profile_type">
                                    <span>{profile_name}</span>
                                  </div>
                                  <h2>{profile.business_name}</h2>
                                  {profile.business_email && (
                                    <p>
                                      <i className="fa fa-envelope" />{" "}
                                      {profile.business_email}
                                    </p>
                                  )}
                                  {profile.business_phone && (
                                    <p>
                                      <i className="fa fa-phone" />{" "}
                                      {profile.business_phone}
                                    </p>
                                  )}

                                  <div className="buyers_right">
                                    <i className="fa fa-check-circle-o" />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(Profile);
