import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Setting_Filter_Img } from "../../../assets/images";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import { Tabs, Tab } from "react-bootstrap";
import "./allLead.css";
import PreeAssignedLead from "./PreeAssignedLead";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";
import AdvanceFilter from "../AdvanceFilter";
import ChartView from "./chartView/ChartView";
import AssignedLead from "./AssignedLead";
import { Link } from "react-router-dom";
import MyLead from "./MyLead";
import config from "../../../config/apiHeader";
import { ActionTypes } from "../../../utils/ActionTypes";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class AllLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // DEFAULT FIELDS
      hash_id: "",
      isLoading: false,
      totalPage: 0,
      per_page: 10,
      currentPage: 1,
      totalItems: 0,
      tabkey: "mylead",
      dashboard_id: "",

      toggleEvent: false,

      pre_assigned_lead: [],
      review_lead: [],
      assigned_lead: [],
      my_lead: [],
      collapsedArray: [],

      all_tags: [],
      state_list: [],
      lead_header: [],
      contact_list: [],
      user_list: [],
      financer_list: [],
      contact_person_list: [],

      // ADVANCE FILTER
      isFiltered: false,
      customer_name: "",
      customer_phone: "",
      start_date: "",
      end_date: "",
      temp_lead_id: "",
      luid: [],
      customer_address_state: "",
      customer_address_city: "",
      euipment_category: "",
      temp_euipment_name: "",
      euipment_name: [],
      tags_id: [],
      selected_tags: [],
      finance_type: "",
      creator_id: "",
      assignee_name: "",
      status: "",
      sub_status: "",
      expected_closing_date: false,

      creator_details: null,
      assignee_details: null,
      lead_type: "",

      formData: {},

      local_sort_type: ActionTypes.SORT_CREATION_DATE,
      local_sort_order_type: "2", // 1 -> ASCENDING 2 -> DESCENDING
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let state = this.props.location.state;
    if (state) {
      let { tabkey, currentPage, formData } = state;
      if (formData) {
        Object.keys(formData).forEach(async (key) => {
          await this.setState({ [key]: formData[key] });
        });
      }

      await this.setState({
        tabkey,
        currentPage: currentPage ? currentPage : 1,
      });
    }

    let hash_id = new URLSearchParams(this.props.location.search).get(
      "hash_id"
    );

    if (
      hash_id &&
      hash_id !== JSON.parse(localStorage.getItem("userdata"))?.hash_id
    ) {
      this.setState({ isLoading: true });
      axios
        .post("/lead-web-auto-login", { hash_id })
        .then((res) => {
          if (res.data.status === "1") {
            localStorage.setItem("api-token", res.data.data.api_token_web);
            localStorage.setItem("userdata", JSON.stringify(res.data.data));

            const config = {
              headers: { api_token: res.data.data.api_token_web },
            };

            axios.post("/profile-details", { hash_id }, config).then((res) => {
              if (res.data.status === "1" && res.data.message === "Success") {
                let { media_base_url } = res.data.data;
                localStorage.setItem("media_base_url", media_base_url);
                res.data.data.profile_data.forEach((profile) => {
                  if (profile.profile_type === "1") {
                    localStorage.setItem(
                      "basicProfile",
                      JSON.stringify(profile)
                    );
                    window.location.reload();
                  }
                });
              }
            });
            // this.mySetupFunction();
          } else {
            this.props.history.push({
              pathname: "/",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ isLoading: false });
          message.error("Something went wrong!!");
        });
    } else {
      this.mySetupFunction();
    }
  };

  mySetupFunction = async () => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
      return;
    }

    let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
    if (sub_menus) {
      let { pathname } = this.props.history.location;
      pathname = pathname.substring(1);

      let res = _.find(sub_menus, (o) => {
        return o.url === pathname;
      });
      if (res) {
        await this.setState({ dashboard_id: res.dashboard_id });
        this.apiCallHere();
        this.getAllTagList();
        this.getAllStateList();
        this.getLeadHeaderList();
        this.getContactList();
        // this.getUserList();
        this.getFinancerList();
        this.getContactPersonList();
      } else {
        message.error(
          "Access Denied: Your account does not have sufficient permissions to perform the requested action."
        );
        this.props.history.push({
          pathname: "/",
        });
      }
    } else {
      message.error(
        "Access Denied: Your account does not have sufficient permissions to perform the requested action."
      );
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  getAllTagList = () => {
    let { hash_id } = this.state;

    const formData = {
      hash_id,
      medium: "web",
      section_name: "lm_get_all_tags",
    };

    axios
      .post("/all-tag-list-status-wise", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_tags = [];
          res.data.data.map((tag) => {
            return all_tags.push({
              label: tag.tag_title,
              value: tag.id,
              color: tag.tag_back_color,
              text_color: tag.tag_title_color,
            });
          });
          this.setState({ all_tags });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getAllStateList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_get_all_state",
    };

    axios
      .post("/state-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let { state_list } = res.data.data;
          this.setState({ state_list });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getLeadHeaderList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_all_header_list",
    };

    axios
      .post("/lead-header-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let lead_header = res.data.data.lead_header.map((header) => {
            return {
              label: header.header_label,
              value: header.id,
              header,
            };
          });
          this.setState({ lead_header });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getContactList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_contact_list",
    };

    axios
      .post("/contact-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let contact_list = res.data.data.map((contact) => {
            return {
              label:
                contact.contact_type === "1"
                  ? contact.first_name + " " + contact.last_name
                  : contact.company_name,
              value: contact.user_id,
              contact,
            };
          });
          let user_list = res.data.data.map((user) => {
            return {
              label:
                user.contact_type === "1"
                  ? user.first_name + " " + user.last_name
                  : user.company_name,
              value: user.id,
            };
          });
          this.setState({ user_list });
          this.setState({ contact_list });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getUserList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_user_list",
    };

    axios
      .post("/contact-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let user_list = res.data.data.map((user) => {
            return {
              label:
                user.contact_type === "1"
                  ? user.first_name + " " + user.last_name
                  : user.company_name,
              value: user.id,
            };
          });
          this.setState({ user_list });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getFinancerList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_financer_list",
    };

    axios
      .post("/get-financer-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ financer_list: res.data.data });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getContactPersonList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_contact_person",
    };
    axios
      .post("/get-contact-person-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ contact_person_list: res.data.data });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  onToggle = () => {
    if (this.state.tabkey === "chartview") return;
    this.setState({ toggleEvent: !this.state.toggleEvent });
  };

  tabChange = async (e) => {
    // CHNAGE TAB KEY

    // if (this.props.location.state?.tabkey) {
    //   this.props.location.state.tabkey = e;
    // }

    this.setState({
      local_sort_type: ActionTypes.SORT_CREATION_DATE,
      local_sort_order_type: "2", // 1 -> ASCENDING 2 -> DESCENDING
    });

    this.setState({
      tabkey: e,
      totalPage: 0,
      totalItems: 0,
      per_page: 10,
      currentPage: 1,

      // tags_id: [],
      // luid: [],
      // temp_lead_id: "",
      // customer_name: "",
      // customer_address_state: "",
      // customer_address_city: "",
      // euipment_name: [],
      // temp_euipment_name: "",
      // euipment_category: "",
      // start_date: "",
      // end_date: "",
      // selected_tags: [],
      // isFiltered: false,
      // toggleEvent: false,
    });

    this.resetFilter();
    // this.apiCallHere();
  };

  apiCallHere = () => {
    let {
      hash_id,
      currentPage,
      per_page,
      start_date,
      end_date,
      customer_name,
      customer_phone,
      customer_address_city,
      customer_address_state,
      euipment_category,
      tags_id,
      dashboard_id,
      finance_type,
      creator_id,
      assignee_name,
      status,
      sub_status,
      expected_closing_date,

      isFiltered,
      toggleEvent,
      lead_header,
      creator_details,
      assignee_details,
      lead_type,
    } = this.state;

    const data = {
      lead_header,
      hash_id,
      currentPage,
      per_page,
      start_date,
      end_date,
      customer_name,
      customer_phone,
      customer_address_city,
      customer_address_state,
      euipment_category,
      tags_id,
      dashboard_id,
      finance_type,
      creator_id,
      assignee_name,
      status,
      sub_status,
      luid: this.state.luid,
      euipment_name: this.state.euipment_name,
      expected_closing_date,

      isFiltered,
      toggleEvent,

      creator_details,
      assignee_details,
      lead_type,
    };

    const formData = {
      hash_id,
      per_page,
      page: currentPage,
      medium: "web",
      dashboard_id,

      start_date,
      end_date,
      customer_name,
      customer_phone,
      luid: this.state.luid.toString(),
      customer_address_city,
      customer_address_state,
      euipment_category,
      euipment_name: this.state.euipment_name.toString(),
      tags_id: tags_id.toString(),
      finance_type,
      creator_id,
      assignee_name,
      status,
      sub_status,
      expected_closing_date,
      lead_type,
    };

    this.setState({ formData: data });

    // if (this.props.location.state?.formData) {
    //   this.props.location.state.formData = null;
    // }

    this.setState({ my_lead: [], totalPage: 0, totalItems: 0 });

    switch (this.state.tabkey) {
      case "mylead":
        this.getMyLead(config, formData);
        break;
      case "preassigned":
        this.getPreAssignedLead(config, formData);
        break;
      case "review":
        this.getReviewLead(config, formData);
        break;
      case "assigned":
        this.getAssignedLead(config, formData);
        break;
      case "chartview":
        this.chartViewLead(config, formData);
        break;
      default:
        message.error("No Active Event Key Found");
    }
  };

  getPreAssignedLead = (config, formData) => {
    this.setLoading(true);

    formData.section_name = "lm_all_get_preassigned_lead";
    axios
      .post("/get-pree-assigned-list", formData, config)
      .then((res) => {
        this.setLoading(false);
        if (res.data.status === "1") {
          let { assigned_lead, last_page, total } = res.data.data;
          let size = assigned_lead.length;
          let collapsedArray = _.fill(Array(size), this.state.isFiltered);
          this.setState({
            my_lead: assigned_lead,
            totalPage: last_page,
            collapsedArray,
            totalItems: total,
          });
          if (total === 0) {
            message.warning("No Pre Assigned Lead Found!!");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setLoading(false);
        message.error("Something went wrong!!");
      });
  };

  getReviewLead = (config, formData) => {
    this.setLoading(true);

    formData.section_name = "lm_all_get_review_lead";
    axios
      .post("/get-review-lead", formData, config)
      .then((res) => {
        this.setLoading(false);
        if (res.data.status === "1") {
          let { review_lead, last_page, total } = res.data.data;
          let size = review_lead.length;
          let collapsedArray = _.fill(Array(size), this.state.isFiltered);
          this.setState({
            my_lead: review_lead,
            totalPage: last_page,
            collapsedArray,
            totalItems: total,
          });
          if (total === 0) {
            message.warning("No Review Lead Found!!");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setLoading(false);
        message.error("Something went wrong!!");
      });
  };

  getAssignedLead = (config, formData) => {
    this.setLoading(true);

    formData.section_name = "lm_all_get_assigned_lead";
    axios
      .post("/get-assigned-list", formData, config)
      .then((res) => {
        this.setLoading(false);
        if (res.data.status === "1") {
          let { assigned_lead, last_page, total } = res.data.data;
          let size = assigned_lead.length;
          let collapsedArray = _.fill(Array(size), this.state.isFiltered);
          this.setState({
            my_lead: assigned_lead,
            totalPage: last_page,
            collapsedArray,
            totalItems: total,
          });
          if (total === 0) {
            message.warning("No Assign Lead Found!!");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setLoading(false);
        message.error("Something went wrong!!");
      });
  };

  chartViewLead = (config, formData) => {
    console.log("Chart View");
  };

  getMyLead = (config, formData) => {
    this.setLoading(true);

    formData.section_name = "lm_get_all_leads";

    axios
      .post("/get-my-lead", formData, config)
      .then((res) => {
        this.setLoading(false);
        if (res.data.status === "1") {
          let { my_lead, last_page, total } = res.data.data;
          let size = my_lead.length;
          let collapsedArray = _.fill(Array(size), this.state.isFiltered);
          this.setState({
            my_lead,
            totalPage: last_page,
            collapsedArray,
            totalItems: total,
          });
          if (total === 0) {
            message.warning("No Lead Found!!");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setLoading(false);
        message.error("Something went wrong!!");
      });
  };

  changeCollapsed = async (pos) => {
    let { collapsedArray } = this.state;
    collapsedArray[pos] = !collapsedArray[pos];
    await this.setState({ collapsedArray });
  };

  deleteLead = (lead_hash_id) => {
    this.setLoading(true);

    const formData = {
      lead_hash_id,
      medium: "web",
      section_name: "lm_delete_all_lead",
    };

    axios
      .post("/lead-delete", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.apiCallHere();
          message.success(res.data.message);
        } else {
          this.setLoading(false);
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setLoading(false);
        message.error("Something went wrong!!");
      });
  };

  handleOnChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "per_page") {
      await this.setState({ [name]: value, currentPage: 1 });
      this.apiCallHere();
      return;
    }
    await this.setState({ [name]: value });
  };

  onPageChange = async (val) => {
    let currentPage = val.selected + 1;
    await this.setState({ currentPage });
    this.apiCallHere();
  };

  resetFilter = async () => {
    this.setState(
      {
        tags_id: [],
        luid: [],
        temp_lead_id: "",
        customer_name: "",
        customer_address_state: "",
        customer_address_city: "",
        euipment_name: [],
        temp_euipment_name: "",
        euipment_category: "",
        start_date: "",
        end_date: "",
        selected_tags: [],
        isFiltered: false,
        toggleEvent: false,
        finance_type: "",
        creator_id: "",
        assignee_name: "",
        status: "",
        sub_status: "",
        expected_closing_date: false,

        creator_details: null,
        assignee_details: null,
        lead_type: "",
      },
      () => {
        this.apiCallHere();
      }
    );
  };

  setKeyWithValue = async (key, value) => {
    await this.setState({ [key]: value });
  };

  removeLuid = (index) => {
    let { luid } = this.state;
    luid.splice(index, 1);
    this.setState({ luid });
  };

  removeEquipment = (index) => {
    let { euipment_name } = this.state;
    euipment_name.splice(index, 1);
    this.setState({ euipment_name });
  };

  tagChanges = (value) => {
    let tags_id = [];
    let selected_tags = [];
    value.map((tag) => {
      return [tags_id.push(tag.value), selected_tags.push(tag)];
    });

    this.setState({ tags_id, selected_tags });
  };

  singleSelect = (key, value) => {
    this.setState({ [key]: value.value });
  };

  onAdvanceFilterClick = async () => {
    let { luid, temp_lead_id, euipment_name, temp_euipment_name } = this.state;

    await this.setState({
      isFiltered: true,
      totalPage: 0,
      per_page: 10,
      currentPage: 1,
    });
    if (temp_lead_id) {
      luid.push(temp_lead_id);
      await this.setState({ temp_lead_id: "", luid });
    }
    if (temp_euipment_name) {
      euipment_name.push(temp_euipment_name);
      await this.setState({ temp_euipment_name: "", euipment_name });
    }

    this.apiCallHere();
  };

  setLoading = async (isLoading) => {
    await this.setState({ isLoading });
    if (isLoading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!isLoading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  };

  localSorting = async (e) => {
    e.persist();
    let { name, value } = e.target;
    this.setState({ [name]: value });

    let { my_lead } = this.state;

    switch (value) {
      case ActionTypes.SORT_CREATION_DATE:
        my_lead = _.sortBy(my_lead, [
          (o) => {
            return o.created_at;
          },
        ]);

        // IF DESCENDING SELECTED
        if (this.state.local_sort_order_type === "2") {
          my_lead = _.reverse(my_lead);
        }
        this.setState({ my_lead });
        break;
      case ActionTypes.SORT_MODIFICATION_DATE:
        my_lead = _.sortBy(my_lead, [
          (o) => {
            return o.updated_at;
          },
        ]);

        // IF DESCENDING SELECTED
        if (this.state.local_sort_order_type === "2") {
          my_lead = _.reverse(my_lead);
        }
        this.setState({ my_lead });
        break;
    }
  };

  render() {
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.all_leads;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel mb-5 pb-3">
                    <Row>
                      <Col
                        lg={this.state.toggleEvent ? "9" : "12"}
                        className="lead_resuuultts"
                      >
                        <div className="my_lead_section">
                          <div className="ds_header">
                            <h2>{language.all_leads_h}</h2>
                          </div>
                          <div className="my_lead_heading">
                            <div className="show_entries">
                              <label>{language.short_by}</label>
                              <select
                                className="sorting_field"
                                name="local_sort_type"
                                onChange={this.localSorting}
                                value={this.state.local_sort_type}
                              >
                                <option value={ActionTypes.SORT_CREATION_DATE}>
                                  {language.short_by_creation_date}
                                </option>
                                <option
                                  value={ActionTypes.SORT_MODIFICATION_DATE}
                                >
                                  {language.short_by_modification_date}
                                </option>
                              </select>
                            </div>

                            <div className="show_entries">
                              <label>{language.order_by}</label>
                              <select
                                className="sorting_field"
                                name="local_sort_order_type"
                                onChange={(e) => {
                                  this.setState({
                                    local_sort_order_type: e.target.value,
                                  });

                                  let { my_lead } = this.state;
                                  my_lead = _.reverse(my_lead);

                                  this.setState({ my_lead });
                                }}
                                value={this.state.local_sort_order_type}
                              >
                                <option value="1">
                                  {language.order_by_ascending}
                                </option>
                                <option value="2">
                                  {language.order_by_descending}
                                </option>
                              </select>
                            </div>

                            <div className="mylead_heading_right">
                              <div className="show_entries">
                                <label>{language.show_entries}</label>
                                <select
                                  className="show_field"
                                  name="per_page"
                                  onChange={this.handleOnChange}
                                  value={this.state.per_page}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>

                              {this.state.tabkey === "mylead" && (
                                <Link
                                  to="/add-lead"
                                  className="btn btn_primary btn-sm"
                                >
                                  {language.add_lead_btn}
                                </Link>
                              )}

                              <Button
                                className="toggle filter_buttonner btn_primary btn-sm ml-2"
                                onClick={this.onToggle}
                              >
                                <img width={14} src={Setting_Filter_Img} />
                              </Button>
                            </div>
                          </div>
                          <div className="lead_tabss">
                            <Tabs
                              defaultActiveKey="mylead"
                              activeKey={this.state.tabkey}
                              onSelect={(key) => this.tabChange(key)}
                            >
                              <Tab
                                eventKey="mylead"
                                title={language.my_lead_tab}
                              >
                                {this.state.tabkey === "mylead" && (
                                  <MyLead
                                    my_lead={this.state.my_lead}
                                    collapsedArray={this.state.collapsedArray}
                                    changeCollapsed={this.changeCollapsed}
                                    getLead={this.apiCallHere}
                                    deleteLead={this.deleteLead}
                                    totalPage={this.state.totalPage}
                                    onPageChange={this.onPageChange}
                                    allTags={this.state.all_tags}
                                    setLoading={this.setLoading}
                                    contactList={this.state.contact_list}
                                    dashboard_id={this.state.dashboard_id}
                                    totalItems={this.state.totalItems}
                                    per_page={this.state.per_page}
                                    currentPage={this.state.currentPage}
                                    formData={this.state.formData}
                                  />
                                )}
                              </Tab>
                              {(localStorage.getItem("lead_user_role") ===
                                "1" ||
                                (localStorage.getItem("lead_user_role") ===
                                  "5") |
                                  (localStorage.getItem("lead_user_role") ===
                                    "8")) && (
                                <Tab
                                  eventKey="preassigned"
                                  title={`${language.total_leads_tab} ${
                                    this.state.tabkey === "preassigned"
                                      ? `(${this.state.totalItems})`
                                      : ""
                                  }`}
                                >
                                  {this.state.tabkey === "preassigned" && (
                                    <PreeAssignedLead
                                      pre_assigned_lead={this.state.my_lead}
                                      collapsedArray={this.state.collapsedArray}
                                      changeCollapsed={this.changeCollapsed}
                                      getLead={this.apiCallHere}
                                      deleteLead={this.deleteLead}
                                      totalPage={this.state.totalPage}
                                      onPageChange={this.onPageChange}
                                      allTags={this.state.all_tags}
                                      setLoading={this.setLoading}
                                      leadHeader={this.state.lead_header}
                                      contactList={this.state.contact_list}
                                      dashboard_id={this.state.dashboard_id}
                                      totalItems={this.state.totalItems}
                                      per_page={this.state.per_page}
                                      currentPage={this.state.currentPage}
                                      financerList={this.state.financer_list}
                                      contactPersonList={
                                        this.state.contact_person_list
                                      }
                                      formData={this.state.formData}
                                    />
                                  )}
                                </Tab>
                              )}

                              {/* <Tab eventKey="review" title="Review">
                                {this.state.tabkey === "review" && (
                                  <ReviewLead
                                    review_lead={this.state.review_lead}
                                    collapsedArray={this.state.collapsedArray}
                                    changeCollapsed={this.changeCollapsed}
                                    getLead={this.apiCallHere}
                                    totalPage={this.state.totalPage}
                                    onPageChange={this.onPageChange}
                                    allTags={this.state.all_tags}
                                    setLoading={this.setLoading}
                                    leadHeader={this.state.lead_header}
                                    contactList={this.state.contact_list}
                                    dashboard_id={this.state.dashboard_id}
                                    totalItems={this.state.totalItems}
                                    per_page={this.state.per_page}
                                    currentPage={this.state.currentPage}
                                  />
                                )}
                              </Tab> */}
                              {(localStorage.getItem("lead_user_role") ===
                                "1" ||
                                (localStorage.getItem("lead_user_role") ===
                                  "5") |
                                  (localStorage.getItem("lead_user_role") ===
                                    "8")) && (
                                <Tab
                                  eventKey="assigned"
                                  title={language.assigned_lead_tab}
                                >
                                  {this.state.tabkey === "assigned" && (
                                    <AssignedLead
                                      assigned_lead={this.state.my_lead}
                                      collapsedArray={this.state.collapsedArray}
                                      changeCollapsed={this.changeCollapsed}
                                      getLead={this.apiCallHere}
                                      totalPage={this.state.totalPage}
                                      onPageChange={this.onPageChange}
                                      allTags={this.state.all_tags}
                                      setLoading={this.setLoading}
                                      leadHeader={this.state.lead_header}
                                      contactList={this.state.contact_list}
                                      dashboard_id={this.state.dashboard_id}
                                      totalItems={this.state.totalItems}
                                      per_page={this.state.per_page}
                                      currentPage={this.state.currentPage}
                                      financerList={this.state.financer_list}
                                      contactPersonList={
                                        this.state.contact_person_list
                                      }
                                      formData={this.state.formData}
                                    />
                                  )}
                                </Tab>
                              )}

                              {/* {localStorage.getItem("lead_user_role") ===
                                "1" && (
                                <Tab eventKey="chartview" title="Chart View">
                                  {this.state.tabkey === "chartview" && (
                                    <ChartView
                                    // review_lead={this.state.review_lead}
                                    // collapsedArray={this.state.collapsedArray}
                                    // changeCollapsed={this.changeCollapsed}
                                    // getLead={this.apiCallHere}
                                    // totalPage={this.state.totalPage}
                                    // onPageChange={this.onPageChange}
                                    // allTags={this.state.all_tags}
                                    // setLoading={this.setLoading}
                                    // leadHeader={this.state.lead_header}
                                    // contactList={this.state.contact_list}
                                    />
                                  )}
                                </Tab>
                              )} */}
                            </Tabs>
                          </div>
                        </div>
                      </Col>
                      {this.state.toggleEvent && (
                        <AdvanceFilter
                          resetFilter={this.resetFilter}
                          myState={this.state}
                          handleOnChange={this.handleOnChange}
                          setKeyWithValue={this.setKeyWithValue}
                          removeLuid={this.removeLuid}
                          removeEquipment={this.removeEquipment}
                          tagChanges={this.tagChanges}
                          singleSelect={this.singleSelect}
                          onAdvanceFilterClick={this.onAdvanceFilterClick}
                          activeKey="all_lead"
                        />
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(AllLead);
