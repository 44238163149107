import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import { Tag_Edit_Img, tag_delte } from "../../../assets/images";
import "./taglisting.css";
import CustomPopup from "../../../utils/CustomPopup";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import DeletePopup from "../../../utils/DeletePopup";
import ExitPopup from "../../../utils/ExitPopup";
import config from "../../../config/apiHeader";
import _ from "lodash";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class TagListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      isLoading: false,
      show: false,
      tag_back_color: "#ffe49e",
      tag_title_color: "#0e8a83",
      tag_title: "",
      is_universal: "0",

      my_tags: [],
      add_tag: false,
      calling_get_tag: false,

      // EDIT TAG
      isEdit: false,
      tag_hash_id: "",

      // DELETE TAG
      delete_tag_hash_id: "",
      isDelete: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
    if (sub_menus) {
      let { pathname } = this.props.history.location;
      pathname = pathname.substring(1);

      let res = _.find(sub_menus, (o) => {
        return o.url === pathname;
      });
      if (res) {
        this.getMyTags();
      } else {
        message.error(
          "Access Denied: Your account does not have sufficient permissions to perform the requested action."
        );
        this.props.history.push({
          pathname: "/",
        });
      }
    } else {
      message.error(
        "Access Denied: Your account does not have sufficient permissions to perform the requested action."
      );
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  getMyTags = () => {
    let { hash_id } = this.state;
    const formData = {
      hash_id,
      page: 1,
      medium: "web",
      section_name: "lm_get_my_tags",
    };
    this.setState({ isLoading: true });
    axios
      .post("/get-my-tag", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { my_tags, total } = res.data.data;
          this.setState({ my_tags });
          if (total === 0) {
            message.warning("No Tags Found! Please Create A New Tag");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  onClose = (show) => {
    if (this.state.calling_get_tag) {
      this.getMyTags();
    }
    this.setState({
      show,
      tag_title: "",
      isEdit: false,
      tag_back_color: "#ffe49e",
      tag_title_color: "#0e8a83",
      calling_get_tag: false,
    });
  };

  handleChanges = async (e) => {
    e.persist();
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let {
      hash_id,
      tag_title,
      tag_title_color,
      tag_back_color,
      isEdit,
      tag_hash_id,
      is_universal,
    } = this.state;

    const formData = {
      hash_id,
      tag_title,
      tag_title_color,
      tag_back_color,
      medium: "web",
      section_name: "lm_add_tag",
      tag_hash_id,
      is_universal,
    };
    this.setState({ isLoading: true, show: false });

    if (isEdit) {
      this.setState({ isEdit: false });
      formData.section_name = "lm_edit_my_tag";
      axios
        .post("/tag-update", formData, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.status === "1") {
            this.getMyTags();
            message.success(res.data.message);
            this.setState({
              tag_back_color: "#ffe49e",
              tag_title_color: "#0e8a83",
              tag_title: "",
              is_universal: "0",
            });
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.error(err);
          message.error("Something went wrong!!");
        });
      return;
    }

    axios
      .post("/add-tag", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.setState({
            tag_back_color: "#ffe49e",
            tag_title_color: "#0e8a83",
            tag_title: "",
            add_tag: true,
            is_universal: "0",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  editTag = async (index) => {
    let { my_tags } = this.state;
    const tag = my_tags[index];
    await this.setState({
      tag_hash_id: tag.hash_id,
      tag_back_color: tag.tag_back_color,
      tag_title: tag.tag_title,
      tag_title_color: tag.tag_title_color,
      is_universal: tag.is_universal,
      show: true,
      isEdit: true,
    });
  };

  deleteTag = () => {
    let { hash_id, delete_tag_hash_id } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };
    const formData = {
      tag_hash_id: delete_tag_hash_id,
      medium: "web",
      section_name: "lm_delete_my_tag",
    };
    this.setState({ isLoading: true, isDelete: false });

    axios
      .post("/tag-delete", formData, config)
      .then((res) => {
        this.setState({ isLoading: false, delete_tag_hash_id: "" });
        if (res.data.status === "1") {
          this.getMyTags();
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let { my_tags } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.tag;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <CustomPopup
                  title={
                    this.state.isEdit
                      ? language.update_your_tag_h
                      : language.create_new_tag_h
                  }
                  show={this.state.show}
                  onClose={this.onClose}
                  isCenter={true}
                >
                  <div className="custom_popuped">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{language.tag_title_label}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={language.enter_your_tag_title_plh}
                          className="tag_field_text"
                          name="tag_title"
                          required
                          value={this.state.tag_title}
                          onChange={this.handleChanges}
                        />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group className="select_text_color">
                            <Form.Label htmlFor="exampleColorInput">
                              {language.select_text_color_label} :
                            </Form.Label>
                            <Form.Control
                              type="color"
                              title="Choose your text color"
                              className="text_color_code"
                              name="tag_title_color"
                              value={this.state.tag_title_color}
                              onChange={this.handleChanges}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="select_text_color">
                            <Form.Label htmlFor="exampleColorInput">
                              {language.select_tag_background_colur_label} :
                            </Form.Label>
                            <Form.Control
                              type="color"
                              value={this.state.tag_back_color}
                              title="Choose your background color"
                              className="text_color_code"
                              name="tag_back_color"
                              onChange={this.handleChanges}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="tag_prev">
                        <Form.Label className="mb-0 mr-2">
                          {language.preview}
                        </Form.Label>
                        {this.state.tag_title && (
                          <span
                            className="preview_holder"
                            style={{
                              background: this.state.tag_back_color,
                              color: this.state.tag_title_color,
                            }}
                          >
                            {this.state.tag_title}
                          </span>
                        )}
                      </Form.Group>

                      {localStorage.getItem("lead_user_role") === "1" && (
                        <Form.Group className="global_tag">
                          <Form.Control
                            type="checkbox"
                            checked={this.state.is_universal === "1"}
                            id="is_universal"
                            onChange={() => {
                              this.setState({
                                is_universal:
                                  this.state.is_universal === "0" ? "1" : "0",
                              });
                            }}
                            name="is_universal"
                          />
                          <Form.Label htmlFor="is_universal" className="mb-0">
                            {language.global_tag}
                          </Form.Label>
                        </Form.Group>
                      )}

                      <div className="submit_lead lead_listing_submit">
                        <Button type="submit" className="btn_primary">
                          {this.state.isEdit
                            ? language.update_btn
                            : language.submit_btn}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CustomPopup>

                <DeletePopup
                  show={this.state.isDelete}
                  message={language.tag_delete_alert_msg}
                  yes_msg={language.yes_btn}
                  no_msg={language.no_btn}
                  no={() => {
                    this.setState({ isDelete: false });
                  }}
                  yes={this.deleteTag}
                />

                <ExitPopup
                  show={this.state.add_tag}
                  message={language.tag_added_successfully}
                  add_title={language.add_another_tag_btn}
                  back_title={language.back_to_tag_listing_btn}
                  add={() => {
                    this.setState({
                      add_tag: false,
                      show: true,
                      calling_get_tag: true,
                    });
                  }}
                  back={() => {
                    this.setState({ add_tag: false });
                    this.getMyTags();
                  }}
                />

                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="tag_listing_inner_part">
                    <div className="tag_listing_inner_inner_part">
                      <div className="tag_listing_header">
                        <h2>{language.my_tag_list_h}</h2>
                        <Button
                          className="btn_primary btn-sm"
                          onClick={() => {
                            this.setState({ show: true });
                          }}
                        >
                          {language.create_tag_btn}
                        </Button>
                      </div>
                      <div className="tag_listing_body">
                        {my_tags &&
                          my_tags.map((tag, index) => {
                            return (
                              <div
                                className="tag_listing_inner_body"
                                key={index}
                              >
                                <label
                                  className="tag_text"
                                  style={{
                                    background: tag.tag_back_color,
                                    color: tag.tag_title_color,
                                  }}
                                >
                                  {tag.tag_title}
                                </label>
                                <div className="tag_action">
                                  <div className="tag_edit">
                                    <img
                                      src={Tag_Edit_Img}
                                      alt="HMC"
                                      onClick={() => {
                                        this.editTag(index);
                                      }}
                                    />
                                  </div>
                                  <div className="tag_delete">
                                    <img
                                      src={tag_delte}
                                      alt="HMC"
                                      onClick={() => {
                                        this.setState({
                                          delete_tag_hash_id: tag.hash_id,
                                          isDelete: true,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(TagListing);
