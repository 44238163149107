import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "react-bootstrap";
import "./popup.css";
import LANGUAGE_DATA from "../config/LanguageData";

export default class LocationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
    };
  }

  render() {
    return (
      <Modal show={this.props.show} backdrop="static" centered={true}>
        <Modal.Body>
          <h5>Please Select Your Country</h5>
          <Form
            className="mt-5"
            onSubmit={(e) => {
              e.preventDefault();
              let { country } = this.state;
              localStorage.setItem("country", country);
              let selected_language = LANGUAGE_DATA[country];
              localStorage.setItem("country", country);
              localStorage.setItem("language", selected_language.language);

              window.location.reload();
            }}
          >
            <div className="delete_style">
              <Form.Check
                name="country"
                value="IN"
                type="radio"
                label="India"
                id="in"
                required
                onChange={(e) => {
                  this.setState({ country: "IN" });
                }}
              />
              <Form.Check
                name="country"
                value="SA"
                type="radio"
                label="Saudi"
                id="in"
                required
                onChange={(e) => {
                  this.setState({ country: "SA" });
                }}
              />
            </div>
            <div className="delete_style mt-5">
              <Button type="submit">Proceed</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
LocationPopup.propTypes = {
  show: PropTypes.bool.isRequired,
};
