import React, { Component } from "react";

export default class LocationPin extends Component {
  render() {
    return (
      <>
        <div className="pin">
          {/* <img
            src="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
            alt="map icon"
            className="pin-icon"
          /> */}
        </div>
        <div className="pulse" />
      </>
    );
  }
}
