import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { Expert3 } from "../../images";

class ExpertAdvice extends Component {
  render() {
    return (
      <div className="expert_advice expert_advice_2">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12" xs="12">
              <img src={Expert3} alt="HMC" />
            </Col>

            <Col lg="6" md="6" sm="12" xs="12">
              <h5>Expert Advice</h5>

              <p>
                From maintenance tips to finding the best heavy equipment for
                you, a trusted industrial expert like the ones we have here in
                our portal can save you a lot of time & effort.
              </p>

              <div className="ctb_button">
                <Link to="/expert-connect">connect</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ExpertAdvice;
