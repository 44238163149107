import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { filter_option, Tag_plus_Img } from "../../assets/images";
import MultiSelectTags from "./MultiSelectTags";
import moment from "moment";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class AdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      get_sub_header: [],
      lead_header: [],
    };
  }

  componentDidMount = () => {
    const lead_header = [...this.props.myState.lead_header];

    if (lead_header.length) {
      let lastValue = lead_header[lead_header.length - 1];

      if (lastValue.value !== "Commision Paid") {
        lead_header.push({
          label: "Commision Paid",
          value: "Commision Paid",
          header: {
            get_sub_header: [],
          },
        });
      }
    }

    this.setState({ lead_header });
  };

  handleSelectData = async (value) => {
    if (value) {
      if (value.__isNew__) {
        await this.setState({
          get_sub_header: [],
        });
      } else {
        let get_sub_header = value.header.get_sub_header;
        await this.setState({
          get_sub_header,
        });
      }
    } else {
      await this.setState({
        get_sub_header: [],
      });
    }
  };

  render() {
    let { get_sub_header, lead_header } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.advance_filter;
    } else {
      language = {};
    }

    return (
      <Col lg="3" md="12" sm="12" className="lead_filter my-collapsible">
        <div className="lead_right my-collapsible__content">
          <div className="mylead_filter_heading">
            <div>
              <span className="filter_left_icon">
                <img src={filter_option} alt="Filter Icon" />
              </span>
              {language.filter_h}
            </div>
            <div>
              <Button
                type="button"
                className="reset_but"
                onClick={this.props.resetFilter}
              >
                {language.reset_btn}
              </Button>
            </div>
          </div>

          <div className="tag_filter">
            <div className="filter_text">
              <MultiSelectTags
                tags={this.props.myState.all_tags}
                onChange={this.props.tagChanges}
                defaultTag={this.props.myState.selected_tags}
                placeholder={language.tags_plh}
              />
            </div>

            <div className="filter_text">
              <Form.Control
                type="text"
                placeholder={language.lead_unique_id_plh}
                className="tag_field"
                name="temp_lead_id"
                value={this.props.myState.temp_lead_id}
                onChange={this.props.handleOnChange}
              />

              <div className="tagsearch">
                <span>
                  <img
                    src={Tag_plus_Img}
                    alt="HMC"
                    onClick={() => {
                      let { luid, temp_lead_id } = this.props.myState;
                      if (temp_lead_id) {
                        luid.push(temp_lead_id);
                        this.props.setKeyWithValue("luid", luid);
                        this.props.setKeyWithValue("temp_lead_id", "");
                      }
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="tag_name">
              <ul>
                {this.props.myState.luid.map((value, key) => {
                  return (
                    <li key={key}>
                      {value}
                      <span
                        onClick={() => {
                          this.props.removeLuid(key);
                        }}
                      >
                        X
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="filter_text">
              <Form.Control
                type="text"
                placeholder={language.customer_name_plh}
                className="tag_field"
                name="customer_name"
                onChange={this.props.handleOnChange}
                value={this.props.myState.customer_name}
              />
            </div>

            <div className="filter_text">
              <Form.Control
                type="text"
                placeholder={language.customer_phone_plh}
                className="tag_field"
                name="customer_phone"
                onChange={this.props.handleOnChange}
                value={this.props.myState.customer_phone}
              />
            </div>

            <div className="filter_text">
              <Form.Control
                type="text"
                placeholder={language.city_plh}
                className="tag_field"
                name="customer_address_city"
                onChange={this.props.handleOnChange}
                value={this.props.myState.customer_address_city}
              />
            </div>

            <div className="select_partts mt-0">
              <Form.Control
                as="select"
                className="select_boxqaw"
                onChange={this.props.handleOnChange}
                name="customer_address_state"
                value={this.props.myState.customer_address_state}
              >
                <option value="">{language.state_plh}</option>
                {this.props.myState.state_list &&
                  this.props.myState.state_list.map((val, key) => {
                    return (
                      <option value={val.name} key={key}>
                        {val.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>

            <div className="filter_text">
              <Form.Control
                type="text"
                placeholder={language.equipment_plh}
                className="tag_field"
                name="temp_euipment_name"
                value={this.props.myState.temp_euipment_name}
                onChange={this.props.handleOnChange}
              />

              <div className="tagsearch">
                <span>
                  <img
                    src={Tag_plus_Img}
                    alt="HMC"
                    onClick={() => {
                      let { temp_euipment_name, euipment_name } =
                        this.props.myState;
                      if (temp_euipment_name) {
                        euipment_name.push(temp_euipment_name);
                        this.props.setKeyWithValue(
                          "euipment_name",
                          euipment_name
                        );
                        this.props.setKeyWithValue("temp_euipment_name", "");
                      }
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="tag_name">
              <ul>
                {this.props.myState.euipment_name.map((value, key) => {
                  return (
                    <li key={key}>
                      {value}
                      <span
                        onClick={() => {
                          this.props.removeEquipment(key);
                        }}
                      >
                        X
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {this.props.activeKey === "all_lead" && (
            <div className="filter_text">
              <Select
                options={this.props.myState.user_list}
                placeholder={language.creator_name_plh}
                onChange={(value) => {
                  this.props.singleSelect("creator_id", value);
                  this.props.setKeyWithValue("creator_details", value);
                }}
                name="creator_id"
                value={this.props.myState.creator_details}
              />
            </div>
          )}

          {this.props.activeKey === "all_lead" &&
            this.props.myState.tabkey !== "mylead" && (
              <div className="filter_text">
                <Select
                  options={this.props.myState.contact_list}
                  placeholder={language.assignee_name_plh}
                  onChange={(value) => {
                    this.props.setKeyWithValue("assignee_name", value.label);
                    this.props.setKeyWithValue("assignee_details", value);
                  }}
                  name="assignee_name"
                  value={this.props.myState.assignee_details}
                />
              </div>
            )}

          <div className="select_partts mt-0">
            <Form.Control
              as="select"
              className="select_boxqaw"
              name="lead_type"
              onChange={this.props.handleOnChange}
              value={this.props.myState.lead_type}
            >
              <option value="">{language.lead_type_plh}</option>
              <option value="1">{language.customer}</option>
              <option value="2">{language.dealer}</option>
              <option value="3">{language.financer}</option>
            </Form.Control>

            <Form.Control
              as="select"
              className="select_boxqaw"
              name="euipment_category"
              onChange={this.props.handleOnChange}
              value={this.props.myState.euipment_category}
            >
              <option value="">{language.equipment_type_plh}</option>
              <option value="1">{language.new}</option>
              <option value="0">{language.old}</option>
            </Form.Control>

            <Form.Control
              as="select"
              className="select_boxqaw"
              name="finance_type"
              onChange={this.props.handleOnChange}
              value={this.props.myState.finance_type}
            >
              <option value="">{language.finance_type_plh}</option>
              <option value="1">{language.re_finance}</option>
              <option value="2">{language.normal_finance}</option>
            </Form.Control>

            <div className="filter_text">
              <CreatableSelect
                isClearable
                onChange={(value) => {
                  this.handleSelectData(value);
                  this.props.setKeyWithValue("status", value.label);
                }}
                options={lead_header}
                placeholder={language.select_status_plh}
                name="status"
              />
            </div>

            {get_sub_header.length > 0 && (
              <Form.Control
                as="select"
                name="sub_status"
                onChange={this.props.handleOnChange}
                className="select_boxqaw"
              >
                <option value="">{language.select_sub_status}</option>
                {get_sub_header.map((header, key) => {
                  return (
                    <option value={header.sub_header_label} key={key}>
                      {header.sub_header_label}
                    </option>
                  );
                })}
              </Form.Control>
            )}

            <div className="starting_date">
              <ReactDatePicker
                selected={
                  this.props.myState.start_date !== "" &&
                  new Date(this.props.myState.start_date)
                }
                className="starting_datepicker"
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  this.props.setKeyWithValue(
                    "start_date",
                    moment(date).format("YYYY-MM-DD")
                  );
                }}
                placeholderText={language.start_date_plh}
                // maxDate={new Date()}
              />
            </div>

            <div className="starting_date">
              <ReactDatePicker
                selected={
                  this.props.myState.end_date !== "" &&
                  new Date(this.props.myState.end_date)
                }
                className="starting_datepicker"
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  this.props.setKeyWithValue(
                    "end_date",
                    moment(date).format("YYYY-MM-DD")
                  );
                }}
                placeholderText={language.end_date_plh}
                // maxDate={new Date()}
                // minDate={
                //   this.props.myState.start_date !== "" &&
                //   new Date(this.props.myState.start_date)
                // }
              />
            </div>

            {this.props.activeKey !== "my_assign_lead" && (
              <div className="filter_text">
                <Form.Check
                  type="checkbox"
                  name="expected_closing_date"
                  id="expected_closing_date"
                  onChange={() => {
                    this.props.setKeyWithValue(
                      "expected_closing_date",
                      !this.props.myState.expected_closing_date
                    );
                  }}
                  chacked={this.props.myState.expected_closing_date}
                  label={language.closing_date_plh}
                />
              </div>
            )}
          </div>
          <div className="filter_submit">
            <Button
              variant="primary"
              type="submit"
              className="filter_submit_button"
              onClick={this.props.onAdvanceFilterClick}
            >
              {language.apply_btn}
            </Button>
          </div>
        </div>
      </Col>
    );
  }
}
export default connect(mapStateToProps)(AdvanceFilter);
