import React, { Component } from "react";
import { Navbar, Nav, Dropdown, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import {
  logo_img,
  menu_logout,
  prof_icon_menu,
  dashboard_icon_menu,
  header_phone,
  mail_icon,
  header_phone_contact_us,
  header_expert,
  whatsapp_header,
} from "../../assets/images";
import "./header.css";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { getFMCToken } from "../../fmc/myFirebase";
import config from "../../config/apiHeader";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import LANGUAGE_DATA from "../../config/LanguageData";
import { GetLanguageDetails } from "../../app/action/LanguageActions";
import { connect } from "react-redux";
import { getAllCountryList } from "../../app/action/CountryAction";
import Geocode from "react-geocode";
import { GeocodeApiKey } from "../../utils/SecretKey";
import LocationPopup from "../../utils/LocationPopup";

Geocode.setApiKey(GeocodeApiKey());

const mapStateToProps = (state) => {
  let { language, country_code } = state;
  return { language, country_code };
};

const mapActionToProps = (dispatch) => {
  return {
    getLanguageContent: (body) => dispatch(GetLanguageDetails(body)),
    getCountryList: () => dispatch(getAllCountryList()),
  };
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiledata: "",
      img_url: null,
      country_error: false,
    };
  }

  componentDidMount = () => {
    if (!localStorage.getItem("country")) {
      this.getCurrentLocation();
    }

    let { loading, language } = this.props.language;

    if (!loading && !language) {
      this.props.getLanguageContent({});
    }

    let country_loading = this.props.country_code.loading;
    let country_code = this.props.country_code.country_code_list;

    if (!country_loading && !country_code) {
      this.props.getCountryList();
    }

    let basicProfile = JSON.parse(localStorage.getItem("basicProfile"));
    if (basicProfile) {
      let { img_url } = basicProfile;
      this.setState({ profiledata: basicProfile, img_url });
    }
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      let { hash_id } = userdata;

      axios
        .post("/get-user-fcm-token", { hash_id }, config)
        .then(async (res) => {
          if (res.data.status === "1") {
            let { api_token_web, fcm_token_web } = res.data.data;
            await getFMCToken()
              .then((token) => {
                if (fcm_token_web !== token) {
                  // save fmc token
                  const formData = {
                    hash_id,
                    fcm_token_web: token,
                  };

                  axios
                    .post("/fcm-token-web", formData, config)
                    .then((res) => {
                      if (res.data.status === "1") {
                        localStorage.setItem("fmc-token", res.data.data);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              })
              .catch((err) => {
                console.error(err);
              });
            if (localStorage.getItem("api-token") !== api_token_web) {
              this.removeLocalStorage();
            }
          } else if (res.data.status === "0") {
            this.removeLocalStorage();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  compIsType = (t, s) => {
    for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;
    return false;
  };

  getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (place) => {
            place = place.results[0];
            if (place.address_components !== undefined) {
              let country;
              place.address_components.forEach((addrComp) => {
                let typ = addrComp.types;
                if (this.compIsType(typ, "country"))
                  country = addrComp.short_name;
              });
              let selected_language = LANGUAGE_DATA[country];
              if (selected_language) {
                localStorage.setItem("country", country);
                localStorage.setItem("country", country);
                localStorage.setItem("language", selected_language.language);
                window.location.reload();
              } else {
                this.setState({ country_error: true });
              }
            }
          },
          (error) => {
            console.error(error);
            message.error(error.message);
            this.setState({ country_error: true });
          }
        );
      },
      (error) => {
        console.error(error);
        this.setState({ country_error: true });
      }
    );
  };

  removeLocalStorage = () => {
    let country = localStorage.getItem("country");
    let language = localStorage.getItem("language");
    // LOGOUT

    localStorage.clear();
    window.location.pathname = "/login";

    localStorage.setItem("country", country || "");
    localStorage.setItem("language", language || "");
  };

  logout = () => {
    const hideMessage = message.loading("Please wait ...", 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    const formData = {
      hash_id: userdata.hash_id,
      platform: "web",
    };

    axios
      .post("/logout", formData, config)
      .then((res) => {
        hideMessage();
        if (res.data.status === "1") {
          message.success(res.data.message);
        }
        this.removeLocalStorage();
      })
      .catch((err) => {
        hideMessage();
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  showLoginMsg = () => {
    if (!localStorage.getItem("api-token"))
      message.info("Please login to access this page");
  };

  render() {
    let selected_language =
      LANGUAGE_DATA[localStorage.getItem("country") || "IN"];

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.header_menu;
    } else {
      language = {};
    }

    return (
      <header className="header_parttsss">
        <LocationPopup show={this.state.country_error} />
        <Navbar expand="lg" className="new_customnav">
          <Navbar.Brand href="/">
            <img src={logo_img} alt="HMC" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="ml-auto mr-3"
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav as="ul" className="custom_ul ml-auto">
              <Nav.Item as="li">
                <NavLink to="/" exact>
                  {language.home}
                </NavLink>
              </Nav.Item>

              <Nav.Item as="li">
                <NavLink to="/about-us" exact>
                  {language.about_us}
                </NavLink>
              </Nav.Item>

              <Nav.Item as="li">
                <NavLink
                  to={`${
                    localStorage.getItem("api-token")
                      ? "/all-equipment"
                      : "/product"
                  }`}
                  exact
                >
                  {language.equipments}
                </NavLink>
              </Nav.Item>

              <Nav.Item as="li">
                <NavLink
                  to="/service-provider"
                  exact
                  // onClick={this.showLoginMsg}
                >
                  {language.services}
                </NavLink>
              </Nav.Item>

              <Nav.Item as="li">
                <NavLink to="/projects" exact>
                  {language.projects}
                </NavLink>
              </Nav.Item>
              {/* <Nav.Item as="li">
                <NavLink to="/finance" exact>
                  {language.finance}
                </NavLink>
              </Nav.Item> */}

              <NavDropdown
                title={language.support}
                id="basic-nav-dropdown"
                className="nav_drop"
              >
                <div className="phone_partts">
                  <div className="phone_image">
                    <img alt="HMC" src={header_phone} />
                  </div>
                  <div className="phone_text">
                    {language.support_sub_menu_phone}{" "}
                    <span>
                      <a href="tel:+91 9065340638">+91 9065340638</a>
                    </span>
                  </div>
                </div>

                <div className="phone_partts align-items-start whatsapp">
                  <div className="phone_image">
                    <img alt="HMC" src={whatsapp_header} />
                  </div>
                  <div className="phone_text">
                    {language.support_sub_menu_whatsapp}
                    <span>
                      <a
                        href="https://wa.me/917439195289"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +91 7439195289
                      </a>
                      <a
                        href="https://wa.me/918910162952"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +91 8910162952
                      </a>
                    </span>
                  </div>
                </div>

                <div className="phone_partts">
                  <div className="phone_image">
                    <img alt="HMC" src={mail_icon} />
                  </div>
                  <div className="phone_text">
                    {language.support_sub_menu_mail}{" "}
                    <span>
                      <a href="mailto:support@heavymachinerycare.com">
                        support@heavymachinerycare.com
                      </a>
                    </span>
                  </div>
                </div>

                <div className="phone_partts">
                  <div className="phone_image">
                    <img alt="HMC" src={header_phone_contact_us} />
                  </div>
                  <div className="phone_text">
                    <span>
                      <Link to="/contact-us">
                        {language.support_sub_menu_contact_us}
                      </Link>
                    </span>
                  </div>
                </div>

                <div className="phone_partts">
                  <div className="phone_image">
                    <img alt="HMC" src={header_expert} />
                  </div>
                  <div className="phone_text">
                    <span>
                      <Link to="/expert-connect">
                        {language.support_sub_menu_expert_advice}
                      </Link>
                    </span>
                  </div>
                </div>
              </NavDropdown>
            </Nav>

            <div className="header_loginand_email d-flex align-items-center">
              <div className="lang ">
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    {
                      <>
                        <img
                          width={20}
                          src={selected_language.icon}
                          alt={selected_language.label}
                        />{" "}
                        {selected_language.label}
                      </>
                    }
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.values(LANGUAGE_DATA).map((language, key) => {
                      return (
                        <Dropdown.Item
                          key={key}
                          onClick={() => {
                            localStorage.setItem("country", language.value);
                            localStorage.setItem("language", language.language);
                            window.location.reload();
                          }}
                        >
                          <img
                            width={20}
                            src={language.icon}
                            alt={language.label}
                          />{" "}
                          {language.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <Link className="expert_advice" to="/expert-connect">
                {language.expert_advice}
              </Link>
            </div>
          </Navbar.Collapse>

          <div className="user_login">
            {localStorage.getItem("api-token") ? (
              <div className="after_signin">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src={
                        this.state.img_url
                          ? MEDIA_BASE_URL + this.state.img_url
                          : prof_icon_menu
                      }
                      alt="HMC"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <NavLink to="/dashboard" className="dropdown-item">
                      <span className="menu_dash">
                        <img src={dashboard_icon_menu} alt="HMC" />
                      </span>
                      {language.dashboard}
                    </NavLink>

                    <NavLink to="/profile" className="dropdown-item">
                      <span className="menu_prof">
                        <img src={prof_icon_menu} alt="HMC" />
                      </span>
                      {language.my_profiles}
                    </NavLink>
                    <Dropdown.Item onClick={this.logout}>
                      <span className="menu_logout">
                        <img src={menu_logout} alt="HMC" />
                      </span>
                      {language.logout}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <Link to="/login" className="login_butttton">
                {language.sign_in}
              </Link>
            )}
          </div>
        </Navbar>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapActionToProps)(Header);
