import React, { Component } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import { Sticky, StickyContainer } from "react-sticky";
import { equipment_search, list_view, map_view } from "../../assets/images";
import axios from "../../config/axios";
import message from "../../utils/messages";
import config from "../../config/apiHeader";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { DefaultJob } from "../../images";
import GoogleMapReact from "google-map-react";
import LocationPin from "../../utils/LocationPin";
import AutoCompleteLocation from "../../utils/AutoCompleteLocation";
import CustomSlider from "../../utils/CustomSlider";
import CustomPopup from "../../utils/CustomPopup";
import _ from "lodash";
import "./jobseeker.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class JobSeeker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      isLoading: false,
      isGridView: true,

      total: 0,
      total_map_count: 0,
      currentPage: 1,
      per_page: 10,
      last_page: 0,
      map_last_page: 0,

      profiles: [],
      allExpertise: [],
      allEducations: [],
      showExpertiseModal: false,

      //   ADVANCE FILTER
      location: "",
      name: "",
      lat: "",
      long: "",
      km: "",
      expertise: "",
      education: "",

      filter: {
        name: "",
        isApplyFilter: false,
        lat: "",
        long: "",
        km: "",
        expertise: [],
        education: "",
      },
      exp_data: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    this.getAllJobProfileList();
    this.getAllExpertise();
    this.getEducation();
  };

  getAllJobProfileList = () => {
    let { hash_id, currentPage, expertise, lat, long, education } = this.state;

    let formData = {
      hash_id,
      page: currentPage,
      expertise,
      lat,
      long,
      education,
    };

    this.setState({ isLoading: true });

    axios
      .post("/job-profile-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { profiles, total, total_map_count, map_last_page, last_page } =
            res.data.data;

          currentPage++;
          profiles = this.state.profiles.concat(profiles);

          this.setState({
            profiles,
            total,
            currentPage,
            total_map_count,
            map_last_page,
            last_page,
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getAllExpertise = () => {
    axios
      .get("/category")
      .then((res) => {
        if (res.data.status === "1") {
          let allExpertise = res.data.data.category;
          this.setState({
            allExpertise,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getEducation = () => {
    axios
      .get("/all-educations")
      .then((res) => {
        if (res.data.status === "1") {
          let { educations } = res.data.data;
          this.setState({
            allEducations: educations,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleOnChange = (e) => {
    let { name, value } = e.target;
    let { filter } = this.state;

    filter[name] = value;
    filter.isApplyFilter = false;

    this.setState({ filter });
  };

  setViewType = () => {
    this.setState({ isGridView: !this.state.isGridView });
  };

  handleGetLocation = (value) => {
    if (value) {
      let { label, lat, long } = value;

      let { filter } = this.state;

      filter.lat = lat;
      filter.long = long;
      filter.isApplyFilter = false;

      this.setState({
        location: label,
        filter,
      });
    }
  };

  handleExperties = (e, expertiseValue = "") => {
    let { value } = e.target;

    let { exp_data } = this.state;

    if (exp_data[value]) {
      delete exp_data[value];
    } else {
      exp_data[value] = value;
    }
    this.setState({ exp_data });
  };

  applyExperties = () => {
    let { exp_data, filter } = this.state;

    filter.expertise = Object.values(exp_data);
    filter.isApplyFilter = false;

    this.setState({
      filter,
      showExpertiseModal: false,
    });
  };

  copyArrayToObj = () => {
    let { filter } = this.state;

    let exp_data = {};

    filter.expertise.forEach((value) => {
      exp_data[value] = value;
    });
    this.setState({ exp_data });
  };

  clearFilter = () => {
    this.setState(
      {
        //   ADVANCE FILTER
        location: "",
        name: "",
        lat: "",
        long: "",
        km: "",
        expertise: "",

        filter: {
          name: "",
          isApplyFilter: false,
          lat: "",
          long: "",
          km: "",
          expertise: [],
        },
        exp_data: {},

        currentPage: 1,
        profiles: [],
      },
      () => {
        this.getAllJobProfileList();
      }
    );
  };

  applyFilter = () => {
    let { filter } = this.state;

    if (filter.isApplyFilter) {
      // CLEAR FILTER
      this.clearFilter();
      return;
    }
    const entries = Object.entries(filter);
    for (let [key, value] of entries) {
      if (key === "expertise") {
        this.setState({ [key]: value.toString() });
      } else {
        this.setState({ [key]: value });
      }
    }

    filter.isApplyFilter = true;

    this.setState({ filter, currentPage: 1, profiles: [] }, () => {
      this.getAllJobProfileList();
    });
  };

  render() {
    let {
      filter,
      profiles,
      isGridView,
      allExpertise,
      allEducations,
      exp_data,
      total,
      currentPage,
      per_page,
      total_map_count,
      map_last_page,
      last_page,
    } = this.state;

    if (!isGridView) {
      total = parseInt(total_map_count);
      last_page = parseInt(map_last_page);
    }
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.job_seeker;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <CustomPopup
          show={this.state.showExpertiseModal}
          title="Choose Expertise"
          onClose={() => {
            this.setState({
              showExpertiseModal: false,
            });
            this.copyArrayToObj();
          }}
        >
          <Form.Label>Experties you looking for </Form.Label>
          {allExpertise.map((expertise, key) => {
            return (
              <div key={key} className="mb-2">
                {expertise.sub_category.length > 0 ? (
                  <Form.Group>
                    <Form.Label className="sub_category_label">
                      {expertise.category_name}
                    </Form.Label>
                    <div className="check_container">
                      {expertise.sub_category.map((sub_cat, ind) => {
                        return (
                          <Form.Check
                            type="checkbox"
                            label={sub_cat.category_name}
                            onChange={this.handleExperties}
                            value={sub_cat.id}
                            name="skill"
                            className="sub_category_check"
                            key={ind}
                            checked={exp_data[sub_cat.id] ? true : false}
                          />
                        );
                      })}
                    </div>
                    <hr />
                  </Form.Group>
                ) : (
                  <>
                    <Form.Check
                      type="checkbox"
                      label={expertise.category_name}
                      onChange={(e) => {
                        this.handleExperties(e, expertise.category_name);
                      }}
                      value={expertise.id}
                      name="skill"
                      checked={exp_data[expertise.id] ? true : false}
                    />
                  </>
                )}
              </div>
            );
          })}
          <Button
            disabled={Object.entries(exp_data).length === 0}
            onClick={this.applyExperties}
          >
            Apply
          </Button>
        </CustomPopup>

        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="buy_rent_part">
                    <StickyContainer>
                      <Row>
                        <Col lg="9" md="8" sm="12">
                          <div className="dashboard_right_panel">
                            <h2>{language.job_seeker_h}</h2>

                            <div className="rent_result_top">
                              {/* <h2>Showing Results for</h2> */}

                              <div className="buy_search_box">
                                <div className="search_box_rent">
                                  <Form.Control
                                    type="text"
                                    className="search_textbox"
                                    placeholder="Search.."
                                    onChange={this.handleOnChange}
                                    name="name"
                                    value={filter.name}
                                  />
                                  <span
                                    className="eqp_search_icon"
                                    // onClick={() => {
                                    //   let { name } = filter;
                                    //   this.props.history.push(
                                    //     `/parts?name=${name}`
                                    //   );
                                    // }}
                                  >
                                    <img alt="HMC" src={equipment_search} />
                                  </span>
                                </div>

                                <div className="buy_rent_viewbox">
                                  <span
                                    onClick={this.setViewType}
                                    className={
                                      this.state.isGridView
                                        ? " "
                                        : "active_color"
                                    }
                                  >
                                    <img alt="List View" src={list_view} />
                                  </span>
                                  <span
                                    onClick={this.setViewType}
                                    className={
                                      this.state.isGridView
                                        ? "active_color"
                                        : " "
                                    }
                                  >
                                    <img alt="Map View" src={map_view} />
                                  </span>
                                </div>
                              </div>
                            </div>

                            {isGridView ? (
                              <div className="js_wrap">
                                {profiles.map((job, key) => {
                                  return (
                                    <Card
                                      className="js_item hmc_card mt-4"
                                      key={key}
                                    >
                                      <Card.Body className="row mx-0">
                                        <div className="js_img">
                                          <LazyLoadImage
                                            className="img-fluid"
                                            src={
                                              job.img_url
                                                ? MEDIA_BASE_URL + job.img_url
                                                : DefaultJob
                                            }
                                            alt={job.business_name}
                                          />
                                        </div>
                                        <div className="js_info col pr-0">
                                          <div className="js_review float-right">
                                            <i className="fa fa-star" />{" "}
                                            <span>{job.service_rating}</span>
                                          </div>
                                          <h2 className="js_title">
                                            {job.business_name}
                                          </h2>

                                          <div className="js_address">
                                            <i className="fa fa-map-marker" />{" "}
                                            {job.city}, {job.state}
                                          </div>

                                          <div className="row mt-3 align-items-end">
                                            <Col>
                                              <div className="js_expertise">
                                                <h5>
                                                  {language.expertise_label}
                                                </h5>
                                                <div
                                                  className={
                                                    this.state.active &&
                                                    "active"
                                                  }
                                                >
                                                  <ul className="list-inline">
                                                    {job.job_category &&
                                                      job.job_category
                                                        .split(",")
                                                        .map((s, key) => {
                                                          return (
                                                            <li
                                                              className="list-inline-item"
                                                              key={key}
                                                            >
                                                              {s}
                                                            </li>
                                                          );
                                                        })}
                                                  </ul>
                                                </div>
                                                {/* <div>
                                                  <span
                                                    onClick={() =>
                                                      this.setState({
                                                        active:
                                                          !this.state.active,
                                                      })
                                                    }
                                                  >
                                                    +More
                                                  </span>
                                                </div> */}
                                              </div>
                                            </Col>
                                            {/* <Col
                                              md={2}
                                              sm={3}
                                              className="text-right"
                                            >
                                              <a
                                                className="btn btn_primary btn-sm"
                                                href="#"
                                              >
                                                Chat
                                              </a>
                                            </Col> */}
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  );
                                })}
                              </div>
                            ) : (
                              <div style={{ height: "100vh", width: "100%" }}>
                                <GoogleMapReact
                                  defaultCenter={{ lat: 12.97, lng: 77.59 }}
                                  defaultZoom={5}
                                >
                                  {profiles.map((parts, key) => {
                                    return (
                                      <LocationPin
                                        key={key}
                                        lat={parts.lat}
                                        lng={parts.long}
                                        text={parts.city}
                                      />
                                    );
                                  })}
                                </GoogleMapReact>
                              </div>
                            )}

                            <div className="showing_result">
                              <p>
                                {language.showing_results}{" "}
                                {Math.min(total, (currentPage - 1) * per_page)}{" "}
                                {language.out_of} {total}
                              </p>
                              <Button
                                className="submit_button commn_button"
                                type="submit"
                                disabled={currentPage - 1 >= last_page}
                                onClick={this.getAllJobProfileList}
                              >
                                {language.view_more_btn}
                              </Button>
                            </div>
                          </div>
                        </Col>

                        <Col lg="3" md="4" sm="12">
                          <Sticky>
                            {({ style }) => (
                              <div style={style} className="filter_partts">
                                <div className="filter_heading">
                                  <span>Filter</span>
                                </div>
                                <div className="eqp_added">
                                  <Form.Label>Location</Form.Label>

                                  <AutoCompleteLocation
                                    handleGetLocation={this.handleGetLocation}
                                    value={this.state.location}
                                  />

                                  <Form.Label className="mt-2">
                                    {language.select_radius_label}
                                  </Form.Label>
                                  <div className="hmr_value">
                                    {filter.km || 0}km-6000KM
                                  </div>

                                  <CustomSlider
                                    max={6000}
                                    value={filter.km}
                                    handleSlider={(km) => {
                                      filter.km = km;
                                      filter.isApplyFilter = false;
                                      this.setState({ filter });
                                    }}
                                  />

                                  <Form.Label
                                    className="mt-2 exp"
                                    onClick={() => {
                                      this.setState({
                                        showExpertiseModal: true,
                                      });
                                    }}
                                  >
                                    {language.expertise_label}
                                  </Form.Label>
                                  <br />

                                  <Form.Label className="mt-2">
                                    Education
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="education"
                                    onChange={this.handleOnChange}
                                  >
                                    <option value="">
                                      {language.select_education_plh}
                                    </option>

                                    {allEducations.map((items, key) => {
                                      return (
                                        <option value={items.id} key={key}>
                                          {items.title}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>

                                  <div className="range_rent_slider mt-3">
                                    <div className="submit_button_rent mt-3">
                                      <Button
                                        onClick={this.applyFilter}
                                        type="submit"
                                      >
                                        {filter.isApplyFilter
                                          ? language.clear_btn
                                          : language.apply_btn}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Sticky>
                        </Col>
                      </Row>
                    </StickyContainer>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(JobSeeker);
