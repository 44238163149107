import React, { Component } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import {
  cross_preview,
  Eqp_model_img,
  modal_attach,
  Upload_img_icon_img,
  Up_doc_icon_img,
  word_preview,
  pdf_preview,
  image_preview,
} from "../../../assets/images";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import _ from "lodash";
import { getFileExtension, MEDIA_BASE_URL } from "../../../utils/Helper";
import message from "../../../utils/messages";
import CustomPopup from "../../../utils/CustomPopup";
import config from "../../../config/apiHeader";
import Select from "react-select";
import AutoCompleteLocation from "../../../utils/AutoCompleteLocation";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import axios from "../../../config/axios";
import CustomMultiSelect from "../../../utils/CustomMultiSelect";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class EditEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      machine_activity_hash_id: "",

      allAreas: [],
      allStates: [],
      documet_types: [],

      machine_profile_type: "",
      isRent: false,
      machine_image: [],
      lat: "",
      long: "",
      location: "",
      purchase_date: "",
      hmr: "",
      general_info: {},
      availability: [],
      utilization: [],
      image: [],
      selcted_states: [],
      selcted_areas: [],
      save_for_later: "0",

      // DOCUMENTS
      show: false,
      doc_select: {},
      doc_type: "",
      total_doc_type: [],
      temp_total_doc_type: [],
      doc_name: "",
      total_doc_name: [],
      temp_total_doc_name: [],
      total_doc: [],
      temp_total_doc: [],

      error: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    if (this.props.match.params) {
      let { machine_activity_hash_id } = this.props.match.params;
      await this.setState({ machine_activity_hash_id });
    }

    this.getMachineDetails();
    this.getDocumetType();
    this.getAllState();
    this.getAllAreas();
  };

  getMachineDetails = () => {
    let { machine_activity_hash_id, hash_id } = this.state;
    const formData = {
      machine_activity_hash_id,
      profile_hash_id: hash_id,
    };
    this.setState({ isLoading: true });
    axios
      .post("/machine-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { general_info, availability, utilization, image } =
            res.data.data;
          if (general_info) {
            let {
              machine_profile_type,
              location,
              lat,
              long,
              purchase_date,
              hmr,
            } = general_info;

            let selcted_states = availability.map((state) => {
              return state.id;
            });

            let selcted_areas = utilization.map((area) => {
              return area.id;
            });

            availability = availability.map((state) => {
              return {
                value: state.id,
                label: state.name,
              };
            });

            utilization = utilization.map((state) => {
              return {
                value: state.id,
                label: state.name,
              };
            });

            this.setState({
              machine_profile_type,
              location,
              lat,
              long,
              purchase_date,
              hmr,
              general_info,
              availability,
              utilization,
              selcted_states,
              selcted_areas,

              isRent: machine_profile_type.includes("4"),
              image,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getDocumetType = () => {
    axios
      .post("/document-type-section-wise", { document_master_id: "7" }, config)
      .then((res) => {
        if (res.data.status === "1") {
          let documet_types = res.data.data.doc_type.map((doc) => {
            return {
              value: doc.id,
              label: doc.name,
            };
          });
          this.setState({ documet_types });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        // message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getAllState = () => {
    axios
      .get("/area", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allStates = res.data.data.area.map((state) => {
            return {
              value: state.id,
              label: state.name,
            };
          });
          this.setState({ allStates });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        // message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getAllAreas = () => {
    axios
      .get("/utilization-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allAreas = res.data.data.utilization_type.map((area) => {
            return {
              value: area.id,
              label: area.name,
            };
          });
          this.setState({ allAreas });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        // message.error("Something went wrong!!");
        console.error(err);
      });
  };

  handleChange = (e) => {
    e.persist();

    let { error } = this.state;
    let { name, value } = e.target;

    if (name === "machine_profile_type") {
      let { machine_profile_type } = this.state;
      if (machine_profile_type.includes(value)) {
        machine_profile_type = machine_profile_type.replace(value, "");
      } else {
        machine_profile_type += value;
      }
      if (!machine_profile_type) {
        error[name] =
          "Please select either rent or resale or both before submitting your equipment details";
      } else {
        error[name] = "";
      }
      this.setState({ machine_profile_type, error });

      // let allType = this.state.machine_profile_type;
      // if (
      //   _.find(allType, (val) => {
      //     return val === value;
      //   })
      // ) {
      //   allType = _.filter(allType, (val) => {
      //     return val !== value;
      //   });
      // } else {
      //   allType = _.concat(allType, value);
      // }

      // this.setState({ machine_profile_type: allType, error });
      return;
    }

    this.setState({
      [name]: value,
    });
  };

  handleImages = (e) => {
    e.persist();

    let { machine_image } = this.state;
    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg"];
    if (file !== undefined) {
      let extension = getFileExtension(file);
      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        machine_image.push(file);
      } else {
        message.error("The file format is not supported");
      }
    }
    this.setState({ machine_image });
  };

  deleteImage = (pos) => {
    let { machine_image } = this.state;
    machine_image.splice(pos, 1);
    this.setState({ machine_image });
  };

  handleDocument = (e) => {
    e.persist();

    let {
      temp_total_doc,
      temp_total_doc_type,
      temp_total_doc_name,
      doc_type,
      doc_name,
    } = this.state;
    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg", "doc", "docx", "pdf"];
    if (file !== undefined) {
      let extension = getFileExtension(file);

      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        let preview;
        if (extension === "docx" || extension === "doc") {
          preview = word_preview;
        } else if (extension === "pdf") {
          preview = pdf_preview;
        } else {
          preview = image_preview;
        }
        temp_total_doc.push(file);
        temp_total_doc_type.push(doc_type);
        doc_name += `.${extension}`;
        temp_total_doc_name.push({ doc_name, preview });

        this.setState({
          temp_total_doc,
          temp_total_doc_type,
          temp_total_doc_name,
          doc_select: {},
          doc_type: "",
          doc_name: "",
        });
      } else {
        message.error("Please Select Valid Document");
      }
    }
  };

  deleteTempDoc = (pos) => {
    let { temp_total_doc, temp_total_doc_name, temp_total_doc_type } =
      this.state;
    temp_total_doc.splice(pos, 1);
    temp_total_doc_name.splice(pos, 1);
    temp_total_doc_type.splice(pos, 1);
    this.setState({ temp_total_doc, temp_total_doc_name, temp_total_doc_type });
  };

  deleteDoc = (pos) => {
    let { total_doc, total_doc_name, total_doc_type } = this.state;
    total_doc.splice(pos, 1);
    total_doc_name.splice(pos, 1);
    total_doc_type.splice(pos, 1);

    this.setState({
      temp_total_doc: [...total_doc],
      temp_total_doc_name: [...total_doc_name],
      temp_total_doc_type: [...total_doc_type],
      total_doc,
      total_doc_name,
      total_doc_type,
    });
  };

  submitDocument = () => {
    let { temp_total_doc, temp_total_doc_name, temp_total_doc_type } =
      this.state;
    this.setState({
      total_doc: [...temp_total_doc],
      total_doc_name: [...temp_total_doc_name],
      total_doc_type: [...temp_total_doc_type],
      show: false,
    });
  };

  onClose = (show) => {
    let { total_doc_type, total_doc_name, total_doc } = this.state;
    this.setState({
      show,
      doc_select: {},
      doc_type: "",
      temp_total_doc_type: total_doc_type,
      doc_name: "",
      temp_total_doc_name: total_doc_name,
      temp_total_doc: total_doc,
    });
  };

  handleGetLocation = (value) => {
    if (value) {
      let { error } = this.state;
      let { label, lat, long } = value;
      error.location = "";
      this.setState({
        location: label,
        error,
        long,
        lat,
      });
    }
  };

  handleDatePicker = (date, dateString) => {
    this.setState({ purchase_date: dateString });
  };

  stateSelected = (value) => {
    let selcted_states = value.map((state) => {
      return state.value;
    });
    this.setState({ selcted_states, availability: value });
  };

  areaSelected = (value) => {
    let selcted_areas = value.map((area) => {
      return area.value;
    });
    this.setState({ selcted_areas, utilization: value });
  };

  requiredField = () => {
    const arr = ["machine_profile_type", "location"];
    let { error } = this.state;

    arr.forEach((key) => {
      if (!this.state[key].toString()) {
        error[key] = "This filed is required";
      } else {
        error[key] = "";
      }
      this.setState({ error });
    });
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleEditMachine = () => {
    let {
      save_for_later,
      hash_id,
      machine_activity_hash_id,
      machine_profile_type,
      location,
      lat,
      long,
      purchase_date,
      general_info,
      hmr,
      selcted_states,
      selcted_areas,
      machine_image,
      total_doc,
      error,
    } = this.state;

    this.requiredField();
    if (this.isValidForm(error)) {
      selcted_states = selcted_states.toString();
      selcted_areas = selcted_areas.toString();

      let formData = {
        save_for_later,
        profile_hash_id: hash_id,
        machine_profile_type,
        purchase_date,
        hmr,
        location,
        lat,
        long,
        availability: selcted_states,
        utilization: selcted_areas,
        machine_hash_id: general_info.machine_hash_id,
        brand_hash_id: general_info.brand_hash_id,
        industry_hash_id: general_info.industry_hash_id,
        machine_type_hash_id: general_info.machine_type_hash_id,
        machine_activity_hash_id,

        medium: "web",
        section_name: "edit_equipment",
      };

      this.setState({ isLoading: true });
      axios
        .post("/machine-edit", formData, config)
        .then((res) => {
          if (res.data.status === "1") {
            message.success(res.data.message);
            if (machine_image.length > 0) {
              this.uploadImages(machine_activity_hash_id);
            } else if (total_doc.length > 0) {
              this.uploadDocs(machine_activity_hash_id);
            } else {
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ isLoading: false });
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          message.error("Something went wrong!!");
          console.error(err);
        });
    }
  };

  uploadImages = (id) => {
    let { machine_image, total_doc } = this.state;

    let formData = new FormData();
    formData.append("machine_activity_hash_id", id);
    formData.append("medium", "web");
    formData.append("section_name", "edit_equip_image");

    machine_image.forEach((file, index) => {
      formData.append(`machine_image[${index}]`, file);
    });

    axios
      .post("/machine-image-v1", formData, config)
      .then((res) => {
        if (total_doc.length > 0) {
          this.uploadDocs(id);
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  uploadDocs = (id) => {
    let { total_doc_type, total_doc_name, total_doc } = this.state;

    let formData = new FormData();
    formData.append("machine_activity_hash_id", id);
    formData.append("medium", "web");
    formData.append("section_name", "edit_equip_doc");

    total_doc.forEach((file, index) => {
      formData.append(`machine_doc[${index}]`, file);
    });

    total_doc_type.forEach((file, index) => {
      formData.append(`type[${index}]`, file);
    });

    total_doc_name.forEach((file, index) => {
      formData.append(`name[${index}]`, file);
    });

    axios
      .post("/machine-document-v1", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  render() {
    let {
      error,
      image,
      temp_total_doc,
      temp_total_doc_name,
      total_doc,
      total_doc_name,
      general_info,
      machine_profile_type,
      purchase_date,
      availability,
      utilization,
    } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.edit_equipment;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col className="ds_right" lg="8" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <div className="ds_header">
                      <h2>{language.edit_equipment_h}</h2>
                      <h4>{language.edit_equipment_short_desc}</h4>
                      <p>{language.edit_equipment_long_desc}</p>
                    </div>

                    <div className="eqp_ind">
                      <div className="eqp_modelpart">
                        <div className="eqpmodel_left">
                          <img
                            src={
                              general_info.machine_cover_image || Eqp_model_img
                            }
                            alt="HMC"
                          />
                        </div>
                        <div className="eqpmodel_right">
                          <p>{language.equipment_model_name_label}</p>
                          <h3>{general_info.model_name}</h3>
                        </div>
                      </div>

                      <div className="ind_select">
                        <h3>{language.select_industry_label}</h3>
                        <p>{language.rent_resale_option_desc}</p>

                        <Form.Group
                          controlId="machine_profile_type"
                          className="check_container"
                        >
                          <div className="hmc_check check-inline">
                            <label>
                              <input
                                type="checkbox"
                                onChange={this.handleChange}
                                value="2"
                                checked={machine_profile_type.includes("2")}
                                name="machine_profile_type"
                              />
                              <span>{language.resale_option}</span>
                            </label>
                          </div>
                          <div className="hmc_check check-inline">
                            <label>
                              <input
                                type="checkbox"
                                onChange={this.handleChange}
                                value="4"
                                className="sub_category_check"
                                name="machine_profile_type"
                                onClick={() => {
                                  this.setState({
                                    isRent: !this.state.isRent,
                                  });
                                }}
                                checked={machine_profile_type.includes("4")}
                              />
                              <span>{language.rent_option}</span>
                            </label>
                          </div>
                        </Form.Group>
                        <p className="invalid_input">
                          {error.machine_profile_type &&
                            language.rent_resale_option_errormsg}
                        </p>

                        <div className="add_eqp_partts_img">
                          <h3>{language.edit_equipment_images_label}</h3>
                          <p className="my-2">
                            {language.edit_equipment_images_desc}
                          </p>
                          {/* OLD IMAGES */}
                          <div className="add_images_partttts">
                            {image &&
                              image.map((preview, index) => {
                                return (
                                  <div
                                    className="after_upload_image"
                                    key={index}
                                  >
                                    <img
                                      // src={URL.createObjectURL(preview)}
                                      src={MEDIA_BASE_URL + preview}
                                      alt="HMC"
                                    />
                                    <span
                                      className="close_preview"
                                      onClick={() => {
                                        this.deleteImage(index);
                                      }}
                                    >
                                      <img src={cross_preview} alt="HMC" />
                                    </span>
                                  </div>
                                );
                              })}
                            {image.length < 5 && (
                              <div className="uploadwrapper">
                                <button className="uploadwrapperbtn">
                                  <img src={Upload_img_icon_img} alt="HMC" />
                                </button>
                                <Form.Control
                                  type="file"
                                  className="file_upload_boxxx"
                                  accept="image/png, image/jpg, image/jpeg"
                                  name="images"
                                  onChange={this.handleImages}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="add_eqp_partts_img">
                          <h3>{language.upload_document_label}</h3>
                          <p className="my-2">
                            {language.upload_document_desc}
                          </p>
                          <div className="add_images_partttts">
                            {total_doc &&
                              total_doc.map((doc, index) => {
                                let { preview, doc_name } =
                                  total_doc_name[index];
                                return (
                                  <div
                                    className="after_upload_image upload_preview"
                                    key={index}
                                  >
                                    <img src={preview} alt="HMC" />
                                    <span
                                      className="close_preview"
                                      onClick={() => {
                                        this.deleteDoc(index);
                                      }}
                                    >
                                      <img src={cross_preview} alt="HMC" />
                                    </span>
                                    <span className="preview_name">
                                      {doc_name}
                                    </span>
                                  </div>
                                );
                              })}

                            <div className="uploadwrapper">
                              <button
                                className="uploadwrapperbtn"
                                type="button"
                                onClick={() => {
                                  this.setState({ show: true });
                                }}
                              >
                                <img src={Up_doc_icon_img} alt="HMC" />
                              </button>

                              <CustomPopup
                                title={language.upload_document_h}
                                show={this.state.show}
                                onClose={this.onClose}
                                isCenter={true}
                              >
                                <Form>
                                  <div className="main_doc">
                                    <Form.Group className="doc_field">
                                      <Form.Label>
                                        {language.document_type_label}
                                      </Form.Label>
                                      <Select
                                        options={this.state.documet_types}
                                        onChange={(e) => {
                                          this.setState({
                                            doc_select: e,
                                            doc_type: e.value,
                                          });
                                        }}
                                        value={this.state.doc_select}
                                        placeholder="Select Document Type"
                                      />
                                    </Form.Group>
                                    <div className="doc_attach">
                                      <img src={modal_attach} alt="HMC" />
                                      <Form.Control
                                        disabled={
                                          this.state.doc_type === "" ||
                                          this.state.doc_name === ""
                                        }
                                        type="file"
                                        className="custom-file-input"
                                        accept="image/png, image/jpg, image/jpeg, .doc, .docx, .pdf"
                                        onChange={this.handleDocument}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <Form.Group>
                                    <Form.Label>
                                      {language.document_name_label}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={this.state.doc_name}
                                      name="doc_name"
                                      placeholder={
                                        language.enter_document_name_plh
                                      }
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </Form.Group>
                                  <div className="add_images_partttts">
                                    {temp_total_doc &&
                                      temp_total_doc.map((doc, index) => {
                                        let { preview, doc_name } =
                                          temp_total_doc_name[index];
                                        return (
                                          <div
                                            className="after_upload_image upload_preview"
                                            key={index}
                                          >
                                            <img src={preview} alt="HMC" />
                                            <span
                                              className="close_preview"
                                              onClick={() => {
                                                this.deleteTempDoc(index);
                                              }}
                                            >
                                              <img
                                                src={cross_preview}
                                                alt="HMC"
                                              />
                                            </span>
                                            <span className="preview_name">
                                              {doc_name}
                                            </span>
                                          </div>
                                        );
                                      })}
                                  </div>
                                  <Button
                                    type="button"
                                    className="submit_button commn_button"
                                    onClick={this.submitDocument}
                                    disabled={temp_total_doc.length === 0}
                                  >
                                    {language.submit_btn}
                                  </Button>
                                </Form>
                              </CustomPopup>
                            </div>
                          </div>
                        </div>

                        <Form.Group>
                          <label>
                            {language.current_location_of_the_equipment_label}
                          </label>
                          <div className="ser_loc">
                            <AutoCompleteLocation
                              handleGetLocation={this.handleGetLocation}
                              value={this.state.location}
                            />
                            <p className="invalid_input">{error.location}</p>
                          </div>
                        </Form.Group>

                        <Form.Group className="set_details">
                          <h3>{language.set_details_for_rent_resale_both}</h3>
                          <div className="purchase_year">
                            {this.state.isRent && (
                              <>
                                <p className="rent_desc">
                                  {language.a_few_more_information_related}
                                </p>

                                <Form.Group className="resale_price">
                                  <Form.Label>
                                    {
                                      language.choose_your_preferred_states_label
                                    }
                                  </Form.Label>
                                  <CustomMultiSelect
                                    options={this.state.allStates}
                                    value={availability}
                                    handleChange={this.stateSelected}
                                  />
                                </Form.Group>

                                <Form.Group className="resale_price">
                                  <Form.Label>
                                    {
                                      language.select_your_prefered_working_areas_label
                                    }
                                  </Form.Label>

                                  <CustomMultiSelect
                                    options={this.state.allAreas}
                                    value={utilization}
                                    handleChange={this.areaSelected}
                                  />
                                </Form.Group>
                              </>
                            )}
                            <Row>
                              <Col lg="6" md="6" sm="12">
                                <div className="datetime_p">
                                  <label>{language.purchase_year_label}</label>
                                  <div className="innerdatetime">
                                    <ReactDatePicker
                                      placeholderText={
                                        language.select_purchase_year_plh
                                      }
                                      showYearPicker
                                      selected={
                                        purchase_date !== "" &&
                                        new Date(purchase_date)
                                      }
                                      dateFormat="yyyy"
                                      maxDate={new Date()}
                                      minDate={new Date("1990")}
                                      onChange={(date) => {
                                        this.setState({
                                          purchase_date:
                                            moment(date).format("YYYY"),
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6" md="6" sm="12">
                                <div className="range_rent_slider hmr_slider">
                                  <label>{language.current_hmr}</label>
                                  <div className="hmr_value">
                                    {this.state.hmr}
                                  </div>
                                  <Slider
                                    onChange={(value) => {
                                      this.setState({ hmr: value });
                                    }}
                                    defaultValue={0}
                                    min={0}
                                    max={8000}
                                    value={this.state.hmr}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="byclicking">
                              <small className="invalid_input">
                                {language.terms_condition_label}{" "}
                                <Link to="/privacy-policy">
                                  {language.privacy_policy}
                                </Link>{" "}
                                {language.and}{" "}
                                <Link to="/terms-conditions">
                                  {language.terms_conditions}
                                </Link>
                                .
                              </small>
                            </div>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="save_for_later_submit">
                        <Button
                          onClick={this.handleEditMachine}
                          type="button"
                          className="btn_primary btn-sm"
                        >
                          {language.submit_btn}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(EditEquipment);
