import {
  LANGUAGE_FAILED,
  LANGUAGE_REQUEST,
  LANGUAGE_SUCCESS,
} from "../constant/LanguageConstants";
import axios from "../../config/axios";
import config from "../../config/apiHeader";

export const GetLanguageDetails = (body) => async (dispatch) => {
  dispatch({
    type: LANGUAGE_REQUEST,
  });

  await axios
    .post("/language-utility-web", body, config)
    .then((res) => {
      if (res.data.status === "1") {
        dispatch({
          type: LANGUAGE_SUCCESS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: LANGUAGE_FAILED,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: LANGUAGE_FAILED,
        payload: err?.response?.data,
      });
    });
};
