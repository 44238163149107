import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import { Sticky, StickyContainer } from "react-sticky";
import { equipment_search, list_view, map_view } from "../../assets/images";
import axios from "../../config/axios";
import message from "../../utils/messages";
import config from "../../config/apiHeader";
import { DefaultParts } from "../../images";
import { Link } from "react-router-dom";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import GoogleMapReact from "google-map-react";
import LocationPin from "../../utils/LocationPin";
import "./partsdealer.css";
import SelectWIthIcon from "../../utils/SelectWIthIcon";
import AutoCompleteLocation from "../../utils/AutoCompleteLocation";
import CustomSlider from "../../utils/CustomSlider";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class Parts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      isLoading: false,
      isGridView: true,

      total: 0,
      total_map_count: 0,
      currentPage: 1,
      per_page: 10,
      last_page: 0,
      map_last_page: 0,

      allParts: [],
      all_machine_types: [],
      allBrands: [],
      location: "",

      //   ADVANCE FILTER
      parts_type: "3",
      brand: "",
      lat: "",
      long: "",
      km: "",
      selected_brand: null,

      filter: {
        isApplyFilter: false,
        name: "",
        parts_type: "3",
        brand: "",
        lat: "",
        long: "",
        km: "",
      },
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    this.getAllParts();
    this.getAllBrands();
    this.getAllMachineTypes();
  };

  getAllParts = () => {
    this.setState({ isLoading: true });
    let { hash_id, parts_type, currentPage, allParts, brand, lat, long, km } =
      this.state;

    let formData = {
      hash_id,
      page: currentPage,

      // FILTER

      parts_type,
      brand,
      lat,
      long,
      km,
    };

    axios
      .post("/parts-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { profiles, total, total_map_count, map_last_page, last_page } =
            res.data.data;

          currentPage += 1;

          this.setState({
            allParts: allParts.concat(profiles),
            total,
            currentPage,
            total_map_count,
            map_last_page,
            last_page,
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getAllBrands = () => {
    axios
      .get("/brand-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allBrands = res.data.data.brand.map((brand) => {
            return {
              value: brand.id,
              label: brand.brand_name,
              icon: brand.brand_img_url,
            };
          });
          // remove I don't know and Other fields
          allBrands.pop();
          allBrands.pop();
          this.setState({
            allBrands,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllMachineTypes = () => {
    axios
      .get("/all-machine-type-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_machine_types = res.data.data.machine_types.map((machine) => {
            return {
              value: machine.id,
              label: machine.machine_type_name,
              image: machine.image,
            };
          });

          this.setState({
            all_machine_types,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getSearchParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);

    let query = {};

    searchParams.forEach((value, key) => {
      Object.assign(query, { [key]: value });
    });

    return query;
  };

  handleOnChange = (e) => {
    let { name, value } = e.target;
    let { filter } = this.state;

    filter[name] = value;
    filter.isApplyFilter = false;

    this.setState({ filter });
  };

  handleSelect = (name, value) => {
    let { filter } = this.state;
    let brand_name = value.map((item) => {
      return item.value;
    });

    filter[name] = brand_name.toString();
    filter.isApplyFilter = false;
    this.setState({ filter });
  };

  setViewType = () => {
    this.setState({ isGridView: !this.state.isGridView });
  };

  addSearchParam = (url, key, value) => {
    if (url.includes("?")) {
      // ADD ANOTHER QUERY
      url += `&${key}=${value}`;
    } else {
      // FIRST QUERY
      url += `?${key}=${value}`;
    }

    return url;
  };

  clearFilter = () => {
    this.setState(
      {
        currentPage: 1,
        allParts: [],
        parts_type: "3",
        brand: "",
        lat: "",
        long: "",
        km: "",
        selected_brand: null,

        filter: {
          isApplyFilter: false,
          name: "",
          parts_type: "3",
          brand: "",
          lat: "",
          long: "",
          km: "",
        },
      },
      () => {
        this.getAllParts();
      }
    );
  };

  applyFilter = async () => {
    let { filter } = this.state;

    if (filter.isApplyFilter) {
      // CLEAR FILTER

      return;
    } else {
      const entries = Object.entries(filter);

      let url = "/parts";

      for (let [key, value] of entries) {
        if (key !== "isApplyFilter" && value) {
          url = this.addSearchParam(url, key, value);
        }
        this.setState({ [key]: value });
      }

      filter.isApplyFilter = true;

      this.setState({ filter, currentPage: 1, allParts: [] }, () => {
        this.getAllParts();
      });
      this.props.history.push(url);
    }
  };

  handleGetLocation = (value) => {
    if (value) {
      let { label, lat, long } = value;

      let { filter } = this.state;

      filter.lat = lat;
      filter.long = long;
      filter.isApplyFilter = false;

      this.setState({
        location: label,
        filter,
      });
    }
  };

  render() {
    let {
      filter,
      allParts,
      isGridView,
      total,
      currentPage,
      per_page,
      total_map_count,
      map_last_page,
      last_page,
      allBrands,
      parts_type,
    } = this.state;

    if (!isGridView) {
      total = parseInt(total_map_count);
      last_page = parseInt(map_last_page);
    }
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.parts_dealers;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="buy_rent_part">
                    <StickyContainer>
                      <Row>
                        <Col lg="9" md="8" sm="12">
                          <div className="dashboard_right_panel">
                            <h2>{language.parts_dealers_h}</h2>

                            <div className="rent_result_top">
                              <h2>
                                {language.showing_results_for}{" "}
                                {parts_type.parts_type === "2"
                                  ? language.used_parts_label
                                  : parts_type.parts_type === "1"
                                  ? language.new_parts_label
                                  : language.new_and_used_parts_label}
                              </h2>
                              <div className="buy_search_box">
                                <div className="search_box_rent">
                                  <Form.Control
                                    type="text"
                                    className="search_textbox"
                                    placeholder="Search.."
                                    onChange={this.handleOnChange}
                                    name="name"
                                    value={filter.name}
                                  />
                                  <span
                                    className="eqp_search_icon"
                                    // onClick={() => {
                                    //   let { name } = filter;
                                    //   this.props.history.push(
                                    //     `/parts?name=${name}`
                                    //   );
                                    // }}
                                  >
                                    <img alt="HMC" src={equipment_search} />
                                  </span>
                                </div>

                                <div className="buy_rent_viewbox">
                                  <span
                                    onClick={this.setViewType}
                                    className={
                                      this.state.isGridView
                                        ? " "
                                        : "active_color"
                                    }
                                  >
                                    <img alt="List View" src={list_view} />
                                  </span>
                                  <span
                                    onClick={this.setViewType}
                                    className={
                                      this.state.isGridView
                                        ? "active_color"
                                        : " "
                                    }
                                  >
                                    <img alt="Map View" src={map_view} />
                                  </span>
                                </div>
                              </div>
                            </div>

                            {isGridView ? (
                              <Row className="individual_partts">
                                {allParts.map((parts, key) => {
                                  return (
                                    <Col md={4} key={key}>
                                      <div className="pd_card">
                                        <div className="sponsor_box_left">
                                          <div className="sponsor_img1">
                                            <LazyLoadImage
                                              src={
                                                parts.img_url
                                                  ? MEDIA_BASE_URL +
                                                    parts.img_url
                                                  : DefaultParts
                                              }
                                              alt={parts.business_name}
                                              className="img-fluid"
                                              effect="blur"
                                            />
                                          </div>
                                        </div>
                                        <div className="sponsor_box_bottom">
                                          <div className="service_prv_right_top">
                                            <h2>
                                              <Link
                                                to={`/parts-dealer-details/${parts.profile_hash_id}`}
                                              >
                                                {parts.business_name}
                                              </Link>
                                            </h2>

                                            <div className="location_partts">
                                              <i className="fa fa-map-marker" />
                                              <span>
                                                {parts.city &&
                                                  parts.city + ", "}
                                                {parts.state}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="service_prv_right_bottom align-items-center">
                                            <div className="rating_service">
                                              <label>
                                                {language.rating_label}
                                              </label>
                                              <div className="result_star">
                                                <i className="fa fa-star" />
                                                {parts.service_rating}
                                              </div>
                                            </div>
                                            <div className="rating_service">
                                              <a
                                                className="rating_connect"
                                                href="#"
                                              >
                                                {language.chat_btn}
                                              </a>
                                            </div>
                                          </div>
                                          <div className="individual_tag">
                                            {parts.parts_type === "2"
                                              ? language.used_parts_label
                                              : parts.parts_type === "1"
                                              ? language.new_parts_label
                                              : language.new_and_used_parts_label}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            ) : (
                              <div style={{ height: "100vh", width: "100%" }}>
                                <GoogleMapReact
                                  defaultCenter={{ lat: 12.97, lng: 77.59 }}
                                  defaultZoom={5}
                                >
                                  {allParts.map((parts, key) => {
                                    return (
                                      <LocationPin
                                        key={key}
                                        lat={parts.lat}
                                        lng={parts.long}
                                        text={parts.city}
                                      />
                                    );
                                  })}
                                </GoogleMapReact>
                              </div>
                            )}

                            <div className="showing_result">
                              <p>
                                {language.pagination_showing_results_label}{" "}
                                {Math.min(total, (currentPage - 1) * per_page)}{" "}
                                {language.pagination_out_of_label} {total}
                              </p>
                              <Button
                                className="submit_button commn_button"
                                type="submit"
                                disabled={currentPage - 1 >= last_page}
                                onClick={this.getAllParts}
                              >
                                {language.view_more_btn}
                              </Button>
                            </div>
                          </div>
                        </Col>

                        {/* FILTER */}

                        <Col lg="3" md="4" sm="12">
                          <Sticky>
                            {({ style }) => (
                              <div style={style} className="filter_partts">
                                <div className="filter_heading">
                                  <span>{language.filter_label}</span>
                                </div>
                                <div className="eqp_added">
                                  <h3>{language.select_parts_type}</h3>

                                  <div className="eqp_added_radiopart">
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={language.all}
                                      name="parts_type"
                                      type="radio"
                                      id="3-days"
                                      value="3"
                                      checked={filter.parts_type === "3"}
                                    />
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={language.new}
                                      name="parts_type"
                                      type="radio"
                                      id="New"
                                      value="1"
                                      checked={filter.parts_type === "1"}
                                    />
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={language.used}
                                      name="parts_type"
                                      type="radio"
                                      id="Used"
                                      value="2"
                                      checked={filter.parts_type === "2"}
                                    />
                                  </div>

                                  <div className="select_box_rent">
                                    <Form.Label className="mt-2">
                                      {language.equipment_brand_name_label}
                                    </Form.Label>

                                    <SelectWIthIcon
                                      className="form-control select_inner_box"
                                      options={allBrands}
                                      placeholder={language.select_brand_plh}
                                      isMulti={true}
                                      value={this.state.selected_brand}
                                      onChange={(value) => {
                                        let selected_brand = value;
                                        this.setState({ selected_brand });
                                        this.handleSelect("brand", value);
                                      }}
                                    />

                                    <Form.Label className="mt-2">
                                      {language.locations_label}
                                    </Form.Label>

                                    <AutoCompleteLocation
                                      handleGetLocation={this.handleGetLocation}
                                      value={this.state.location}
                                    />

                                    <div className="range_rent_slider">
                                      <Form.Label className="mt-2">
                                        {language.select_radius_label}
                                      </Form.Label>
                                      <div className="hmr_value">
                                        {filter.km || 0}km-6000KM
                                      </div>

                                      <CustomSlider
                                        max={6000}
                                        value={filter.km}
                                        handleSlider={(km) => {
                                          filter.km = km;
                                          filter.isApplyFilter = false;
                                          this.setState({ filter });
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="range_rent_slider mt-3">
                                    <div className="submit_button_rent mt-3">
                                      <Button
                                        onClick={this.applyFilter}
                                        type="submit"
                                      >
                                        {filter.isApplyFilter
                                          ? language.clear_btn
                                          : language.apply_btn}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Sticky>
                        </Col>
                      </Row>
                    </StickyContainer>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(Parts);
