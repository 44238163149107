import Axios from "axios";

const getBaseUrl = () => {
  switch (localStorage.getItem("country")) {
    // case "SA":
    //   return "https://manage.hmcksa.online/api";

    default:
      // return "https://prod.heavymachinerycare.com/api" // PROD
      return "https://test.heavymachinerycare.com/api"; // TEST
  }
};

const axios = Axios.create({
  // baseURL: "https://prod.heavymachinerycare.com/api", // prod
  // baseURL: "https://staging.heavymachinerycare.com/api" //staging
  // baseURL: "https://test.heavymachinerycare.com/api", //test
  // baseURL: "http://127.0.0.1:8000/api" //local

  baseURL: getBaseUrl(),
});

export default axios;
