import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import {
  call_back,
  send_message,
  expert_buying,
  expert_requirment,
  expert_spare_parts,
  expert_job,
  expert_consult,
  expert_service,
  expert_banner,
  expert_rent,
  expert_selling,
} from "../../../assets/images";
import config from "../../../config/apiHeader";
import axios from "../../../config/axios";
import validation from "../../Validate/validator";
import message from "../../../utils/messages";
import "./expertconnect.css";
import CustomPopup from "../../../utils/CustomPopup";
import OtpInput from "react-otp-input";
import Seo from "../../seo/Seo";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language, country_code } = state;
  return { language, country_code };
};

class ExpertConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      allCategory: [],
      email: "",
      mobile: "",
      countryCode: "",
      title: "",
      subject: "",
      description: "",

      error: {},

      // REQUEST CALL BACK
      showExpertiseModal: false,
      isOTP: false,
      otp: "",
      otpError: false,

      timer: "00",
      onComplete: false,

      footer_faq_data: [],
      seo_data: {},
      country_code_list: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.getMetaData();
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    // this.getAllCountryList();

    this.getContactCategoey();
  };

  getMetaData = () => {
    axios
      .get(
        `/web-seo-content?page_key=expert_advice&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getContactCategoey = () => {
    axios
      .get("/contactus-category")
      .then((res) => {
        if (res.data.status === "1") {
          let resuserData = res.data.data;
          this.setState({
            allCategory: resuserData,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleOnChange = (e) => {
    e.persist();

    let { error } = this.state;
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    var valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  handlePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      let internatonal = value && formatPhoneNumberIntl(value);
      let countryCode = internatonal.split(" ");
      if (isValidPhoneNumber(value)) {
        error.mobile = "";
      } else {
        error.mobile = "Enter Valid Mobile Number";
      }

      this.setState({
        mobile: value,
        countryCode: countryCode[0],
        error,
      });
    } else {
      error.mobile = "Enter Valid Mobile Number";
      this.setState({ error, mobile: "" });
    }
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValidForm(this.state.error)) {
      let { hash_id, title, subject, description, email, mobile } = this.state;

      let formData = {
        sender_id: hash_id,
        title,
        subject,
        description,
        type: 2,
        email_id: email,
        phone_no: mobile,
      };
      this.setState({ isLoading: true });
      axios
        .post("/send_message", formData, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.status === "1") {
            message.success(res.data.message);
            this.setState({
              title: "",
              subject: "",
              description: "",
              email: "",
              mobile: "",
            });
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.error(err);
          message.error("Something went wrong!!");
        });
    }
  };

  handleCallBackRequest = () => {
    if (!this.state.hash_id) {
      this.setState({ showExpertiseModal: true });
      return;
    }
    this.handleRequestCallback();
  };

  handleShowExpertiseModal = () => {
    this.setState({
      showExpertiseModal: false,
      mobile: "",
      Error: "",
      otp: "",
      timer: "00",
      onComplete: false,
    });
  };

  handleRequestCallback = () => {
    this.setState({ isLoading: true });
    const formData = {
      sender_id: this.state.hash_id,
      type: 1,
      mobile: this.state.mobile,
    };
    axios
      .post("/send_message", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.setState({ mobile: "", isOTP: false, otp: "" });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
      });
  };

  handleSubmitExpertiseModal = (e) => {
    e.preventDefault();

    if (this.isValidForm(this.state.error)) {
      if (this.state.isOTP) {
        this.veryfyOTP();
        return;
      }
      this.setState({ isLoading: true });
      this.getOTP();
    }
  };

  getOTP = () => {
    this.setState({ isLoading: true });
    const formData = {
      mobile: this.state.mobile,
      country_code: this.state.countryCode,
    };
    axios
      .post("/send-otp-mobile", formData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          this.setState({
            isOTP: true,
            otpCode: res.data.otp,
            onComplete: false,
          });
          this.startTimer(30);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
      });
  };

  startTimer = (seconds) => {
    let myTimer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(myTimer);
        this.setState({ onComplete: true });
      }
      this.setState({ timer: seconds > 9 ? seconds : "0" + seconds });
      seconds--;
    }, 1000);
  };

  veryfyOTP = () => {
    if (!this.state.otp) {
      this.setState({ otpError: true });
      return;
    }
    if (this.state.otp === this.state.otpCode) {
      this.setState({ isLoading: true });
      this.setState({ showExpertiseModal: false });
      this.handleRequestCallback();
    } else {
      this.setState({ otpError: true });
    }
  };

  render() {
    let { error, country_code_list } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.expert_advice;
    } else {
      language = {};
    }

    let country_loading = this.props.country_code.loading;
    let country_code = this.props.country_code.country_code_list;

    if (!country_loading && country_code) {
      country_code_list = country_code.country_code_list.map((val) => {
        return val.code;
      });
    }

    return (
      <div className="home">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Loading ..."
        >
          <CustomPopup
            show={this.state.showExpertiseModal}
            title={
              this.state.isOTP
                ? language.enter_otp_h
                : language.send_request_enter_mobile_number
            }
            onClose={this.handleShowExpertiseModal}
          >
            <LoadingOverlay
              active={this.state.isLoading}
              spinner
              text="Loading ..."
            >
              <Form onSubmit={this.handleSubmitExpertiseModal}>
                <Form.Group controlId="formBasicEmail">
                  {!this.state.isOTP ? (
                    <>
                      {country_code_list.length !== 0 && (
                        <PhoneInput
                          className="exp_phone_input"
                          defaultCountry={
                            localStorage.getItem("country") || "IN"
                          }
                          countries={country_code_list}
                          value={this.state.mobile}
                          required
                          placeholder={
                            language.send_request_enter_mobile_number_placeholder
                          }
                          onChange={this.handlePhoneChange}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <OtpInput
                        onChange={(otp) => {
                          this.setState({ otp, otpError: false });
                        }}
                        numInputs={4}
                        inputStyle="inputStyle"
                        isInputNum={true}
                        placeholder="****"
                        hasErrored={this.state.otpError}
                        errorStyle="error"
                        value={this.state.otp}
                      />
                      <div className="countdown">
                        {language.time_left} : {this.state.timer}
                      </div>
                      {this.state.onComplete && (
                        <div className="resend">
                          {language.didnot_receive_code}{" "}
                          <span onClick={this.getOTP}>{language.resend}</span>
                        </div>
                      )}
                    </>
                  )}

                  <p className="invalid_input">{error.mobile || error.otp}</p>
                </Form.Group>
                {!this.state.isOTP && (
                  <p className="mt-3">{language.send_request_form_text}</p>
                )}
                <div className="submit_lead">
                  <Button variant="primary" type="submit" className="ml-5">
                    {this.state.isOTP
                      ? language.verify_otp_btn
                      : language.send_request_form_get_otp_btn}
                  </Button>
                </div>
              </Form>
            </LoadingOverlay>
          </CustomPopup>

          <div className="projects_top_banner">
            <div className="projects_bannerer">
              <img src={expert_banner} alt="Project Banner" />
              <div className="projets_banner_heading">
                <h2>{language.expert_advice_heading}</h2>
              </div>
            </div>
          </div>

          <section className="expert_carousel">
            <Container>
              <div className="expert_car_inner row">
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_requirment} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Selling Construction Equipment</h3> */}
                      <p>{language.expert_advice_desc_1}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_selling} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Selling Construction Equipment</h3> */}
                      <p>{language.expert_advice_desc_2}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_buying} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Buying Construction Equipment</h3> */}
                      <p>{language.expert_advice_desc_3}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_rent} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Renting Construction Equipment</h3> */}
                      <p>{language.expert_advice_desc_4}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_service} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Services</h3> */}
                      <p>{language.expert_advice_desc_5}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_spare_parts} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Spare Parts</h3> */}
                      <p>{language.expert_advice_desc_6}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_job} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Finding a job or Hiring</h3> */}
                      <p>{language.expert_advice_desc_7}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={4} className="carousel_inner text-center">
                  <div className="car_box">
                    <div className="car_img">
                      <img alt="HMC" src={expert_consult} />
                    </div>
                    <div className="ex_text">
                      {/* <h3>Finding a job or Hiring</h3> */}
                      <p>{language.expert_advice_desc_8}</p>
                    </div>
                  </div>
                </Col>
              </div>
            </Container>
          </section>

          <section className="send_messaage_form">
            <Container>
              <Row className="justify-content-center">
                <Col md={8}>
                  <div className="send_message_heading text-center">
                    <h2>{language.send_us_a_message}</h2>
                    <p>{language.send_us_a_message_desc}</p>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="send_inner_message">
                    <div className="send_inner_left">
                      <img alt="HMC" src={send_message} />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="send_message_inner_form">
                    <Form onSubmit={this.handleSubmit}>
                      {!this.state.hash_id && (
                        <>
                          <Form.Group controlId="email">
                            <Form.Label>
                              {language.expert_form_email}
                              <span className="required">*</span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder={language.expert_form_enter_email}
                              value={this.state.email}
                              name="email"
                              onChange={this.handleOnChange}
                              validaterule={["isEmail"]}
                              validatemsg={["Enter valid email address"]}
                              required={!this.state.hash_id}
                            />
                            <p className="invalid_input">
                              {error.email &&
                                language.enter_valid_email_address}
                            </p>
                          </Form.Group>
                          <Form.Group controlId="mobile">
                            <Form.Label>
                              {language.expert_form_mobile}
                              <span className="required">*</span>
                            </Form.Label>
                            {country_code_list.length !== 0 && (
                              <PhoneInput
                                className="exp_phone_input"
                                defaultCountry={
                                  localStorage.getItem("country") || "IN"
                                }
                                countries={country_code_list}
                                value={this.state.mobile}
                                required={!this.state.hash_id}
                                placeholder={
                                  language.expert_enter_mobile_number
                                }
                                onChange={this.handlePhoneChange}
                              />
                            )}
                            <p className="invalid_input">
                              {error.mobile &&
                                language.enter_valid_mobile_number}
                            </p>
                          </Form.Group>
                        </>
                      )}

                      <Form.Group controlId="formBasicText">
                        <Form.Label>
                          {language.expert_form_select_your_query}
                          <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="title"
                          value={this.state.title}
                          onChange={this.handleOnChange}
                          required
                          validaterule={["required"]}
                          validatemsg={["This field is required"]}
                        >
                          <option value="">
                            {language.expert_form_what_are_you_looking_for}
                          </option>

                          {this.state.allCategory?.map((items, key) => {
                            return (
                              <option value={items.id} key={key}>
                                {items.title}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <p className="invalid_input">
                          {error.title &&
                            language.select_your_specific_query_message}
                        </p>
                      </Form.Group>

                      <Form.Group controlId="formBasicText">
                        <Form.Label>
                          {language.expert_form_subject}
                          <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={language.expert_form_enter_subject}
                          value={this.state.subject}
                          name="subject"
                          onChange={this.handleOnChange}
                          required
                          validaterule={["required"]}
                          validatemsg={["This field is required"]}
                        />
                        <p className="invalid_input">
                          {error.subject && language.subject_message}
                        </p>
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>
                          {language.expert_form_description}
                          <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder={language.expert_form_enter_message}
                          value={this.state.description}
                          name="description"
                          onChange={this.handleOnChange}
                          required
                          validaterule={["required"]}
                          validatemsg={["This field is required"]}
                        />
                        <p className="invalid_input">
                          {error.description && language.description_message}
                        </p>
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn_primary btn-lg"
                      >
                        {language.send_message_btn}
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="request_call">
            <Container>
              <div className="request_call_inner">
                <Row className="align-items-center">
                  <div className="send_inner_left col-md-2 col-sm-3">
                    <img alt="HMC" src={call_back} className="img-fluid" />
                  </div>
                  <div className="send_inner_right col-md-7 col-sm-6">
                    <h3>{language.request_a_call_back}</h3>
                    <p>{language.request_a_call_back_desc}</p>
                  </div>
                  <div className="col-md-3 col-sm-3 text-right">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn_primary btn-lg"
                      onClick={this.handleCallBackRequest}
                    >
                      {language.send_request_btn}
                    </Button>
                  </div>
                </Row>
              </div>
            </Container>
          </section>
          <Seo {...this.state} />
        </LoadingOverlay>
      </div>
    );
  }
}
export default connect(mapStateToProps)(ExpertConnect);
