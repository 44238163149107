import React, { Component } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import {
  hmc_user_onboard,
  Lead_Arrow_Img,
  Three_Dots,
  hmc_user_not_onboard,
  not_hmc_user,
  Upload_img_icon_img,
  pdf_preview,
  word_preview,
  cross_preview,
  Up_doc_icon_img,
  excel_preview,
} from "../../../assets/images";
import ReactTooltip from "react-tooltip";
import "./allLead.css";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DeletePopup from "../../../utils/DeletePopup";
import { LeadTypes } from "../../../utils/LeadTypes";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import CustomPopup from "../../../utils/CustomPopup";
import config from "../../../config/apiHeader";
import LoadingOverlay from "react-loading-overlay";
import { MEDIA_BASE_URL, getFileExtension } from "../../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class MyLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      clickedPosition: -1,

      // DELETE
      isDelete: false,
      delete_id: "",

      // ADD DOCUMENT AND IMAGE
      isAddedDocument: false,
      lead_id: "",
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
  };

  handleDocument = async (e) => {
    e.persist();

    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    let files = e.target.files;

    let validExtension = ["doc", "docx", "pdf", "xls", "xlsx"];

    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);

        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          let preview;
          if (extension === "docx" || extension === "doc") {
            preview = word_preview;
          } else if (extension === "pdf") {
            preview = pdf_preview;
          } else {
            preview = excel_preview;
          }
          equipment_docs.push(file);

          equipment_docs_preview.push({ name: file.name, preview });
          attachment_title.push(file.name);

          this.setState({
            equipment_docs,
            equipment_docs_preview,
          });
        } else {
          message.error(file.name + " is not a valid document");
        }
      }
    });
  };

  handleImages = async (e) => {
    e.persist();

    let { equipment_images } = this.state;

    let files = e.target.files;
    let validExtension = ["png", "jpg", "jpeg"];
    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          equipment_images.push(file);
        } else {
          message.error(file.name + " is not a valid image");
        }
      }
      await this.setState({ equipment_images });
    });
  };

  closeAddDocumentPopup = (isAddedDocument) => {
    this.setState({
      isAddedDocument,
      lead_id: "",
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
      isLoading: false,
    });
  };

  leadDocumentUpload = () => {
    this.setState({ isAddedDocument: false });
    let { lead_id, equipment_docs, attachment_title, old_attachment, hash_id } =
      this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });
    attachment_title.map((title, key) => {
      formData.append(`attachment_title[${key}]`, title);
    });
    formData.append("attachment_type", 2);
    formData.append("medium", "web");
    formData.append("section_name", "lm_my_lead_doc_upload");
    formData.append("lead_assignee_act_id", "");
    formData.append("hash_id", hash_id);

    equipment_docs.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.props.setLoading(true);

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.leadImageUpload();

        if (res.data.status === "1") {
          // message.success("Document Upload Successfully");

          this.setState({
            equipment_docs: [],
            equipment_docs_preview: [],
            attachment_title: [],
            old_docs: [],
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  leadImageUpload = () => {
    let { equipment_images, lead_id, old_attachment, hash_id } = this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    formData.append("attachment_title", []);
    formData.append("attachment_type", 1);
    formData.append("medium", "web");
    formData.append("section_name", "lm_my_lead_doc_upload");
    formData.append("lead_assignee_act_id", "");
    formData.append("hash_id", hash_id);

    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    equipment_images.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.props.setLoading(true);

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.props.setLoading(false);

        if (res.data.status === "1") {
          message.success("Image Upload Successfully");
          this.closeAddDocumentPopup(false);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllDocumentsLeadWise = (lead_hash_id) => {
    this.setState({ isLoading: true });
    const formData = {
      lead_hash_id,
      medium: "web",
      section_name: "lm_my_lead_get_all_doc",
      call_from: "my_lead",
    };
    axios
      .post("/get-lead-all-document", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let old_attachment = [];
          let old_images = [];
          let old_docs = [];
          res.data.data.forEach((doc) => {
            old_attachment.push(doc.id);
            if (doc.attachment_type === "1") {
              old_images.push({
                url: doc.attachment_url,
                id: doc.id,
              });
            } else {
              let url = doc.attachment_url;
              let preview;

              if (url.endsWith(".doc") || url.endsWith(".docx")) {
                preview = word_preview;
              } else if (url.endsWith(".pdf")) {
                preview = pdf_preview;
              } else {
                preview = excel_preview;
              }

              old_docs.push({
                url,
                id: doc.id,
                name: doc.attachment_title,
                preview,
              });
            }
          });

          this.setState({ old_attachment, old_images, old_docs });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  deleteOldImage = async (id, pos) => {
    let { old_attachment, old_images } = this.state;
    old_images.splice(pos, 1);

    let index = old_attachment.indexOf(id);
    old_attachment.splice(index, 1);

    await this.setState({ old_images, old_attachment });
  };

  deleteImage = async (pos) => {
    let { equipment_images } = this.state;
    equipment_images.splice(pos, 1);
    await this.setState({ equipment_images });
  };

  render() {
    let { collapsedArray, my_lead } = this.props;
    let { equipment_images, equipment_docs_preview, old_images, old_docs } =
      this.state;
    const popperConfig = {
      strategy: "fixed",
    };
    let { loading, language } = this.props.language;
    let lead_action = {};
    let add_documents_images = {};

    if (!loading && language) {
      lead_action = language.lead_action;
      add_documents_images = language.add_documents_images;
      language = language.all_leads;
    } else {
      language = {};
    }

    return (
      <>
        {/* DELETE LEAD */}
        <DeletePopup
          show={this.state.isDelete}
          message={lead_action.delete_alert_msg}
          yes_msg={lead_action.yes_btn}
          no_msg={lead_action.no_btn}
          no={() => {
            this.setState({
              isDelete: false,
            });
          }}
          yes={() => {
            this.setState({
              isDelete: false,
            });
            this.props.deleteLead(this.state.delete_id);
          }}
        />

        {/* ADD DOCUMENT */}
        <CustomPopup
          show={this.state.isAddedDocument}
          title={add_documents_images.add_documents_images_h}
          onClose={this.closeAddDocumentPopup}
          isCenter={true}
        >
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Loading ..."
          >
            {/* IMAGE */}
            <Form.Group>
              <Form.Label>
                {add_documents_images.add_equipment_images}
              </Form.Label>
              <div className="upload_image_box">
                {old_images &&
                  old_images.map((image, key) => {
                    return (
                      <div className="image_box" key={key}>
                        <img src={MEDIA_BASE_URL + image.url} alt="HMC" />
                        <span
                          className="close_preview"
                          onClick={() => {
                            this.deleteOldImage(image.id, key);
                          }}
                        >
                          <img src={cross_preview} alt="HMC" />
                        </span>
                      </div>
                    );
                  })}

                {equipment_images &&
                  equipment_images.map((preview, index) => {
                    return (
                      <div className="image_box" key={index}>
                        <img src={URL.createObjectURL(preview)} alt="HMC" />
                        <span
                          className="close_preview"
                          onClick={() => {
                            this.deleteImage(index);
                          }}
                        >
                          <img src={cross_preview} alt="HMC" />
                        </span>
                      </div>
                    );
                  })}

                <div className="upload_box">
                  <div className="upload_btn_wrappererer">
                    <button className="btnner">
                      <img src={Upload_img_icon_img} />
                    </button>

                    <Form.Control
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      name="images"
                      multiple
                      onChange={this.handleImages}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>

            {/* DOCUMENT */}
            <Form.Group>
              <Form.Label>{add_documents_images.add_document}</Form.Label>
              <div className="cert_box">
                {old_docs &&
                  old_docs.map((doc, index) => {
                    return (
                      <div className="cert_box_inner" key={index}>
                        <div className="cret_file">
                          <img src={doc.preview} alt="HMC" />
                        </div>
                        <Form.Label>{doc.name}</Form.Label>
                      </div>
                    );
                  })}
                {equipment_docs_preview &&
                  equipment_docs_preview.map((doc, index) => {
                    return (
                      <div className="cert_box_inner" key={index}>
                        <div className="cret_file">
                          <img src={doc.preview} alt="HMC" />
                        </div>
                        <Form.Label>{doc.name}</Form.Label>
                        <div className="click_drop"></div>
                      </div>
                    );
                  })}

                <div className="cert_box_upload">
                  <div className="upload_btn_wrappererer">
                    <button className="btnner">
                      <img src={Up_doc_icon_img} />
                    </button>
                    <Form.Control
                      type="file"
                      accept=".doc, .docx, .pdf, .xls,.xlsx"
                      onChange={this.handleDocument}
                      multiple
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
            <div className="status_footer">
              <Button
                type="submit"
                className="status_update"
                onClick={this.leadDocumentUpload}
              >
                {add_documents_images.submit_btn}
              </Button>
            </div>
          </LoadingOverlay>
        </CustomPopup>

        <div className="my_lead_inner_page my_lead_inner_box">
          <div className="mylead_inner_inner_page my_lead_inner_content">
            {my_lead.map((lead, index) => {
              let create_date = moment(lead.created_at).format("DD/MM/YYYY");
              let updated_date = moment(lead.updated_at).format("DD/MM/YYYY");

              let tags = lead.tags && lead.tags.split(",");
              let hmc_user_type, hmc_user_msg;
              if (lead.is_login === "1") {
                hmc_user_msg = language.hmc_user_onboard;
                hmc_user_type = hmc_user_onboard;
              } else if (lead.is_login === "0") {
                hmc_user_msg = language.hmc_user_not_onboard;
                hmc_user_type = hmc_user_not_onboard;
              } else if (lead.is_login === null) {
                hmc_user_msg = language.not_hmc_user;
                hmc_user_type = not_hmc_user;
              }
              return (
                <div
                  className={`lead_equipment eqp_lead ${
                    this.state.clickedPosition === index ? "blue_border" : ""
                  }`}
                  onClick={() => {
                    this.setState({ clickedPosition: index });
                  }}
                  key={index}
                >
                  <div className="eqp_lead_part">
                    <div className="lead_eq_inner">
                      <label>{language.lui}</label>
                      <span>{lead.luid}</span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.customer_name}</label>
                      <div className="d-flex align-items-center">
                        <label className="lead_tooltip">
                          <img
                            src={hmc_user_type}
                            data-tip
                            data-for={`user_type${index}`}
                          />
                          <ReactTooltip
                            id={`user_type${index}`}
                            place="top"
                            effect="solid"
                          >
                            {hmc_user_msg}
                          </ReactTooltip>
                        </label>
                        <span>
                          <label data-tip data-for={`customer_name${index}`}>
                            {lead.customer_name}
                            <ReactTooltip
                              id={`customer_name${index}`}
                              place="top"
                              effect="solid"
                            >
                              {lead.customer_name},(
                              {lead.lead_type === "1"
                                ? language.customer
                                : lead.lead_type === "2"
                                ? language.dealer
                                : language.financer}
                              )
                            </ReactTooltip>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.location}</label>
                      <span>
                        {lead.customer_address_city} ,
                        {lead.customer_address_state}
                      </span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.current_status}</label>
                      {lead.current_status_name && (
                        <>
                          {lead.current_status === "16" ? (
                            <div className="pending">
                              {lead.current_status_name}
                            </div>
                          ) : lead.current_status === "33" ? (
                            <div className="failed">
                              {lead.current_status_name}
                            </div>
                          ) : lead.current_status === "32" ? (
                            <div className="pending">
                              {lead.current_status_name}
                            </div>
                          ) : (
                            <div className="success">
                              {lead.current_status_name}
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="lead_eq_inner ">
                      <label>{language.action}</label>
                      <div className="lead_label_inner">
                        <Dropdown className="select_box_preassigneds">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <img src={Three_Dots} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu popperConfig={popperConfig}>
                            <Link
                              to={{
                                pathname: `/edit-lead/${lead.hash_id}`,
                                state: {
                                  section_name: "update_lead",
                                  lead_types: LeadTypes.MY_LEAD,
                                  formData: this.props.formData,
                                },
                              }}
                              className="dropdown-item"
                              role="button"
                            >
                              {lead_action.edit_lead}
                            </Link>

                            <div
                              className="dropdown-item action-cursor"
                              onClick={() => {
                                this.setState({
                                  lead_id: lead.id,
                                  isAddedDocument: true,
                                });
                                this.getAllDocumentsLeadWise(lead.hash_id);
                              }}
                            >
                              {lead_action.add_documents_Images}
                            </div>

                            <div
                              className="dropdown-item action-cursor"
                              onClick={() => {
                                this.setState({
                                  isDelete: true,
                                  delete_id: lead.hash_id,
                                });
                              }}
                            >
                              {lead_action.delete}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>

                        <div className="lead_arrow">
                          <img
                            src={Lead_Arrow_Img}
                            onClick={() => {
                              collapsedArray[index] = !collapsedArray[index];
                              this.setState({
                                collapsedArray,
                              });
                            }}
                            className={
                              collapsedArray[index] ? "t_iconhide" : "t_icon"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      collapsedArray[index]
                        ? "collapse lead_equipment show"
                        : "collapse lead_equipment "
                    }
                  >
                    <div className="lead_eq_inner">
                      <label>{language.date}</label>
                      <span>{create_date}</span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.last_mod_date}</label>
                      <span>{updated_date}</span>
                    </div>
                    <div className="lead_eq_inner">
                      <label>{language.lead_type}</label>
                      <span>
                        <label data-tip data-for={`euipment_category${index}`}>
                          {lead.euipment_category === "1"
                            ? language.new_equipment
                            : language.old_equipment}
                          <ReactTooltip
                            id={`euipment_category${index}`}
                            place="top"
                            effect="solid"
                          >
                            {lead.euipment_category === "1"
                              ? language.new_equipment
                              : language.old_equipment}
                          </ReactTooltip>
                        </label>
                      </span>
                    </div>
                    <div className="lead_eq_inner">
                      <label>{language.equip_name}</label>

                      <span data-tip data-for={`euipment_name${index}`}>
                        {lead.euipment_name}
                        <ReactTooltip
                          id={`euipment_name${index}`}
                          place="top"
                          effect="solid"
                        >
                          {lead.euipment_name}
                        </ReactTooltip>
                      </span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.tags}</label>
                      {tags &&
                        tags.map((tag, key) => {
                          tag = tag.split("-");
                          let title = tag[0];
                          let tag_title_color = tag[1];
                          let tag_back_color = tag[2];
                          return (
                            <div
                              className="tags"
                              key={key}
                              style={{
                                background: tag_back_color,
                                color: tag_title_color,
                              }}
                            >
                              {title}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="lead_showing_result">
          <label>
            {language.showing_results}{" "}
            {Math.min(
              this.props.totalItems,
              this.props.currentPage * this.props.per_page
            )}{" "}
            {language.out_of} {this.props.totalItems}
          </label>
          <div className="result_pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(val) => {
                this.setState({ clickedPosition: -1 });
                this.props.onPageChange(val);
              }}
              pageRangeDisplayed={3}
              pageCount={this.props.totalPage}
              previousLabel="<"
              activeClassName="active"
              disabledClassName="disable"
              renderOnZeroPageCount={null}
              forcePage={parseInt(this.props.currentPage) - 1}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(MyLead);
