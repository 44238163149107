import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import Select from "react-select";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import validation from "../../../components/Validate/validator";
import axios from "../../../config/axios";
import AutoCompleteLocation from "../../../utils/AutoCompleteLocation";
import CustomPopup from "../../../utils/CustomPopup";
import message from "../../../utils/messages";
import "./addlead.css";
import { Search_Lead_Img, Lead_Upload_Img } from "../../../assets/images";
import ExitPopup from "../../../utils/ExitPopup";
import config from "../../../config/apiHeader";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language, country_code } = state;
  return { language, country_code };
};

class AddLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      country_code_list: [],
      allBrandList: [],
      allMachineModelList: [],
      filteredMachineTypeList: [],
      ownEquipmentList: [],
      phone: "",
      customer_phone: "",
      customer_name: "",
      customer_address: "",
      customer_id: "",
      euipment_name: "",
      equipment_available: "",
      euipment_category: "1",
      finance_type: "2",
      other_details: "",
      customer_address_state: "",
      customer_address_city: "",
      equipment_available_state: "",
      equipment_available_city: "",
      usr_equipment_act_id: "",
      hmc_equipment_act_id: "",

      lead_type: "",

      error: {},

      isEuipmentName: false,
      popupType: false,
      popupSearch: false,
      popupOwn: false,
      temp_euipment_name: "",
      brand_hash_id: "",
      isSearchLoading: false,
      nextPage: false,
      add_lead: false,
      user_list: [],
      on_behalf: false,
      creator_hash_id: "",
      select_creator_value: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
    if (sub_menus) {
      this.getAllCountryList();
      this.getAllBrandList();
      this.getAllMachineModelList();
      this.getUserList();
    } else {
      message.error(
        "Access Denied: Your account does not have sufficient permissions to perform the requested action."
      );
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  getAllBrandList = () => {
    let { hash_id } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
      call_from: "0",
    };

    axios
      .get("brand-list?medium=web?section_name=lm_get_all_brand", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allBrandList = [];
          res.data.data.brand.map((brand) => {
            return allBrandList.push({
              value: brand.brand_hash_id,
              label: brand.brand_name,
            });
          });
          this.setState({ allBrandList });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getAllMachineModelList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_get_all_model",
    };

    axios
      .post("/machine-model-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let allMachineModelList = [];
          res.data.data.machines.map((machine) => {
            return allMachineModelList.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
            });
          });
          this.setState({ allMachineModelList });
        }
      })
      .catch((err) => {
        // message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getUserList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_add_lead_get_user_list",
    };

    axios
      .post("/contact-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let user_list = res.data.data.map((user) => {
            return {
              label:
                user.contact_type === "1"
                  ? user.first_name + " " + user.last_name
                  : user.company_name,
              value: user.hash_id,
            };
          });
          this.setState({ user_list });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  handlePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      this.setState({ customer_phone: value });

      let number = parsePhoneNumber(value);
      if (number) {
        let country_code = "+" + number.countryCallingCode;
        let phone = number.nationalNumber;
        this.setState({ country_code, phone });
      }
      if (isValidPhoneNumber(value)) {
        error.customer_phone = "";
      } else {
        error.customer_phone = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error.customer_phone = "Enter Valid Mobile Number";
      this.setState({ error, phone: "" });
    }
  };

  getCustomerDetails = () => {
    this.setState({ isLoading: true });

    let { phone } = this.state;

    const formData = {
      phone,
      medium: "web",
      section_name: "lm_get_customer_details",
    };

    axios
      .post("/get-customer-details-for-lead", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { customer_details } = res.data.data;
          if (customer_details.length > 0) {
            let customer_id = customer_details[0].id;
            this.getOwnEquipments(customer_id);
            let customer_name = customer_details[0].full_name;
            let customer_address = customer_details[0].address;
            let customer_address_state = customer_details[0].state;
            let customer_address_city = customer_details[0].city;
            this.setState({
              customer_id,
              customer_name,
              customer_address,
              customer_address_state,
              customer_address_city,
            });
            this.requiredField();
          } else {
            message.error("No customer details found");
            this.setState({
              customer_id: "",
              customer_name: "",
              customer_address: "",
              customer_address_state: "",
              customer_address_city: "",
            });
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getOwnEquipments = (user_id) => {
    const formData = {
      user_id,
      medium: "web",
      section_name: "lm_own_equipments",
    };

    axios
      .post("/get-own-equipment-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let ownEquipmentList = [];
          res.data.data.map((equipment) => {
            return ownEquipmentList.push({
              value: equipment.machine_activity_id,
              label: equipment.model_name + ", hmr(" + equipment.hmr + ")",
              equipment,
            });
          });
          this.setState({ ownEquipmentList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  handleOnChange = (e) => {
    e.persist();
    let { error } = this.state;
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    if (name === "euipment_category") {
      this.setState({ finance_type: value === "1" ? "2" : "1" });
    }

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  handleGetLocation = (value) => {
    let { error } = this.state;
    if (value) {
      error.customer_address = "";
      this.setState({
        customer_address: value.label,
        customer_address_state: value.state,
        customer_address_city: value.city,
        error,
      });
    }
  };

  handleEqupmentLocation = (value) => {
    let { error } = this.state;
    if (value) {
      error.equipment_available = "";
      this.setState({
        equipment_available: value.label,
        equipment_available_state: value.state,
        equipment_available_city: value.city,
        error,
      });
    }
  };

  popUpClose = () => {
    this.setState({
      popupOwn: false,
      popupSearch: false,
      popupType: false,
      temp_euipment_name: "",
      nextPage: false,
    });
  };

  handleSelect = (key, v) => {
    this.setState({ [key]: v.value });
    if (key === "machine_hash_id") {
      this.setState({ euipment_name: v.label });
      this.popUpClose();
    }
    if (key === "own_equipment") {
      this.setState({
        usr_equipment_act_id: v.equipment.machine_activity_id,
        euipment_name: v.equipment.model_name,
        hmc_equipment_act_id: v.equipment.machine_id,
      });
      this.popUpClose();
    }
  };

  getFilteredMachineModelList = () => {
    this.setState({ isSearchLoading: true });
    let { brand_hash_id, hash_id } = this.state;
    const formData = {
      brand_hash_id,
      medium: "web",
      section_name: "lm_filtered_all_model",
    };
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };
    axios
      .post("/machine-model-list", formData, config)
      .then((res) => {
        this.setState({ isSearchLoading: false });
        if (res.data.status === "1") {
          let filteredMachineTypeList = [];
          res.data.data.machines.map((machine) => {
            return filteredMachineTypeList.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
            });
          });
          this.setState({ filteredMachineTypeList });
        }
      })
      .catch((err) => {
        this.setState({ isSearchLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  requiredField = () => {
    const arr = [
      "customer_name",
      "customer_address",
      "lead_type",
      // "equipment_available",
    ];
    let { error } = this.state;

    arr.forEach((key) => {
      if (!this.state[key]) {
        error[key] = "This filed is required";
      } else {
        error[key] = "";
      }
      this.setState({ error });
    });
  };

  submitNewLead = () => {
    let {
      hash_id,
      customer_name,
      customer_phone,
      customer_address,
      euipment_name,
      equipment_available,
      euipment_category,
      finance_type,
      other_details,
      error,
      customer_address_state,
      customer_address_city,
      equipment_available_state,
      equipment_available_city,
      usr_equipment_act_id,
      hmc_equipment_act_id,
      customer_id,
      creator_hash_id,
      on_behalf,
      lead_type,
    } = this.state;

    if (!customer_phone) {
      error.customer_phone = "Enter Valid Mobile Number";
      this.setState({ error });
      return;
    }

    this.requiredField();

    if (this.isValidForm(error)) {
      const formData = {
        hash_id: on_behalf ? creator_hash_id : hash_id,
        customer_name,
        customer_phone,
        customer_address,
        euipment_name,
        equipment_available,
        euipment_category,
        finance_type,
        other_details,
        medium: "web",
        section_name: "lm_add_lead",
        customer_address_state,
        customer_address_city,
        equipment_available_state,
        equipment_available_city,
        usr_equipment_act_id,
        hmc_equipment_act_id,
        customer_id,
        on_behalf,
        user_hash_id: hash_id,
        lead_type,
      };
      this.setState({ isLoading: true });
      axios
        .post("/add-lead", formData, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.status === "1") {
            message.success(res.data.message);
            this.setState({
              phone: "",
              customer_phone: "",
              customer_name: "",
              customer_address: "",
              customer_id: "",
              euipment_name: "",
              equipment_available: "",
              euipment_category: "1",
              finance_type: "2",
              other_details: "",
              customer_address_state: "",
              customer_address_city: "",
              equipment_available_state: "",
              equipment_available_city: "",
              usr_equipment_act_id: "",
              hmc_equipment_act_id: "",
              lead_type: "",

              error: {},
              temp_euipment_name: "",
              brand_hash_id: "",
              add_lead: true,
              on_behalf: false,
              creator_hash_id: "",
              select_creator_value: {},
            });
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.error(err);
          message.error("Something went wrong!!");
        });
    }
  };

  render() {
    let { error, country_code_list } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.add_lead;
    } else {
      language = {};
    }

    let country_loading = this.props.country_code.loading;
    let country_code = this.props.country_code.country_code_list;

    if (!country_loading && country_code) {
      country_code_list = country_code.country_code_list.map((val) => {
        return val.code;
      });
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                {/* TYPE MODEL NAME */}
                <CustomPopup
                  title={language.enter_your_equipment_name_h}
                  show={this.state.popupType}
                  onClose={this.popUpClose}
                  isCenter={true}
                >
                  <Form.Control
                    name="temp_euipment_name"
                    type="text"
                    placeholder={language.type_own_equipment_name_plh}
                    value={this.state.temp_euipment_name}
                    onChange={this.handleOnChange}
                  />
                  <p className="invalid_input">{error.temp_euipment_name}</p>
                  <div className="submit_lead">
                    <Button
                      type="button"
                      onClick={() => {
                        let { temp_euipment_name } = this.state;
                        if (temp_euipment_name) {
                          this.setState({ euipment_name: temp_euipment_name });
                          this.popUpClose();
                        } else {
                          error.temp_euipment_name =
                            language.this_filed_is_required_errormsg;
                          this.setState({ error });
                        }
                      }}
                    >
                      {language.submit_btn}
                    </Button>
                  </div>
                </CustomPopup>

                {/* SEARCH MODEL NAME */}
                <CustomPopup
                  title={language.search_from_list_h}
                  show={this.state.popupSearch}
                  onClose={this.popUpClose}
                  isCenter={true}
                >
                  <LoadingOverlay
                    active={this.state.isSearchLoading}
                    spinner
                    text="Loading ..."
                  >
                    {this.state.nextPage ? (
                      <>
                        <Form.Label>
                          {language.equipment_model_name_label}
                        </Form.Label>
                        <Select
                          options={this.state.filteredMachineTypeList}
                          onChange={(v) => {
                            this.handleSelect("machine_hash_id", v);
                          }}
                          value={null}
                          placeholder={language.select_model_name_plh}
                          name="machine_hash_id"
                          className="mb-5"
                        />
                        <div className="cancel_button">
                          <Button
                            type="button"
                            className="btn-dark"
                            onClick={() => {
                              this.setState({
                                nextPage: false,
                              });
                            }}
                          >
                            {language.cancel_btn}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Form.Label>
                          {language.equipment_brand_label}
                        </Form.Label>
                        <Select
                          options={this.state.allBrandList}
                          onChange={(v) => {
                            this.handleSelect("brand_hash_id", v);
                          }}
                          placeholder={language.select_equipment_brand_plh}
                          name="brand_hash_id"
                        />
                        <div className="submit_lead">
                          <Button
                            type="button"
                            onClick={() => {
                              this.getFilteredMachineModelList();
                              this.setState({ nextPage: true });
                            }}
                            disabled={!this.state.brand_hash_id}
                          >
                            {language.search_btn}
                          </Button>
                        </div>

                        <Form.Label>
                          {language.equipment_model_name_label}
                        </Form.Label>
                        <Select
                          options={this.state.allMachineModelList}
                          onChange={(v) => {
                            this.handleSelect("machine_hash_id", v);
                          }}
                          placeholder={language.select_model_name_plh}
                          name="machine_hash_id"
                          className="mb-5"
                        />
                      </>
                    )}
                  </LoadingOverlay>
                </CustomPopup>

                {/* OWN MODEL NAME */}
                <CustomPopup
                  title={language.select_from_own_equipment_list_h}
                  show={this.state.popupOwn}
                  onClose={this.popUpClose}
                  isCenter={true}
                >
                  <Form.Label>
                    {language.select_from_own_equipment_list_label}
                  </Form.Label>
                  <Select
                    options={this.state.ownEquipmentList}
                    onChange={(v) => {
                      this.handleSelect("own_equipment", v);
                    }}
                    placeholder={language.select_your_equipment_plh}
                    name="own_equipment"
                    className="mb-5"
                  />
                </CustomPopup>

                <ExitPopup
                  show={this.state.add_lead}
                  message={language.lead_added_successfully_title}
                  add_title={language.add_another_lead_title}
                  back_title={language.back_my_lead_title}
                  add={() => {
                    this.setState({ add_lead: false });
                  }}
                  back={() => {
                    this.props.history.push({
                      pathname: "/all-leads",
                      state: {
                        tabkey: "mylead",
                      },
                    });
                  }}
                />

                <Col lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <Row>
                      <Col lg="9" md="9" sm="12">
                        <div className="request_history">
                          <h2>{language.add_lead_h}</h2>

                          <Form.Group>
                            <Form.Label>
                              {language.customer_phone_number}
                              <span className="required">*</span>
                            </Form.Label>
                            {country_code_list.length !== 0 && (
                              <PhoneInput
                                className="phone_field"
                                defaultCountry={
                                  localStorage.getItem("country") || "IN"
                                }
                                placeholder={language.customer_phone_number_plh}
                                countries={country_code_list}
                                value={this.state.customer_phone}
                                onChange={this.handlePhoneChange}
                                required
                                addInternationalOption={false}
                                onKeyDown={(e) => {
                                  if (
                                    error.customer_phone !== undefined &&
                                    !error.customer_phone
                                  ) {
                                    if (e.key === "Enter" || e.key === "Tab") {
                                      this.getCustomerDetails();
                                    }
                                  }
                                }}
                              />
                            )}
                            <Form.Text className="text-muted">
                              *{language.type_hone_number_hit_enter}
                            </Form.Text>
                            <p className="invalid_input">
                              {error.customer_phone &&
                                language.enter_valid_mobile_number_errormsg}
                            </p>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              {language.customer_name_label}
                              <span className="required">*</span>
                            </Form.Label>
                            <Form.Control
                              name="customer_name"
                              type="text"
                              className="control_field"
                              placeholder={language.enter_customer_name_plh}
                              value={this.state.customer_name}
                              onChange={this.handleOnChange}
                              validaterule={["required"]}
                              validatemsg={["This filed is required"]}
                              required
                            />
                            <p className="invalid_input">
                              {error.customer_name &&
                                language.this_filed_is_required_errormsg}
                            </p>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              {language.select_lead_type_label}
                              <span className="required">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="lead_type"
                              className="control_field"
                              placeholder={language.select_lead_type_plh}
                              value={this.state.lead_type}
                              onChange={this.handleOnChange}
                              validaterule={["required"]}
                              validatemsg={["This filed is required"]}
                              required
                            >
                              <option value="">
                                {language.select_lead_type_plh}
                              </option>
                              <option value="1">{language.customer}</option>
                              <option value="2">{language.dealer}</option>
                              <option value="3">{language.financer}</option>
                            </Form.Control>

                            <p className="invalid_input">
                              {error.lead_type &&
                                language.this_filed_is_required_errormsg}
                            </p>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              {language.customer_address_label}
                              <span className="required">*</span>
                            </Form.Label>
                            <AutoCompleteLocation
                              handleGetLocation={this.handleGetLocation}
                              value={this.state.customer_address}
                            />
                            <p className="invalid_input">
                              {error.customer_address &&
                                language.this_filed_is_required_errormsg}
                            </p>
                          </Form.Group>

                          <Form.Group className="radio_group">
                            <Form.Check
                              type="radio"
                              name="euipment_category"
                              label={language.resale_equipment_option}
                              value="0"
                              id="1"
                              className="radio_check"
                              onChange={this.handleOnChange}
                              checked={this.state.euipment_category === "0"}
                            />
                            <Form.Check
                              type="radio"
                              name="euipment_category"
                              label={language.new_equipment_option}
                              value="1"
                              id="2"
                              className="radio_check"
                              onChange={this.handleOnChange}
                              checked={this.state.euipment_category === "1"}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              {language.required_equipment_label}
                              {/* <span className="required">*</span> */}
                            </Form.Label>
                            <div className="eqp_name_fild">
                              <Form.Control
                                name="euipment_name"
                                type="text"
                                className="control_field"
                                placeholder={language.enter_equipment_name_plh}
                                value={this.state.euipment_name}
                                required
                                readOnly
                                onClick={() => {
                                  error.euipment_name = "";
                                  this.setState({
                                    isEuipmentName: !this.state.isEuipmentName,
                                    error,
                                  });
                                }}
                              />
                              <span className="eqp_lead_icon">
                                <img src={Search_Lead_Img} alt="HMC" />
                              </span>
                            </div>
                            {this.state.isEuipmentName && (
                              <div className="eq_names">
                                <div className="eqp_name_fild">
                                  <p
                                    onClick={() => {
                                      this.setState({
                                        popupType: true,
                                        isEuipmentName: false,
                                      });
                                    }}
                                  >
                                    {language.type_own_equipment_name_plh}
                                  </p>
                                  <span className="eqp_lead_icon">
                                    <img src={Lead_Upload_Img} />
                                  </span>
                                </div>
                                <hr />
                                <div className="eqp_name_fild">
                                  <p
                                    onClick={() => {
                                      this.setState({
                                        popupSearch: true,
                                        isEuipmentName: false,
                                      });
                                    }}
                                  >
                                    {language.search_from_list_plh}
                                  </p>
                                  <span className="eqp_lead_icon">
                                    <img src={Lead_Upload_Img} />
                                  </span>
                                </div>
                                <hr />
                                <div className="eqp_name_fild">
                                  <p
                                    onClick={() => {
                                      this.setState({
                                        popupOwn: true,
                                        isEuipmentName: false,
                                      });
                                    }}
                                  >
                                    {
                                      language.select_from_own_equipment_list_label
                                    }
                                  </p>
                                  <span className="eqp_lead_icon">
                                    <img src={Lead_Upload_Img} />
                                  </span>
                                </div>
                              </div>
                            )}

                            <p className="invalid_input">
                              {error.euipment_name}
                            </p>
                          </Form.Group>

                          {this.state.euipment_category === "0" && (
                            <Form.Group>
                              <Form.Label>
                                {
                                  language.required_equipment_preferable_location_label
                                }
                                {/* <span className="required">*</span> */}
                              </Form.Label>
                              <AutoCompleteLocation
                                handleGetLocation={this.handleEqupmentLocation}
                                value={this.state.equipment_available}
                              />
                              <p className="invalid_input">
                                {error.equipment_available}
                              </p>
                            </Form.Group>
                          )}

                          {/* <Form.Group>
                            <Form.Label>Finance Type</Form.Label>
                            <Form.Control
                              as="select"
                              className="control_field"
                              name="finance_type"
                              value={this.state.finance_type}
                              onChange={this.handleOnChange}
                            >
                              <option selected value="">
                                Select Finance Type
                              </option>
                              <option value="1">Re Finance</option>
                              <option value="2">Normal Finance</option>
                            </Form.Control>
                          </Form.Group> */}

                          <Form.Group>
                            <Form.Label>
                              {language.other_information_label}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder={language.type_here_plh}
                              style={{ height: "150px" }}
                              name="other_details"
                              className="information_area"
                              value={this.state.other_details}
                              onChange={this.handleOnChange}
                            />
                          </Form.Group>

                          {(localStorage.getItem("lead_user_role") === "1" ||
                            localStorage.getItem("lead_user_role") === "5") && (
                            <Form.Group>
                              <div className="on_behalf">
                                <Form.Control
                                  type="checkbox"
                                  id="on_behalf"
                                  name="on_behalf"
                                  checked={this.state.on_behalf}
                                  onChange={() => {
                                    this.setState({
                                      on_behalf: !this.state.on_behalf,
                                    });
                                  }}
                                />
                                <Form.Label htmlFor="on_behalf">
                                  {language.on_behalf_of}
                                </Form.Label>
                              </div>
                              {this.state.on_behalf && (
                                <Select
                                  options={this.state.user_list}
                                  onChange={(value) => {
                                    this.handleSelect("creator_hash_id", value);
                                    this.setState({
                                      select_creator_value: value,
                                    });
                                  }}
                                  value={this.state.select_creator_value}
                                  name="creator_hash_id"
                                  placeholder="Select Creator Name"
                                />
                              )}
                            </Form.Group>
                          )}

                          <div className="submit_lead">
                            <Button type="submit" onClick={this.submitNewLead}>
                              {language.submit_btn}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(AddLead);
