import React, { Component } from "react";
import "./Footer.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  logo_img,
  instagram,
  youtube,
  facebook,
  twitter,
  footer_location,
  footer_call,
  playstore,
  ios_store,
  chat,
  footer_email,
  footer_whatsapp,
  twitter_icon,
} from "../../assets/images";
import { Link } from "react-router-dom";

export default class FooterNew extends Component {
  render() {
    return (
      <section className="footer_new">
        <Container fluid>
          <div className="footer_inner_new">
            <Row>
              <Col lg="3" md="6" sm="12">
                <div className="footer_logotext">
                  <img alt="HMC Logo" src={logo_img} />
                  <p>
                    HMC helps you connect with equipment buyers and sellers,
                    renters, service providers, parts dealers, operators,
                    financing companies, industry experts, etc.
                  </p>
                  <div className="social_icons">
                    <a
                      href="https://www.instagram.com/heavymachinerycare/"
                      className="mr-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Instagram" src={instagram} />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC2OMPGScQSbsRkzFgxsGQ_g"
                      className="mr-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="YouTube" src={youtube} />
                    </a>
                    <a
                      href="https://www.facebook.com/heavymachinerycare/"
                      className="mr-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Facebook" src={facebook} />
                    </a>
                    <a
                      href="https://twitter.com/hmctweett"
                      className="mr-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Twitter" src={twitter} />
                    </a>
                  </div>
                </div>
              </Col>

              <Col lg="7" md="6" sm="12">
                <div className="footer_item">
                  <h5>CONTACT</h5>
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <h4 className="address_label">India</h4>
                      <div className="cont_text_part">
                        <div className="cont_text_icon">
                          <img
                            width={20}
                            alt="location"
                            src={footer_location}
                          />
                        </div>
                        <div className="cont_inner_text">
                          <p>
                            <span>Address:</span> No 613 , 6th Floor, PS QUBE
                            Major Arterial Road Rajarhat, kolkata - 700156{" "}
                          </p>
                        </div>
                      </div>
                      <div className="cont_text_part">
                        <div className="cont_text_icon">
                          <img width={20} alt="email" src={footer_email} />
                        </div>
                        <div className="cont_inner_text">
                          <p>
                            <span>Email:</span>{" "}
                            <a href="mailto:support@heavymachinerycare.com">
                              support@heavymachinerycare.com{" "}
                            </a>
                          </p>
                        </div>
                      </div>
                      <Row className="contact_item">
                        <Col lg="6" md="6" sm="12">
                          <div className="cont_text_part">
                            <div className="cont_text_icon">
                              <img width={20} alt="Phone" src={footer_call} />
                            </div>
                            <div className="cont_inner_text">
                              <p>
                                <span>Phone:</span>
                                <a
                                  href="tel:(+91) 9065340638"
                                  className="custom-link"
                                >
                                  (+91) 9065340638
                                </a>
                                <a
                                  href="tel:(+91) 8100495470"
                                  className="custom-link"
                                >
                                  (+91) 8100495470
                                </a>
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                          <div className="cont_text_part">
                            <div className="cont_text_icon">
                              <img
                                width={20}
                                alt="WhatsApp"
                                src={footer_whatsapp}
                              />
                            </div>
                            <div className="cont_inner_text">
                              <p>
                                <span>WhatsApp:</span>
                                <a
                                  href="https://wa.me/917439195289"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  (+91) 7439195289
                                </a>
                                <a
                                  href="https://wa.me/918100495470"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  (+91) 8100495470
                                </a>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <h4 className="address_label">Kingdom of Saudi Arabia</h4>
                      <div className="cont_text_part">
                        <div className="cont_text_icon">
                          <img
                            width={20}
                            alt="Location"
                            src={footer_location}
                          />
                        </div>
                        <div className="cont_inner_text">
                          <p>
                            <span>Address:</span> Riyadh - 2698, Sahel Bin Saad,
                            Olaya District, 7951{" "}
                          </p>
                        </div>
                      </div>
                      <div className="cont_text_part">
                        <div className="cont_text_icon">
                          <img width={20} alt="email" src={footer_email} />
                        </div>
                        <div className="cont_inner_text">
                          <p>
                            <span>Email:</span>{" "}
                            <a href="mailto:supportksa@heavymachinerycare.com">
                              supportksa@heavymachinerycare.com{" "}
                            </a>
                          </p>
                        </div>
                      </div>
                      <Row className="contact_item">
                        <Col lg="6" md="6" sm="12">
                          <div className="cont_text_part">
                            <div className="cont_text_icon">
                              <img
                                width={20}
                                alt="twitter"
                                src={twitter_icon}
                              />
                            </div>
                            <div className="cont_inner_text">
                              <p>
                                <span>Twitter:</span>
                                <a
                                  href="https://twitter.com/hmc__sa?s=11&t=lc_sL2Y6P4VnpzIOD5Ggcg"
                                  className="custom-link"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  @HMC_SA
                                </a>
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                          <div className="cont_text_part">
                            <div className="cont_text_icon">
                              <img
                                width={20}
                                alt="whatsapp"
                                src={footer_whatsapp}
                              />
                            </div>
                            <div className="cont_inner_text">
                              <p>
                                <span>WhatsApp:</span>
                                <a
                                  href="https://wa.me/+9660597937024"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  +966 059 793 7024
                                </a>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg="2" md="6" sm="12">
                <div className="footer_item">
                  <h5>Download App</h5>
                  <div className="foter_play">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wgt.hmc"
                      target="_blank"
                      className="mr-2"
                      rel="noreferrer"
                    >
                      <img alt="Play Store" src={playstore} />
                    </a>
                    <br />
                    <br />
                    <a
                      href="https://apps.apple.com/in/app/heavy-machinery-care/id1582959119"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="iOS Store" src={ios_store} />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="footer_bottom">
          <Container fluid>
            <div className="footer_bottom_cont">
              <div className="copyright">
                &copy; {new Date().getFullYear()} HMC
              </div>
              <div className="privacy_y">
                <a href="https://blog.heavymachinerycare.com/" target="_blank">
                  Blog
                </a>
                {" | "}
                <Link to="/privacy-policy">Privacy Policy</Link>
                {" | "}
                <Link to="/terms-conditions">Terms & Conditions</Link>
                {" | "}
                <Link to="/return-refund-cancellation-policy">
                  Return Refund Cancellation Policy
                </Link>
                {" | "}
                <Link to="/disclaimer">Disclaimer</Link>
                {" | "}
                <Link to="/intellectual-property">Intellectual property</Link>
              </div>
            </div>
          </Container>
        </div>
        <div className="footer_chat">
          <a
            href="https://wa.me/+917439195289"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="chat" src={chat} />
          </a>
        </div>
      </section>
    );
  }
}
