import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { StickyContainer, Sticky } from "react-sticky";
import {
  equipment_search,
  list_view,
  star_img,
  map_view,
  prof_icon_menu,
} from "../../assets/images";
import "./serviceprovider.css";
import LeftPanelPage from "../leftpanel/LeftPanelPage";
import axios from "../../config/axios";
import message from "../../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import config from "../../config/apiHeader";
import AutoCompleteLocation from "../../utils/AutoCompleteLocation";
import CustomSlider from "../../utils/CustomSlider";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { connect } from "react-redux";
import Select from "react-select";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class ServiceProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      isLoading: false,

      profiles: [],
      total: 0,
      currentPage: 1,
      per_page: 10,
      last_page: 0,

      skills: [],
      preferred_area: [],

      //   ADVANCE FILTER
      service_type: "",

      location: "",
      name: "",
      lat: "",
      long: "",
      km: "",
      expertise: "",
      education: "",

      filter: {
        isApplyFilter: false,
        service_type: "",

        name: "",
        lat: "",
        long: "",
        km: "",
        expertise: [],
        education: "",
      },
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    this.getAllService();
    this.getSkills();
    this.getPreferredArea();
  };

  getAllService = () => {
    let { hash_id, service_type, currentPage } = this.state;

    const formData = {
      hash_id,
      service_type,
      page: currentPage,
      medium: "web",
      section_name: "service_provider_get_all_service",
    };

    this.setState({ isLoading: true });

    axios
      .post("/service-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { profiles, total, last_page } = res.data.data;
          this.setState({
            profiles: this.state.profiles.concat(profiles),
            total,
            last_page,
            currentPage: currentPage + 1,
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getSkills = () => {
    axios
      .get("/skills", config)
      .then((res) => {
        if (res.data.status === "1") {
          let { skills } = res.data.data;
          let data = skills.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          this.setState({
            skills: data,
          });
        }
      })
      .catch((err) => {
        console.log("Something went wrong!", err);
      });
  };

  getPreferredArea = () => {
    axios
      .get("/preferred-area", config)
      .then((res) => {
        if (res.data.status === "1") {
          let { preferred_area } = res.data.data;
          let data = preferred_area.map((item) => {
            return {
              label: item.preferred_area,
              value: item.id,
            };
          });
          this.setState({
            preferred_area: data,
          });
        }
      })
      .catch((err) => {
        console.log("Something went wrong!!!", err);
      });
  };

  handleOnChange = async (e) => {
    let { name, value } = e.target;
    let { filter } = this.state;

    filter[name] = value;
    filter.isApplyFilter = false;

    this.setState({ filter });
  };

  handleGetLocation = (value) => {
    if (value) {
      let { label, lat, long } = value;
      this.setState({
        location: label,
        long,
        lat,
      });
    }
  };

  onChangeSelect = (key, value) => {
    console.log("value", value);
  };

  applyFilter = () => {
    let { filter } = this.state;

    if (filter.isApplyFilter) {
      // CLEAR FILTER
      this.clearFilter();
      return;
    }
    const entries = Object.entries(filter);
    for (let [key, value] of entries) {
      if (key === "expertise") {
        this.setState({ [key]: value.toString() });
      } else {
        this.setState({ [key]: value });
      }
    }

    filter.isApplyFilter = true;

    this.setState({ filter, currentPage: 1, profiles: [] }, () => {
      this.getAllService();
    });
  };

  render() {
    let { profiles, total, service_type, skills, filter, preferred_area } =
      this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.service_providers;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="buy_rent_part">
                    <StickyContainer>
                      <Row>
                        {/* SERVICE RESULT */}
                        <Col lg="9" md="8" sm="12">
                          <div className="dashboard_right_panel">
                            <h2>{language.service_providers_h}</h2>
                            <div className="rent_result_top">
                              <h2>
                                {language.pagination_showing_results_label}{" "}
                                {total}
                              </h2>
                              <div className="buy_search_box">
                                <div className="search_box_rent">
                                  <Form.Control
                                    type="text"
                                    className="search_textbox"
                                    placeholder="Search for.."
                                  />
                                  <span className="eqp_search_icon">
                                    <img alt="HMC" src={equipment_search} />
                                  </span>
                                </div>
                                <div className="buy_rent_viewbox">
                                  <span>
                                    <img alt="List View" src={list_view} />
                                  </span>
                                  <span className="active_box">
                                    <img alt="Map View" src={map_view} />
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="individual_partts">
                              {profiles.map((profile, key) => {
                                return (
                                  <div
                                    className="result_inner service_prove"
                                    key={key}
                                  >
                                    <div className="service_prov_left">
                                      <img
                                        src={
                                          profile.img_url
                                            ? MEDIA_BASE_URL + profile.img_url
                                            : prof_icon_menu
                                        }
                                        alt={profile.business_name}
                                      />
                                    </div>
                                    <div className="service_prov_right">
                                      <div className="service_prv_right_top">
                                        <h2>{profile.business_name}</h2>
                                        <div className="location_partts">
                                          <i className="fa fa-map-marker" />{" "}
                                          {profile.city}, {profile.state}
                                        </div>
                                      </div>
                                      <div className="service_prv_right_bottom">
                                        <div className="rating_service">
                                          <label>{language.rating_label}</label>
                                          <div className="result_star">
                                            <img alt="HMC" src={star_img} />
                                            {profile.service_rating}
                                          </div>
                                        </div>

                                        <div className="rating_service">
                                          <label>
                                            {language.preferred_area}
                                          </label>
                                          <h4>
                                            {(profile.preferred_area &&
                                              profile.preferred_area.split(
                                                ","
                                              )[0]) ||
                                              profile.state}
                                          </h4>
                                        </div>

                                        <div className="rating_service">
                                          <label>{language.service_type}</label>
                                          <h4>{profile.skill}</h4>
                                        </div>
                                        {/* <div className="rating_service">
                                          <a
                                            href="#"
                                            className="rating_connect"
                                          >
                                            Connect
                                          </a>
                                        </div> */}
                                      </div>
                                    </div>
                                    <div className="individual_tag">
                                      {profile.service_type === "1"
                                        ? language.individual
                                        : language.workshop}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="showing_result">
                              <p>
                                {language.pagination_showing_results_label}{" "}
                                {Math.min(
                                  total,
                                  (this.state.currentPage - 1) *
                                    this.state.per_page
                                )}{" "}
                                {language.pagination_out_of_label} {total}
                              </p>
                              <Button
                                className="submit_button commn_button"
                                type="submit"
                                disabled={
                                  this.state.currentPage - 1 >=
                                  this.state.last_page
                                }
                                onClick={this.getAllService}
                              >
                                {language.view_more_btn}
                              </Button>
                            </div>
                          </div>
                        </Col>
                        {/* FILTER */}
                        <Col lg="3" md="4" sm="12">
                          <Sticky>
                            {({ style }) => (
                              <div style={style} className="filter_partts">
                                <div className="filter_heading">
                                  <span>{language.filter}</span>
                                </div>
                                <div className="eqp_added">
                                  <h3>{language.filter_type_of_service}</h3>

                                  <div className="eqp_added_radiopart mb-3">
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={
                                        language.filter_type_of_service_option_all
                                      }
                                      type="radio"
                                      id="all"
                                      name="service_type"
                                      checked={service_type === ""}
                                      value=""
                                    />
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={
                                        language.filter_type_of_service_option_individual
                                      }
                                      type="radio"
                                      id="individual"
                                      name="service_type"
                                      value={1}
                                      checked={service_type === "1"}
                                    />

                                    <Form.Check
                                      inline
                                      label={
                                        language.filter_type_of_service_option_workshop
                                      }
                                      type="radio"
                                      id="workshop"
                                      name="service_type"
                                      value={2}
                                      checked={service_type === "2"}
                                      onChange={this.handleOnChange}
                                    />
                                  </div>

                                  <AutoCompleteLocation
                                    handleGetLocation={this.handleGetLocation}
                                    value={this.state.location}
                                  />
                                  <div className="range_rent_slider mt-3">
                                    <label>
                                      {language.filter_select_radius}
                                    </label>
                                    <div className="hmr_value">
                                      {this.state.km || 0}-100KM
                                    </div>
                                    <CustomSlider
                                      max={100}
                                      value={this.state.km}
                                      handleSlider={(km) => {
                                        this.setState({ km });
                                      }}
                                    />

                                    <Select
                                      className="mt-2"
                                      closeMenuOnSelect={false}
                                      // defaultValue={props.defaultTag}
                                      isMulti
                                      options={skills}
                                      placeholder={language.filter_expertise}
                                      onChange={(value) => {
                                        this.onChangeSelect("skills", value);
                                      }}
                                    />

                                    <Select
                                      className="mt-2"
                                      closeMenuOnSelect={false}
                                      // defaultValue={props.defaultTag}
                                      isMulti
                                      options={preferred_area}
                                      placeholder={
                                        language.filter_preferred_service_area
                                      }
                                      onChange={(value) => {
                                        this.onChangeSelect("skills", value);
                                      }}
                                    />
                                  </div>

                                  <div className="range_rent_slider mt-3">
                                    <div className="submit_button_rent mt-3">
                                      <Button
                                        onClick={this.applyFilter}
                                        type="submit"
                                      >
                                        {filter.isApplyFilter
                                          ? language.clear_btn
                                          : language.apply_btn}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Sticky>
                        </Col>
                      </Row>
                    </StickyContainer>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(ServiceProvider);
