import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./editlead.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import axios from "../../../config/axios";
import AutoCompleteLocation from "../../../utils/AutoCompleteLocation";
import CustomPopup from "../../../utils/CustomPopup";
import message from "../../../utils/messages";
import {
  Search_Lead_Img,
  Lead_Upload_Img,
  Search_Icon_Lead_Img,
  word_preview,
  pdf_preview,
} from "../../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import validation from "../../../components/Validate/validator";
import MultiSelectTags from "../MultiSelectTags";
import _ from "lodash";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { LeadTypes } from "../../../utils/LeadTypes";
import config from "../../../config/apiHeader";
import { DatePicker } from "antd";
import { getFileExtension } from "../../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language, country_code } = state;
  return { language, country_code };
};

class EditLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isLoading: false,
      hash_id: "",
      lead_hash_id: this.props.match.params.lead_hash_id,

      country_code_list: [],
      allBrandList: [],
      allMachineModelList: [],
      filteredMachineTypeList: [],
      ownEquipmentList: [],
      all_tags_list_status_wise: [],
      all_status: [],
      phone: "",
      customer_phone: "",
      customer_name: "",
      customer_address: "",
      lead_id: "",
      customer_id: "",
      euipment_name: "",
      equipment_available: "",
      euipment_category: "",
      finance_type: "",
      other_details: "",
      customer_address_state: "",
      customer_address_city: "",
      equipment_available_state: "",
      equipment_available_city: "",
      usr_equipment_act_id: "",
      hmc_equipment_act_id: "",
      insurance: "",
      is_invoice_present: "",
      is_rc_present: "",
      tags: [],
      manufacturing_year: "",
      hmr: "",
      country_code: [],
      alternate_phone_number: [],
      quantity: "1",
      price: "",
      tax_detail: "",
      last_reading: "",
      selected_tags: [],
      selected_status: {},
      old_attachment: [],
      old_images: [],
      old_docs: [],
      equipment_images: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      name_of_financer: "",
      old_loan_financer: "",
      status_of_finance: "",
      need_to_downpayment: "",
      usr_eligible_loan_amt: "",
      asking_loan_amt: "",
      expected_closing_date: "",
      alternate_phone: "",
      alternate_phone_country: "",
      current_loan_amt: "",
      approved_loan_amt: "",

      lead_type: "",

      error: {},
      isEuipmentName: false,
      popupType: false,
      popupSearch: false,
      popupOwn: false,
      temp_euipment_name: "",
      brand_hash_id: "",
      isSearchLoading: false,
      nextPage: false,

      available_equipment_infomation: [],
      available_machine_name: "",
      available_purchase_year: "",
      isAvailableMachine: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
    if (sub_menus) {
      this.getLeadDetails();
      this.getAllCountryList();
      this.getAllBrandList();
      this.getAllMachineModelList();
      this.getAllTagList();
      this.getAllSectionList();
    } else {
      message.error(
        "Access Denied: Your account does not have sufficient permissions to perform the requested action."
      );
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  getLeadDetails = () => {
    this.setState({ isLoading: true });

    let { lead_hash_id } = this.state;

    const formData = {
      lead_hash_id,
      medium: "web",
      section_name: "lm_get_lead_details",
    };

    axios
      .post("/get-lead-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { lead_details } = res.data.data;

          if (!lead_details) {
            message.warning("No Lead details found");
            return;
          }

          let selected_tags = [];
          let tags = [];
          lead_details.all_tags.map((tag) => {
            return [
              selected_tags.push({
                label: tag.tag_title,
                value: tag.user_tag_id,
                color: tag.tag_back_color,
                text_color: tag.tag_title_color,
              }),
              tags.push(tag.user_tag_id),
            ];
          });

          if (lead_details.current_status_name) {
            let selected_status = {
              label: lead_details.current_status_name,
              value: parseInt(lead_details.current_status),
            };
            this.setState({ selected_status });
          }

          Object.keys(lead_details).forEach(async (key) => {
            if (key !== "hash_id" && key !== "all_tags") {
              const val = lead_details[key];
              if (key === "id") await this.setState({ lead_id: val });
              else if (key === "all_document") {
                let old_attachment = [];
                let old_images = [];
                let old_docs = [];

                val.forEach((doc) => {
                  old_attachment.push(doc.id);
                  if (doc.attachment_type === "1") {
                    old_images.push({
                      url: doc.attachment_url,
                      id: doc.id,
                    });
                  } else {
                    let url = doc.attachment_url;
                    let preview;

                    if (url.endsWith(".doc") || url.endsWith(".docx")) {
                      preview = word_preview;
                    } else {
                      preview = pdf_preview;
                    }

                    old_docs.push({
                      url,
                      id: doc.id,
                      name: doc.attachment_title,
                      preview,
                    });
                  }
                });
                await this.setState({ old_attachment, old_images, old_docs });
              } else if (key === "alternate_phone_number") {
                let country_code = [];
                let alternate_phone_number = [];
                val.forEach((phone) => {
                  country_code.push(phone.country_code);
                  alternate_phone_number.push(phone.phone_no);
                });
                await this.setState({ alternate_phone_number, country_code });
              } else await this.setState({ [key]: val ? val : "" });
            }
          });
          this.setState({ selected_tags, tags });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
        message.error("Something went wrong!!");
      });
  };

  getAllBrandList = () => {
    let { hash_id } = this.state;

    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
      call_from: "0",
    };

    axios
      .get("brand-list?medium=web?section_name=lm_edit_get_all_brand", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allBrandList = [];
          res.data.data.brand.map((brand) => {
            return allBrandList.push({
              value: brand.brand_hash_id,
              label: brand.brand_name,
            });
          });
          this.setState({ allBrandList });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllMachineModelList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_edit_get_all_model",
    };

    axios
      .post("/machine-model-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let allMachineModelList = [];
          res.data.data.machines.map((machine) => {
            return allMachineModelList.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
            });
          });
          this.setState({ allMachineModelList });
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
        console.log(err);
      });
  };

  getAllTagList = () => {
    let { hash_id } = this.state;

    const formData = {
      hash_id,
      medium: "web",
      section_name: "lm_edit_get_all_tags",
    };

    axios
      .post("/all-tag-list-status-wise", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_tags_list_status_wise = [];
          res.data.data.map((tag) => {
            return all_tags_list_status_wise.push({
              label: tag.tag_title,
              value: tag.id,
              color: tag.tag_back_color,
              text_color: tag.tag_title_color,
            });
          });
          this.setState({ all_tags_list_status_wise });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong!!");
      });
  };

  getAllSectionList = () => {
    const formData = {
      medium: "web",
      section_name: this.props.location.state.section_name,
    };

    axios
      .post("/get-section-status", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_status = res.data.data.all_status.map((status) => {
            return {
              label: status.status_name,
              value: status.id,
            };
          });
          this.setState({ all_status });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong!!");
      });
  };

  handleGetLocation = (value) => {
    let { error } = this.state;
    if (value) {
      error.customer_address = "";
      this.setState({
        customer_address: value.label,
        customer_address_state: value.state,
        customer_address_city: value.city,
        error,
      });
    }
  };

  handleEqupmentLocation = (value) => {
    let { error } = this.state;
    if (value) {
      error.equipment_available = "";
      this.setState({
        equipment_available: value.label,
        equipment_available_state: value.state,
        equipment_available_city: value.city,
        error,
      });
    }
  };

  popUpClose = () => {
    this.setState({
      popupOwn: false,
      popupSearch: false,
      popupType: false,
      temp_euipment_name: "",
      nextPage: false,
    });
  };

  handleSelect = (key, v) => {
    this.setState({ [key]: v.value });
    if (key === "machine_hash_id") {
      this.setState({ euipment_name: v.label });
      this.popUpClose();
      return;
    }
    if (key === "own_equipment") {
      this.setState({
        usr_equipment_act_id: v.equipment.machine_activity_id,
        euipment_name: v.equipment.model_name,
        hmc_equipment_act_id: v.equipment.machine_id,
      });
      this.popUpClose();
      return;
    }

    if (key === "current_status") {
      this.setState({ selected_status: v, current_status_name: v.label });
      return;
    }
  };

  getFilteredMachineModelList = () => {
    this.setState({ isSearchLoading: true });
    let { brand_hash_id } = this.state;
    const formData = {
      brand_hash_id,
      medium: "web",
      section_name: "lm_filtered_all_model",
    };

    axios
      .post("/machine-model-list", formData, config)
      .then((res) => {
        this.setState({ isSearchLoading: false });
        if (res.data.status === "1") {
          let filteredMachineTypeList = [];
          res.data.data.machines.map((machine) => {
            return filteredMachineTypeList.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
            });
          });
          this.setState({ filteredMachineTypeList });
        }
      })
      .catch((err) => {
        this.setState({ isSearchLoading: false });
        message.error("Something went wrong!!");
        console.log(err);
      });
  };

  handleOnChange = (e) => {
    e.persist();
    let { error } = this.state;
    let { name, value } = e.target;

    if (
      name === "price" ||
      name === "need_to_downpayment" ||
      name === "usr_eligible_loan_amt" ||
      name === "asking_loan_amt" ||
      name === "current_loan_amt" ||
      name === "approved_loan_amt"
    ) {
      this.setState({ [name]: value.replace(/\D/g, "") });
      return;
    }

    this.setState({
      [name]: value,
    });

    if (name === "euipment_category") {
      this.setState({ finance_type: value === "1" ? "2" : "1" });
    }

    var valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  handlePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      this.setState({ customer_phone: value });

      let number = parsePhoneNumber(value);
      if (number) {
        let phone = number.nationalNumber;
        this.setState({ phone });
      }
      if (isValidPhoneNumber(value)) {
        error.customer_phone = "";
      } else {
        error.customer_phone = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error.customer_phone = "Enter Valid Mobile Number";
      this.setState({ error, phone: "" });
    }
  };

  getCustomerDetails = () => {
    this.setState({ isLoading: true });

    let { phone } = this.state;

    const formData = {
      phone,
      medium: "web",
      section_name: "lm_edit_get_customer_details",
    };

    axios
      .post("/get-customer-details-for-lead", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { customer_details } = res.data.data;
          if (customer_details.length > 0) {
            let customer_id = customer_details[0].id;
            this.getOwnEquipments(customer_id);
            let customer_name = customer_details[0].full_name;
            let customer_address = customer_details[0].address;
            let customer_address_state = customer_details[0].state;
            let customer_address_city = customer_details[0].city;
            this.setState({
              customer_id,
              customer_name,
              customer_address,
              customer_address_state,
              customer_address_city,
            });
          } else {
            message.error("No customer details found");
            this.setState({
              customer_id: "",
              customer_name: "",
              customer_address: "",
              customer_address_state: "",
              customer_address_city: "",
            });
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getOwnEquipments = (user_id) => {
    const formData = {
      user_id,
      medium: "web",
      section_name: "lm_own_equipments",
    };

    axios
      .post("/get-own-equipment-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let ownEquipmentList = [];
          res.data.data.map((equipment) => {
            return ownEquipmentList.push({
              value: equipment.machine_activity_id,
              label: equipment.model_name + ", hmr(" + equipment.hmr + ")",
              equipment,
            });
          });
          this.setState({ ownEquipmentList });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong!!");
      });
  };

  tagChanges = (value) => {
    let tags = [];
    let selected_tags = [];
    value.map((tag) => {
      return [tags.push(tag.value), selected_tags.push(tag)];
    });

    this.setState({ tags, selected_tags });
  };

  handleImages = async (e) => {
    e.persist();

    let { equipment_images } = this.state;

    let files = e.target.files;
    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      let validExtension = ["png", "jpg", "jpeg"];
      if (file !== undefined) {
        let extension = this.getExtension(file).toLowerCase();
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          equipment_images.push(file);
        } else {
          message.error(file.name + " is not a valid image");
        }
      }
      await this.setState({ equipment_images });
    });
  };

  getExtension = (file) => {
    return file.name.split(".")[1];
  };

  deleteImage = async (pos) => {
    let { equipment_images } = this.state;
    equipment_images.splice(pos, 1);
    await this.setState({ equipment_images });
  };

  handleDocument = async (e) => {
    e.persist();

    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    let files = e.target.files;

    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      let validExtension = ["doc", "docx", "pdf"];
      if (file !== undefined) {
        let extension = getFileExtension(file);

        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          let preview;
          if (extension === "docx" || extension === "doc") {
            preview = word_preview;
          } else if (extension === "pdf") {
            preview = pdf_preview;
          }
          equipment_docs.push(file);

          equipment_docs_preview.push({ name: file.name, preview });
          attachment_title.push(file.name);

          this.setState({
            equipment_docs,
            equipment_docs_preview,
          });
        } else {
          message.error(file.name + " is not a valid document");
        }
      }
    });
  };

  deleteDoc = async (pos) => {
    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    equipment_docs.splice(pos, 1);
    equipment_docs_preview.splice(pos, 1);
    attachment_title.splice(pos, 1);

    await this.setState({
      equipment_docs,
      equipment_docs_preview,
      attachment_title,
    });
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  requiredField = () => {
    const arr = [
      "customer_name",
      "customer_address",

      // "euipment_name",
      // "equipment_available",
    ];
    let { error } = this.state;

    arr.map((key) => {
      if (!this.state[key]) {
        error[key] = "This filed is required";
      } else {
        error[key] = "";
      }
      this.setState({ error });
    });
  };

  updateLead = () => {
    let {
      customer_name,
      customer_phone,
      customer_address,
      euipment_name,
      equipment_available,
      euipment_category,
      finance_type,
      other_details,
      error,
      customer_address_state,
      customer_address_city,
      equipment_available_state,
      equipment_available_city,
      usr_equipment_act_id,
      hmc_equipment_act_id,
      manufacturing_year,
      lead_id,
      alternate_phone_number,
      country_code,
      quantity,
      price,
      tax_detail,
      last_reading,
      insurance,
      is_invoice_present,
      is_rc_present,
      tags,
      customer_id,
      name_of_financer,
      old_loan_financer,
      status_of_finance,
      need_to_downpayment,
      usr_eligible_loan_amt,
      asking_loan_amt,
      expected_closing_date,
      current_status,
      current_status_name,
      current_loan_amt,
      approved_loan_amt,
      hmr,
      lead_type,
    } = this.state;

    if (!customer_phone) {
      error.customer_phone = "Enter Valid Mobile Number";
      this.setState({ error });
      return;
    }

    this.requiredField();

    if (this.isValidForm(error)) {
      const formData = {
        lead_id,
        customer_name,
        customer_phone,
        customer_address,
        euipment_name,
        equipment_available,
        euipment_category,
        finance_type,
        other_details,
        country_code,
        alternate_phone_number,
        quantity,
        manufacturing_year,
        price,
        tax_detail,
        last_reading,
        insurance,
        is_invoice_present,
        is_rc_present,
        tags,
        customer_address_state,
        customer_address_city,
        equipment_available_state,
        equipment_available_city,
        usr_equipment_act_id,
        hmc_equipment_act_id,
        customer_id,
        medium: "web",
        section_name: "lm_edit_lead_details",
        name_of_financer,
        old_loan_financer,
        status_of_finance,
        need_to_downpayment,
        usr_eligible_loan_amt,
        asking_loan_amt,
        expected_closing_date,
        current_status,
        current_status_name,
        current_loan_amt,
        approved_loan_amt,
        hmr,
        lead_type,
      };

      this.setState({ isLoading: true });
      axios
        .post("/lead-update", formData, config)
        .then((res) => {
          if (res.data.status === "1") {
            message.success(res.data.message);
            this.setState({
              page: 1,
            });
            // this.uploadImage();
            // this.uploadDocument();
            this.callBacktoParent();
          } else {
            this.setState({ isLoading: false });
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
          message.error("Something went wrong!!");
        });
    }
  };

  uploadImage = () => {
    let { equipment_images, lead_id, old_attachment } = this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    formData.append("attachment_title", []);
    formData.append("attachment_type", 1);
    formData.append("medium", "web");
    formData.append("section_name", "lm_edit_upload_lead_image");
    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    equipment_images.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    axios
      .post("lead-document-upload", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ equipment_images: [] });
        } else {
          message.error(res.data.message);
        }

        this.uploadDocument();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
        message.error("Something went wrong!!");
      });
  };

  uploadDocument = () => {
    let { lead_id, equipment_docs, old_attachment, attachment_title } =
      this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    attachment_title.map((title, key) => {
      formData.append(`attachment_title[${key}]`, title);
    });
    formData.append("attachment_type", 2);
    formData.append("medium", "web");
    formData.append("section_name", "lm_edit_upload_lead_document");
    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    equipment_docs.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    axios
      .post("lead-document-upload", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({
            equipment_docs: [],
            equipment_docs_preview: [],
            attachment_title: [],
          });
        } else {
          message.error(res.data.message);
        }

        // this.getLeadDetails();
        this.callBacktoParent();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
        message.error("Something went wrong!!");
      });
  };

  deleteOldImage = async (id, pos) => {
    let { old_attachment, old_images } = this.state;
    old_images.splice(pos, 1);

    let index = old_attachment.indexOf(id);
    old_attachment.splice(index, 1);

    await this.setState({ old_images, old_attachment });
  };

  deleteOldDoc = async (id, pos) => {
    let { old_attachment, old_docs } = this.state;
    old_docs.splice(pos, 1);

    let index = old_attachment.indexOf(id);
    old_attachment.splice(index, 1);

    await this.setState({ old_docs, old_attachment });
  };

  handleAlternatePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      this.setState({ alternate_phone: value });

      let number = parsePhoneNumber(value);
      if (number) {
        let alternate_phone_country = "+" + number.countryCallingCode;
        this.setState({ alternate_phone_country });
      }

      if (isValidPhoneNumber(value)) {
        error.alternate_phone = "";
      } else {
        error.alternate_phone = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error.alternate_phone = "";
      this.setState({ error });
    }
  };

  callBacktoParent = () => {
    switch (this.props.location.state.lead_types) {
      case LeadTypes.MY_LEAD:
        this.props.history.push({
          pathname: "/all-leads",
          state: {
            tabkey: "mylead",
            formData: this.props.location.state?.formData,
          },
        });
        break;

      case LeadTypes.MY_ASSIGN_LEAD:
        this.props.history.goBack();
        break;
      case LeadTypes.PRE_ASSIGN_LEAD:
        this.props.history.push({
          pathname: "/all-leads",
          state: {
            tabkey: "preassigned",
          },
        });
        break;
      case LeadTypes.REVIEW_LEAD:
        this.props.history.push({
          pathname: "/all-leads",
          state: {
            tabkey: "review",
          },
        });
        break;
      default:
        message.error("Lead Type Not Found");
        break;
    }
  };

  render() {
    let { error, country_code_list } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.update_lead;
    } else {
      language = {};
    }

    let country_loading = this.props.country_code.loading;
    let country_code = this.props.country_code.country_code_list;

    if (!country_loading && country_code) {
      country_code_list = country_code.country_code_list.map((val) => {
        return val.code;
      });
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                {/* TYPE MODEL NAME */}
                <CustomPopup
                  title={language.enter_your_equipment_name_label}
                  show={this.state.popupType}
                  onClose={this.popUpClose}
                  isCenter={true}
                >
                  <Form.Control
                    name="temp_euipment_name"
                    type="text"
                    placeholder={language.type_your_own_equipment_name_plh}
                    value={this.state.temp_euipment_name}
                    onChange={this.handleOnChange}
                  />
                  <p className="invalid_input">{error.temp_euipment_name}</p>
                  <div className="submit_lead">
                    <Button
                      type="button"
                      onClick={() => {
                        let { temp_euipment_name } = this.state;
                        if (temp_euipment_name) {
                          this.setState({ euipment_name: temp_euipment_name });
                          this.popUpClose();
                        } else {
                          error.temp_euipment_name =
                            language.this_filed_is_required_errormsg;
                          this.setState({ error });
                        }
                      }}
                    >
                      {language.submit_btn}
                    </Button>
                  </div>
                </CustomPopup>

                {/* SEARCH MODEL NAME */}
                <CustomPopup
                  title={language.search_from_list_h}
                  show={this.state.popupSearch}
                  onClose={this.popUpClose}
                  isCenter={true}
                >
                  <LoadingOverlay
                    active={this.state.isSearchLoading}
                    spinner
                    text="Loading ..."
                  >
                    {this.state.nextPage ? (
                      <>
                        <Form.Label>
                          {language.equipment_model_name_label}
                        </Form.Label>
                        <Select
                          options={this.state.filteredMachineTypeList}
                          onChange={(v) => {
                            if (this.state.isAvailableMachine) {
                              this.setState({
                                available_machine_name: v.label,
                              });
                              this.popUpClose();
                            } else this.handleSelect("machine_hash_id", v);
                          }}
                          value={null}
                          placeholder={language.select_model_name_plh}
                          name="machine_hash_id"
                          className="mb-5"
                        />
                        <div className="cancel_button">
                          <Button
                            type="button"
                            className="btn-dark"
                            onClick={() => {
                              this.setState({
                                nextPage: false,
                              });
                            }}
                          >
                            {language.cancel_btn}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Form.Label>
                          {language.equipment_brand_label}
                        </Form.Label>
                        <Select
                          options={this.state.allBrandList}
                          onChange={(v) => {
                            this.handleSelect("brand_hash_id", v);
                          }}
                          placeholder={language.select_equipment_brand_plh}
                          name="brand_hash_id"
                        />
                        <div className="submit_lead">
                          <Button
                            type="button"
                            onClick={() => {
                              this.getFilteredMachineModelList();
                              this.setState({ nextPage: true });
                            }}
                            disabled={!this.state.brand_hash_id}
                          >
                            {language.search_btn}
                          </Button>
                        </div>

                        <Form.Label>
                          {language.equipment_model_name_label}
                        </Form.Label>
                        <Select
                          options={this.state.allMachineModelList}
                          onChange={(v) => {
                            if (this.state.isAvailableMachine) {
                              this.setState({
                                available_machine_name: v.label,
                              });
                              this.popUpClose();
                            } else this.handleSelect("machine_hash_id", v);
                          }}
                          placeholder={language.select_model_name_plh}
                          name="machine_hash_id"
                          className="mb-5"
                        />
                      </>
                    )}
                  </LoadingOverlay>
                </CustomPopup>

                {/* OWN MODEL NAME */}
                <CustomPopup
                  title={language.select_from_own_equipment_list_h}
                  show={this.state.popupOwn}
                  onClose={this.popUpClose}
                  isCenter={true}
                >
                  <Form.Label>
                    {language.select_from_own_equipment_list_label}
                  </Form.Label>
                  <Select
                    options={this.state.ownEquipmentList}
                    onChange={(v) => {
                      this.handleSelect("own_equipment", v);
                    }}
                    placeholder={language.select_your_equipment_plh}
                    name="own_equipment"
                    className="mb-5"
                  />
                </CustomPopup>

                <Col lg="9" md="8" sm="12">
                  <Row>
                    <Col lg="9" md="12" sm="12" className="lead_resuuultts">
                      {this.state.page === 1 ? (
                        <div className="update_lead">
                          <h2>{language.update_lead_h}</h2>

                          <Form.Group>
                            <Form.Label>
                              {language.customer_phone_number}
                              <span className="required">*</span>
                            </Form.Label>
                            {country_code_list.length !== 0 && (
                              <PhoneInput
                                className="phone_field phone_field121"
                                defaultCountry={
                                  localStorage.getItem("country") || "IN"
                                }
                                placeholder={language.customer_phone_number_plh}
                                countries={country_code_list}
                                value={this.state.customer_phone}
                                onChange={this.handlePhoneChange}
                                required
                                addInternationalOption={false}
                                onKeyDown={(e) => {
                                  if (
                                    error.customer_phone !== undefined &&
                                    !error.customer_phone
                                  ) {
                                    if (e.key === "Enter" || e.key === "Tab") {
                                      this.getCustomerDetails();
                                    }
                                  }
                                }}
                                readOnly
                              />
                            )}
                            <Form.Text className="text-muted">
                              *{language.type_hone_number_hit_enter}
                            </Form.Text>
                            <p className="invalid_input">
                              {error.customer_phone &&
                                language.enter_valid_mobile_number_errormsg}
                            </p>
                          </Form.Group>

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {language.customer_name_label}
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                name="customer_name"
                                type="text"
                                className="control_field update_field"
                                placeholder="Type Name"
                                required
                                value={this.state.customer_name}
                                onChange={this.handleOnChange}
                                validaterule={["required"]}
                                validatemsg={["This filed is required"]}
                              />
                              <p className="invalid_input">
                                {error.customer_name &&
                                  language.this_filed_is_required_errormsg}
                              </p>
                            </Form.Group>
                          </div>

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {language.select_lead_type_label}
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="lead_type"
                                className="control_field"
                                placeholder={language.select_lead_type_plh}
                                value={this.state.lead_type}
                                onChange={this.handleOnChange}
                                validaterule={["required"]}
                                validatemsg={["This filed is required"]}
                                required
                              >
                                <option value="">
                                  {language.select_lead_type_plh}
                                </option>
                                <option value="1">{language.customer}</option>
                                <option value="2">{language.dealer}</option>
                                <option value="3">{language.financer}</option>
                              </Form.Control>

                              <p className="invalid_input">
                                {error.lead_type &&
                                  language.this_filed_is_required_errormsg}
                              </p>
                            </Form.Group>
                          </div>

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {language.customer_address_label}
                              </Form.Label>
                              <AutoCompleteLocation
                                handleGetLocation={this.handleGetLocation}
                                value={this.state.customer_address}
                              />
                              <p className="invalid_input">
                                {error.customer_address &&
                                  language.this_filed_is_required_errormsg}
                              </p>
                            </Form.Group>
                          </div>

                          <Form.Group className="radio_group">
                            <Form.Check
                              type="radio"
                              name="euipment_category"
                              label={language.resale_equipment_option}
                              value="0"
                              id="1"
                              checked={this.state.euipment_category === "0"}
                              className="radio_check"
                              onChange={this.handleOnChange}
                            />
                            <Form.Check
                              type="radio"
                              name="euipment_category"
                              label={language.new_equipment_option}
                              value="1"
                              id="2"
                              checked={this.state.euipment_category === "1"}
                              className="radio_check"
                              onChange={this.handleOnChange}
                            />
                          </Form.Group>

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {language.required_equipment_label}
                              </Form.Label>
                              <div className="eqp_name_fild">
                                <Form.Control
                                  name="euipment_name"
                                  type="text"
                                  className="control_field update_field"
                                  placeholder={
                                    language.enter_equipment_name_plh
                                  }
                                  required
                                  readOnly
                                  value={this.state.euipment_name}
                                  onClick={() => {
                                    error.euipment_name = "";
                                    this.setState({
                                      isEuipmentName:
                                        !this.state.isEuipmentName,
                                      error,
                                    });
                                  }}
                                />
                                <span className="eqp_lead_icon">
                                  <img src={Search_Lead_Img} />
                                </span>
                              </div>
                              {this.state.isEuipmentName && (
                                <div className="eq_names">
                                  <div className="eqp_name_fild">
                                    <p
                                      onClick={() => {
                                        this.setState({
                                          popupType: true,
                                          isEuipmentName: false,
                                        });
                                      }}
                                    >
                                      {language.type_own_equipment_name_plh}
                                    </p>
                                    <span className="eqp_lead_icon">
                                      <img src={Lead_Upload_Img} />
                                    </span>
                                  </div>
                                  <hr />
                                  <div className="eqp_name_fild">
                                    <p
                                      onClick={() => {
                                        this.setState({
                                          popupSearch: true,
                                          isEuipmentName: false,
                                        });
                                      }}
                                    >
                                      {language.search_from_list_tab}
                                    </p>
                                    <span className="eqp_lead_icon">
                                      <img src={Lead_Upload_Img} />
                                    </span>
                                  </div>
                                  <hr />
                                  <div className="eqp_name_fild">
                                    <p
                                      onClick={() => {
                                        this.setState({
                                          popupOwn: true,
                                          isEuipmentName: false,
                                        });
                                      }}
                                    >
                                      {
                                        language.select_from_own_equipment_list_label
                                      }
                                    </p>
                                    <span className="eqp_lead_icon">
                                      <img src={Lead_Upload_Img} />
                                    </span>
                                  </div>
                                </div>
                              )}

                              <p className="invalid_input">
                                {error.euipment_name}
                              </p>
                            </Form.Group>
                          </div>

                          {this.state.euipment_category === "0" && (
                            <>
                              <div className="cust_update_lead">
                                <Form.Group>
                                  <Form.Label>
                                    {
                                      language.required_equipment_preferable_location_label
                                    }
                                  </Form.Label>

                                  <AutoCompleteLocation
                                    handleGetLocation={
                                      this.handleEqupmentLocation
                                    }
                                    value={this.state.equipment_available}
                                  />
                                  <p className="invalid_input">
                                    {error.equipment_available &&
                                      language.this_filed_is_required_errormsg}
                                  </p>
                                </Form.Group>
                              </div>

                              <div className="cust_update_lead">
                                <Form.Group>
                                  <Form.Label>
                                    {language.current_financer_name_label}
                                  </Form.Label>

                                  <Form.Control
                                    name="name_of_financer"
                                    type="text"
                                    className="control_field update_field"
                                    placeholder={
                                      language.type_current_financer_name_plh
                                    }
                                    value={this.state.name_of_financer}
                                    onChange={this.handleOnChange}
                                  />
                                </Form.Group>
                              </div>

                              <div className="cust_update_lead">
                                <Form.Group>
                                  <Form.Label>
                                    {language.status_of_the_financer_label}
                                  </Form.Label>

                                  <Form.Control
                                    name="status_of_finance"
                                    type="text"
                                    className="control_field update_field"
                                    placeholder={language.type_status_plh}
                                    value={this.state.status_of_finance}
                                    onChange={this.handleOnChange}
                                  />
                                </Form.Group>
                              </div>
                            </>
                          )}

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {language.other_information_label}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder={language.type_here_plh}
                                name="other_details"
                                className="lead_text_area"
                                value={this.state.other_details}
                                onChange={this.handleOnChange}
                              />
                            </Form.Group>
                          </div>

                          <div className="submit_lead">
                            <Button
                              type="submit"
                              onClick={() => {
                                this.setState({ page: 2 });
                              }}
                            >
                              {language.next_btn}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="update_lead">
                          <h2>{language.update_lead_h}</h2>

                          {/* <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>Add Equipment images</Form.Label>
                              <div className="upload_image_box">
                                {old_images &&
                                  old_images.map((image, key) => {
                                    return (
                                      <div className="image_box" key={key}>
                                        <img src={image.url} alt="HMC" />
                                        <span
                                          className="close_preview"
                                          onClick={() => {
                                            this.deleteOldImage(image.id, key);
                                          }}
                                        >
                                          <img src={cross_preview} alt="HMC" />
                                        </span>
                                      </div>
                                    );
                                  })}
                                {equipment_images &&
                                  equipment_images.map((preview, index) => {
                                    return (
                                      <div className="image_box" key={index}>
                                        <img
                                          src={URL.createObjectURL(preview)}
                                          alt="HMC"
                                        />
                                        <span
                                          className="close_preview"
                                          onClick={() => {
                                            this.deleteImage(index);
                                          }}
                                        >
                                          <img src={cross_preview} alt="HMC" />
                                        </span>
                                      </div>
                                    );
                                  })}

                                <div className="upload_box">
                                  <div className="upload_btn_wrappererer">
                                    <button className="btnner">
                                      <img src={Upload_img_icon_img} />
                                    </button>

                                    <Form.Control
                                      type="file"
                                      accept="image/png, image/jpg, image/jpeg"
                                      name="images"
                                      multiple
                                      onChange={this.handleImages}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div> */}

                          {/*TODO:NOTCOMPLETE <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>Add Equipment Video</Form.Label>
                              <div className="upload_image_box">
                                <div className="image_box">
                                  <img src={Upload_img} />

                                  <span
                                    className="close_preview"
                                    // onClick={() => {
                                    //   this.deleteImage(index);
                                    // }}
                                  >
                                    <img src={cross_preview} alt="HMC" />
                                  </span>
                                  <div className="video_play">
                                    <img src={Video_Play_Img} />
                                  </div>
                                </div>
                                <div className="upload_box">
                                  <div className="upload_btn_wrappererer">
                                    <button className="btnner">
                                      <img src={Video_Img_Img} />
                                    </button>
                                    <input type="file" name="myfile" />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div> 

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>Add Document</Form.Label>
                              <div className="cert_box">
                                {old_docs &&
                                  old_docs.map((doc, index) => {
                                    return (
                                      <div
                                        className="cert_box_inner"
                                        key={index}
                                      >
                                        <div className="cret_file">
                                          <img src={doc.preview} alt="HMC" />
                                        </div>
                                        <Form.Label>{doc.name}</Form.Label>
                                        <div className="click_drop">
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                            >
                                              <img src={Click_Drop_Img} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <span className="prev_imggg">
                                                  <img src={Preview_Img} />
                                                </span>
                                                Preview
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <span className="prev_imggg">
                                                  <img src={Prev_2_Img} />
                                                </span>
                                                Rename
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <span className="prev_imggg">
                                                  <img src={Prev_3_Img} />
                                                </span>
                                                Download
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.deleteOldDoc(
                                                    doc.id,
                                                    index
                                                  );
                                                }}
                                                className="drop_item"
                                              >
                                                <span className="prev_imggg">
                                                  <img src={tag_delte} />
                                                </span>
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {equipment_docs_preview &&
                                  equipment_docs_preview.map((doc, index) => {
                                    return (
                                      <div
                                        className="cert_box_inner"
                                        key={index}
                                      >
                                        <div className="cret_file">
                                          <img src={doc.preview} alt="HMC" />
                                        </div>
                                        <Form.Label>{doc.name}</Form.Label>
                                        <div className="click_drop">
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                            >
                                              <img src={Click_Drop_Img} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <span className="prev_imggg">
                                                  <img src={Preview_Img} />
                                                </span>
                                                Preview
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <span className="prev_imggg">
                                                  <img src={Prev_2_Img} />
                                                </span>
                                                Rename
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <span className="prev_imggg">
                                                  <img src={Prev_3_Img} />
                                                </span>
                                                Download
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.deleteDoc(index);
                                                }}
                                                className="drop_item"
                                              >
                                                <span className="prev_imggg">
                                                  <img src={tag_delte} />
                                                </span>
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    );
                                  })}

                                <div className="cert_box_upload">
                                  <div className="upload_btn_wrappererer">
                                    <button className="btnner">
                                      <img src={Up_doc_icon_img} />
                                    </button>
                                    <Form.Control
                                      type="file"
                                      accept=".doc, .docx, .pdf"
                                      onChange={this.handleDocument}
                                      multiple
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>

*/}

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {language.alternative_phone_number}
                              </Form.Label>
                              <div className="eqp_name_fild">
                                {this.state.country_code_list.length !== 0 && (
                                  <PhoneInput
                                    className="phone_field phone_field121"
                                    defaultCountry={
                                      localStorage.getItem("country") || "IN"
                                    }
                                    placeholder={language.type_phone_number_plh}
                                    countries={this.state.country_code_list}
                                    value={this.state.alternate_phone}
                                    onChange={this.handleAlternatePhoneChange}
                                    addInternationalOption={false}
                                  />
                                )}
                                <span
                                  className="alternate_phone_add"
                                  onClick={() => {
                                    let {
                                      alternate_phone_number,
                                      alternate_phone,
                                      alternate_phone_country,
                                      country_code,
                                    } = this.state;

                                    if (
                                      !error.alternate_phone &&
                                      alternate_phone.length >= 7
                                    ) {
                                      alternate_phone_number.push(
                                        alternate_phone
                                      );
                                      country_code.push(
                                        alternate_phone_country
                                      );
                                      this.setState({
                                        alternate_phone: "",
                                        alternate_phone_country: "",
                                        alternate_phone_number,
                                        country_code,
                                      });
                                    }
                                  }}
                                >
                                  <img src={Search_Icon_Lead_Img} />
                                </span>
                              </div>
                              <p className="invalid_input">
                                {error.alternate_phone}
                              </p>
                            </Form.Group>
                          </div>

                          <div className="tag_name">
                            <ul>
                              {this.state.alternate_phone_number.map(
                                (value, key) => {
                                  return (
                                    <li key={key}>
                                      {value}
                                      <span
                                        onClick={() => {
                                          let {
                                            alternate_phone_number,
                                            country_code,
                                          } = this.state;
                                          alternate_phone_number.splice(key, 1);
                                          country_code.splice(key, 1);
                                          this.setState({
                                            alternate_phone_number,
                                            country_code,
                                          });
                                        }}
                                      >
                                        X
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>

                          {this.state.euipment_category === "0" && (
                            <>
                              <div className="cust_update_lead">
                                <Form.Group>
                                  <Form.Label>
                                    {language.manufacture_year_equipment_label}
                                  </Form.Label>
                                  <ReactDatePicker
                                    selected={
                                      this.state.manufacturing_year !== "" &&
                                      new Date(this.state.manufacturing_year)
                                    }
                                    className="control_field update_field"
                                    dateFormat="yyyy"
                                    onChange={(date) => {
                                      let manufacturing_year =
                                        moment(date).format("YYYY");
                                      let hmr =
                                        1000 *
                                        (new Date().getFullYear() -
                                          manufacturing_year);

                                      this.setState({
                                        manufacturing_year,
                                        hmr,
                                      });
                                    }}
                                    placeholderText={
                                      language.select_anufacture_year_equipment_plh
                                    }
                                    maxDate={new Date()}
                                    showYearPicker={true}
                                  />
                                </Form.Group>
                              </div>

                              <div className="cust_update_lead">
                                <Form.Group>
                                  <Form.Label>{language.hmr_label}</Form.Label>
                                  <Form.Control
                                    name="hmr"
                                    type="text"
                                    className="control_field update_field"
                                    placeholder={language.hmr_plh}
                                    value={this.state.hmr}
                                    readOnly
                                  />
                                </Form.Group>
                              </div>

                              <div className="cust_update_lead">
                                <Form.Group>
                                  <Form.Label>
                                    {language.equipment_price_approx_label}
                                  </Form.Label>
                                  <Form.Control
                                    name="price"
                                    type="text"
                                    className="control_field update_field"
                                    placeholder={
                                      language.enter_equipment_price_plh
                                    }
                                    value={this.state.price}
                                    onChange={this.handleOnChange}
                                  />
                                </Form.Group>
                              </div>

                              <Form.Group>
                                <Form.Label>
                                  {language.tax_details_label}
                                </Form.Label>
                                <Form.Control
                                  name="tax_detail"
                                  type="text"
                                  className="control_field update_field"
                                  placeholder={language.enter_tax_details_plh}
                                  value={this.state.tax_detail}
                                  onChange={this.handleOnChange}
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>
                                  {language.last_reading_label}
                                </Form.Label>

                                <Form.Control
                                  name="last_reading"
                                  type="text"
                                  className="control_field update_field"
                                  placeholder={
                                    language.enter_reading_details_plh
                                  }
                                  value={this.state.last_reading}
                                  onChange={this.handleOnChange}
                                />
                              </Form.Group>

                              <div className="ins_status">
                                <div className="ins_sta_left">
                                  <Form.Label>
                                    {language.insurance_status_valid_upto}
                                  </Form.Label>
                                </div>
                                <div className="ins_sta_right">
                                  <Row>
                                    <Col lg="6" md="6" sm="12">
                                      <Form.Group className="edit_radio_group">
                                        <Form.Check
                                          type="radio"
                                          name="insurance"
                                          label={language.valid}
                                          value="1"
                                          id="4"
                                          checked={this.state.insurance === "1"}
                                          className="radio_check"
                                          onChange={this.handleOnChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                      <Form.Group className="edit_radio_group">
                                        <Form.Check
                                          type="radio"
                                          name="insurance"
                                          label={language.invalid}
                                          value="0"
                                          id="3"
                                          checked={this.state.insurance === "0"}
                                          className="radio_check"
                                          onChange={this.handleOnChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </div>

                              <div className="ins_status">
                                <div className="ins_sta_left">
                                  <Form.Label>
                                    {language.invoice_status_valid_upto}
                                  </Form.Label>
                                </div>
                                <div className="ins_sta_right">
                                  <Row>
                                    <Col lg="6" md="6" sm="12">
                                      <Form.Group className="edit_radio_group">
                                        <Form.Check
                                          type="radio"
                                          name="is_invoice_present"
                                          label={language.yes}
                                          value="1"
                                          id="6"
                                          checked={
                                            this.state.is_invoice_present ===
                                            "1"
                                          }
                                          className="radio_check"
                                          onChange={this.handleOnChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                      <Form.Group className="edit_radio_group">
                                        <Form.Check
                                          type="radio"
                                          name="is_invoice_present"
                                          label={language.no}
                                          value="0"
                                          id="5"
                                          checked={
                                            this.state.is_invoice_present ===
                                            "0"
                                          }
                                          className="radio_check"
                                          onChange={this.handleOnChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </div>

                              <div className="ins_status">
                                <div className="ins_sta_left">
                                  <Form.Label>
                                    {language.rc_status_valid_upto}
                                  </Form.Label>
                                </div>
                                <div className="ins_sta_right">
                                  <Row>
                                    <Col lg="6" md="6" sm="12">
                                      <Form.Group className="edit_radio_group">
                                        <Form.Check
                                          type="radio"
                                          name="is_rc_present"
                                          label={language.yes}
                                          value="1"
                                          id="8"
                                          checked={
                                            this.state.is_rc_present === "1"
                                          }
                                          className="radio_check"
                                          onChange={this.handleOnChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                      <Form.Group className="edit_radio_group">
                                        <Form.Check
                                          type="radio"
                                          name="is_rc_present"
                                          label={language.no}
                                          value="0"
                                          id="7"
                                          checked={
                                            this.state.is_rc_present === "0"
                                          }
                                          className="radio_check"
                                          onChange={this.handleOnChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>{language.tags_label}</Form.Label>
                              <MultiSelectTags
                                tags={this.state.all_tags_list_status_wise}
                                onChange={this.tagChanges}
                                className="add_tags"
                                defaultTag={this.state.selected_tags}
                                placeholder={language.tags_plh}
                              />
                            </Form.Group>
                          </div>

                          <div className="cust_update_lead">
                            <Form.Group>
                              <Form.Label>
                                {
                                  language.user_available_equipment_infomation_label
                                }
                              </Form.Label>
                              <div className="eqp_name_fild">
                                <div className="search_form_part">
                                  <div className="search_frm_list">
                                    <p
                                      onClick={() => {
                                        this.setState({
                                          popupSearch: true,
                                          isAvailableMachine: true,
                                        });
                                      }}
                                    >
                                      {this.state.available_machine_name ||
                                        "Search From List"}
                                    </p>
                                  </div>
                                  <div className="search_man_year">
                                    <DatePicker
                                      className="date_picker"
                                      onChange={(date, year) => {
                                        this.setState({
                                          available_purchase_year: year,
                                        });
                                      }}
                                      picker="year"
                                      placeholder={
                                        language.select_manufacture_year_plh
                                      }
                                    />
                                  </div>
                                  <div
                                    className="plus_parttts"
                                    onClick={() => {
                                      let {
                                        available_equipment_infomation,
                                        available_machine_name,
                                        available_purchase_year,
                                      } = this.state;
                                      if (!available_machine_name) {
                                        message.warning(
                                          "Please select machine"
                                        );
                                        return;
                                      }
                                      if (!available_purchase_year) {
                                        message.warning("please select year");
                                        return;
                                      }

                                      available_equipment_infomation.push({
                                        name: available_machine_name,
                                        year: available_purchase_year,
                                      });

                                      this.setState({
                                        available_equipment_infomation,
                                        available_machine_name: "",
                                        available_purchase_year: "",
                                      });
                                    }}
                                  >
                                    <img src={Search_Icon_Lead_Img} />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="tag_name">
                            <ul>
                              {this.state.available_equipment_infomation.map(
                                (value, key) => {
                                  return (
                                    <li key={key}>
                                      {value.name + " , " + value.year}
                                      <span
                                        onClick={() => {
                                          let {
                                            available_equipment_infomation,
                                          } = this.state;
                                          available_equipment_infomation.splice(
                                            key,
                                            1
                                          );

                                          this.setState({
                                            available_equipment_infomation,
                                          });
                                        }}
                                      >
                                        X
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>

                          <div className="submit_lead update_lead_but">
                            <Button type="submit" onClick={this.updateLead}>
                              {language.update_btn}
                            </Button>
                            <Button
                              type="button"
                              onClick={() => {
                                this.setState({ page: 1 });
                              }}
                            >
                              {language.previous_btn}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(EditLead);
