import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import {
  Container,
  Row,
  Col,
  Form,
  Dropdown,
  Badge,
  Button,
} from "react-bootstrap";
import "./MyEquipments.css";
import { Link } from "react-router-dom";
import axios from "../../../config/axios";
import config from "../../../config/apiHeader";
import message from "../../../utils/messages";
import DeletePopup from "../../../utils/DeletePopup";
import CustomPopup from "../../../utils/CustomPopup";
import { MEDIA_BASE_URL } from "../../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class MyEquipments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      profile_hash_id: "",

      machine_activity: [],

      // DELETE EQUIPMENT
      isDelete: false,
      machine_activity_hash_id: "",

      // UNLISTED
      isUnlisted: false,
      status: "",
      reason: "",

      // SEARCH
      model_name: "",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let sellerProfile = JSON.parse(localStorage.getItem("sellerProfile"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    if (sellerProfile) {
      await this.setState({
        profile_hash_id: sellerProfile.profile_hash_id,
      });
    }
    this.getUserMchineList();
  };

  getUserMchineList = () => {
    this.setState({ isLoading: true });
    let { profile_hash_id, model_name } = this.state;
    const formData = {
      profile_hash_id,
      machine_profile_type: 2,
      noOfItem: 10,
      model_name,
    };
    axios
      .post("/get-user-machine-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { machine_activity } = res.data.data;
          if (machine_activity.length === 0) {
            message.warning(res.data.message);
            this.props.history.push({ pathname: "/add-equipment" });
          }
          this.setState({ machine_activity });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  handleEdit = (equipment) => {
    this.props.history.push({
      pathname: `edit-equipment/${equipment.machine_activity_hash_id}`,
      // state: { data: equipment, edit: true },
    });
  };

  deleteEquipment = () => {
    const formData = {
      machine_activity_hash_id: this.state.machine_activity_hash_id,
    };
    this.setState({ isLoading: true });
    axios
      .post("/machine-delete", formData, config)
      .then((res) => {
        this.setState({ isLoading: false, machine_activity_hash_id: "" });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.getUserMchineList();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, machine_activity_hash_id: "" });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  onClose = (isUnlisted) => {
    this.setState({
      isUnlisted,
      machine_activity_hash_id: "",
      status: "",
      reason: "",
    });
  };

  handleOnChange = (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "status") {
      this.setState({ reason: "" });
    }

    this.setState({ [name]: value });
  };

  unListEquipment = (e) => {
    e.preventDefault();
    let { status, machine_activity_hash_id, reason } = this.state;
    const formData = {
      machine_activity_hash_id,
      status,
      reason,
    };
    this.setState({ isLoading: true, isUnlisted: false });
    axios
      .post("/machine-status-update", formData, config)
      .then((res) => {
        this.setState({
          isLoading: false,
          machine_activity_hash_id: "",
          status: "",
          reason: "",
        });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.getUserMchineList();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          machine_activity_hash_id: "",
          status: "",
          reason: "",
        });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  formatLine = (n, str) => {
    const len = str.length;
    if (len > n) {
      const arr = str.split(" ");
      let s = arr[0] + " " + arr[arr.length - 1];
      let sLen = s.length;
      sLen += 4;

      s = arr[0] + " ";
      if (sLen < n) {
        let count = n - sLen;
        s += arr[1].substring(0, count);
      }
      s += "... ";
      s += arr[arr.length - 1];

      return s;
    }
    return str;
  };

  render() {
    let { machine_activity } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.my_equipments;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {/* DELETE EQUIPMENT */}
        <DeletePopup
          show={this.state.isDelete}
          message={language.do_you_really_want_to_delete_this_equipment}
          yes_msg={language.yes_btn}
          no_msg={language.no_btn}
          no={() => {
            this.setState({ isDelete: false, machine_activity_hash_id: "" });
          }}
          yes={() => {
            this.setState({ isDelete: false });
            this.deleteEquipment();
          }}
        />

        {/* UNLISTED EQUIPMENT*/}

        <CustomPopup
          show={this.state.isUnlisted}
          title={language.unlisted_your_equipment}
          onClose={this.onClose}
        >
          <Form onSubmit={this.unListEquipment}>
            <Form.Group>
              <Form.Check
                value="2"
                type="radio"
                aria-label="radio 1"
                label={language.don_want_to_list_my_equipment}
                onChange={this.handleOnChange}
                id="2"
                name="status"
                required
              />
              <Form.Check
                value="3"
                type="radio"
                aria-label="radio 2"
                label={language.sold}
                id="3"
                name="status"
                onChange={this.handleOnChange}
                required
              />
            </Form.Group>

            {this.state.status === "3" && (
              <>
                <p>{language.how_sold_equipment}</p>

                <Form.Group className="ml-5">
                  <Form.Check
                    value="1"
                    type="radio"
                    aria-label="radio 21"
                    label={language.via_hmc}
                    onChange={this.handleOnChange}
                    id="21"
                    // checked={this.state.reason === "1"}
                    name="reason"
                    required
                  />
                  <Form.Check
                    value="2"
                    type="radio"
                    aria-label="radio 22"
                    label={language.use_my_own_network}
                    onChange={this.handleOnChange}
                    id="22"
                    // checked={this.state.reason === "2"}
                    name="reason"
                    required
                  />
                  <Form.Check
                    value="3"
                    type="radio"
                    aria-label="radio 23"
                    label={language.other_website}
                    onChange={this.handleOnChange}
                    id="23"
                    // checked={this.state.reason === "3"}
                    name="reason"
                    required
                  />
                  <Form.Check
                    value="4"
                    type="radio"
                    aria-label="radio 24"
                    label={language.other_application}
                    onChange={this.handleOnChange}
                    id="24"
                    // checked={this.state.reason === "4"}
                    name="reason"
                    required
                  />
                </Form.Group>
              </>
            )}

            <Button type="submit" className="submit_button commn_button">
              {language.submit_btn}
            </Button>
          </Form>
        </CustomPopup>

        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <div className="ds_header">
                      <h2>{language.my_equipments_h}</h2>
                    </div>
                    <div className="rent_result_top">
                      <h2>{language.showing_results_for_my_listing}</h2>
                      <div className="buy_search_box">
                        <div className="search_box">
                          <Form.Control
                            type="text"
                            placeholder={language.type_model_name_plh}
                            name="model_name"
                            onChange={this.handleOnChange}
                          />
                          <span
                            className="sb_icon"
                            onClick={this.getUserMchineList}
                          >
                            <i className="fa fa-search" aria-hidden="true" />
                          </span>
                        </div>
                        {/* <div className="buy_rent_viewbox">
                          <span
                            className={
                              this.state.listType == 2
                                ? "map_view list_view"
                                : "map_view "
                            }
                            onClick={() => {
                              this.handleListType(2);
                            }}
                          >
                            <img src={List_view_img} />
                          </span>
                          <span
                            className={
                              this.state.listType == 4
                                ? "map_view list_view"
                                : "map_view "
                            }
                            onClick={() => {
                              this.handleListType(4);
                            }}
                          >
                            <img src={Grid_view_img} />
                          </span>
                        </div> */}
                      </div>
                    </div>

                    <Row className="my_equipments">
                      {machine_activity.map((machine, key) => {
                        return (
                          <Col md={3} sm={6} key={key}>
                            <div className="me_item mb-3">
                              {machine.is_deleted === "0" && (
                                <div className="me_action">
                                  <Dropdown drop="left">
                                    <Dropdown.Toggle>
                                      <i className="fa fa-ellipsis-v" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu size="sm" title="">
                                      <Dropdown.Item
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: `/edit-equipment/${machine.machine_activity_hash_id}`,
                                          });
                                        }}
                                      >
                                        {language.edit_option}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          this.setState({
                                            isDelete: true,
                                            machine_activity_hash_id:
                                              machine.machine_activity_hash_id,
                                          });
                                        }}
                                      >
                                        {language.delete_option}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          this.setState({
                                            isUnlisted: true,
                                            machine_activity_hash_id:
                                              machine.machine_activity_hash_id,
                                          });
                                        }}
                                      >
                                        {language.unlisted_option}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}
                              <div className="me_image">
                                <Link to="/#">
                                  <img
                                    src={
                                      MEDIA_BASE_URL +
                                      machine.machine_cover_image
                                    }
                                    alt="HMC"
                                  />
                                </Link>
                                <div className="me_logo">
                                  <img
                                    src={MEDIA_BASE_URL + machine.brand_logo}
                                    alt="HMC"
                                  />
                                </div>
                              </div>
                              <div className="me_info">
                                <div className="year_brand mb-3">
                                  <span>
                                    <label>{language.brand} :</label>{" "}
                                    {machine.brand_name}
                                  </span>
                                  <div>
                                    {machine.is_deleted === "3" ? (
                                      <Badge variant="success">
                                        {language.sold}
                                      </Badge>
                                    ) : machine.is_deleted === "2" ? (
                                      <Badge variant="success">
                                        {language.unlisted_badge}
                                      </Badge>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <h2 className="mb-0">
                                  <Link to="/#">
                                    {machine.model_name &&
                                      this.formatLine(24, machine.model_name)}
                                  </Link>
                                </h2>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(MyEquipments);
