import {
  LANGUAGE_FAILED,
  LANGUAGE_REQUEST,
  LANGUAGE_SUCCESS,
} from "../constant/LanguageConstants";

export const LanguageReducer = (state = {}, action) => {
  switch (action.type) {
    case LANGUAGE_REQUEST:
      return { loading: true };

    case LANGUAGE_SUCCESS:
      return { loading: false, language: action.payload };

    case LANGUAGE_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
