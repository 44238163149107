import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-multi-carousel/lib/styles.css";
import "./AllEquipments.css";
import {
  el_star_alt,
  el_star_alt_gray,
  help_icon,
  Sell,
  Rent,
  prof_icon_menu,
} from "../../assets/images";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { Container, Row, Col, Card } from "react-bootstrap";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import config from "../../config/apiHeader";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class EquipmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",

      machine_activity_hash_id: "",
      machine_hash_id: "",
      profile_hash_id: "",

      general_info: {},
      specification: [],
      relatedEquipments: [],
      images: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let { params } = this.props.match;
    if (params) {
      let { machine_hash_id, machine_activity_hash_id, profile_hash_id } =
        params;

      if ((machine_hash_id && machine_activity_hash_id, profile_hash_id)) {
        this.setState(
          { machine_hash_id, machine_activity_hash_id, profile_hash_id },
          () => {
            this.getEquipmentDetails();
            this.getAllRelatedEquipments();
          }
        );
      }
    }
  };

  getEquipmentDetails = () => {
    let { machine_activity_hash_id, machine_hash_id, profile_hash_id } =
      this.state;

    const formData = {
      sponsored: "0",
      machine_rent_type: "0",
      is_brand_new: "0",
      machine_hash_id,
      machine_sale_type: "1",
      machine_activity_hash_id,
      profile_hash_id,

      medium: "web",
      section_name: "equipment_details",
    };

    this.setState({ isLoading: true });

    axios
      .post("/machine-buy-details-v1", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { general_info, specification, image } = res.data.data;
          let images = image.length == 0 ? image : [general_info.cover_image];
          this.setState({ general_info, specification, images });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getAllRelatedEquipments = () => {
    let { machine_activity_hash_id, machine_hash_id } = this.state;
    axios
      .post(
        "/related-equipment-list",
        { machine_activity_hash_id, machine_hash_id },
        config
      )
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ relatedEquipments: res.data.data.owner_list });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 1,
      },
    };

    const responsive_two = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };

    let { general_info, specification, relatedEquipments, images } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.equipments_details;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <div className="sp_hero">
                      <Card className="hmc_card mb-4">
                        <Card.Body>
                          <Row>
                            <Col md={5} className="sp_image">
                              <div className="spb_logo">
                                <img
                                  className="rounded-circle"
                                  width={40}
                                  height={40}
                                  alt="HMC"
                                  src={MEDIA_BASE_URL + general_info.brand_logo}
                                />
                              </div>
                              <Carousel
                                responsive={responsive}
                                centerMode={false}
                                className="eq_slider"
                                arrows={false}
                                showDots={true}
                                autoPlay={false}
                                items={1}
                                draggable={true}
                                swipeable={true}
                              >
                                {images.map((cover_image, key) => {
                                  return (
                                    <div className="cs_item" key={key}>
                                      <LazyLoadImage
                                        alt={general_info.model_name}
                                        src={MEDIA_BASE_URL + cover_image}
                                        className="img-fluid w-100"
                                        effect="blur"
                                      />
                                    </div>
                                  );
                                })}
                              </Carousel>
                            </Col>
                            <Col md={7} className="sp_info">
                              <div className="sp_head">
                                <div className="sp_company">
                                  {general_info.brand_name}
                                </div>
                                <h2 className="sp_model">
                                  {general_info.model_name}
                                </h2>
                              </div>
                              <div className="sp_text">
                                <p className="mb-0">
                                  {general_info.short_description}
                                </p>
                              </div>

                              <Row className="sp_feature_list">
                                <Col className="col-4">
                                  <div className="sp_features d-flex">
                                    <div className="spf_icon">
                                      <img alt="HMC" src={Sell} />
                                    </div>
                                    <div className="spf_text">
                                      <dl>
                                        <dd>{language.resale}</dd>
                                        <dt>{general_info.resale_machine}</dt>
                                      </dl>
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-4">
                                  <div className="sp_features d-flex">
                                    <div className="spf_icon">
                                      <img alt="HMC" src={Rent} />
                                    </div>
                                    <div className="spf_text">
                                      <dl>
                                        <dd>{language.rent}</dd>
                                        <dt>{general_info.rent_machine}</dt>
                                      </dl>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="sp_int mb-4">
                      <Row>
                        <Col md={8}>
                          <Card className="hmc_card spc_tab">
                            <Tabs defaultActiveKey="specification">
                              <Tab
                                eventKey="specification"
                                title={language.specification}
                              >
                                <div className="tab_content">
                                  <Row className="spc_row">
                                    {specification.map((value, key) => {
                                      return (
                                        <Col md={3} sm={4} key={key}>
                                          <div className="spc_wrap">
                                            <div
                                              className="spc_card"
                                              style={{
                                                background:
                                                  "url(" +
                                                  MEDIA_BASE_URL +
                                                  value.field_logo +
                                                  ")",
                                              }}
                                            >
                                              <h4>{value.field_name}</h4>
                                              <p className="mb-0">
                                                {value.field_value}
                                              </p>
                                            </div>
                                          </div>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </div>
                              </Tab>
                            </Tabs>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="hmc_card seller_info">
                            <Card.Header>
                              <h4 className="card-title mb-0">
                                {language.seller_info}
                              </h4>
                            </Card.Header>
                            <Card.Body className="text-center">
                              <div className="seller_pd">
                                <img
                                  width={75}
                                  height={75}
                                  src={
                                    general_info.img_url
                                      ? MEDIA_BASE_URL + general_info.img_url
                                      : prof_icon_menu
                                  }
                                  className="rounded-circle"
                                  alt="HMC"
                                />
                                <h4 className="seller_name">
                                  {general_info.business_name}
                                </h4>
                                <div className="seller_location">
                                  {general_info.city}, {general_info.state}
                                </div>
                                <div className="seller_rating">
                                  <img
                                    width={14}
                                    height={14}
                                    src={el_star_alt}
                                    alt="HMC"
                                  />
                                  <img
                                    width={14}
                                    height={14}
                                    src={el_star_alt}
                                    alt="HMC"
                                  />
                                  <img
                                    width={14}
                                    height={14}
                                    src={el_star_alt}
                                    alt="HMC"
                                  />
                                  <img
                                    width={14}
                                    height={14}
                                    src={el_star_alt_gray}
                                    alt="HMC"
                                  />
                                  <img
                                    width={14}
                                    height={14}
                                    src={el_star_alt_gray}
                                    alt="HMC"
                                  />
                                </div>
                              </div>
                              <div className="machines_own row">
                                <Col className="mo_item">
                                  <h4 className="mb-0">
                                    {general_info.resale_count}
                                  </h4>
                                  <p className="mb-0">
                                    {language.machine_sale}
                                  </p>
                                </Col>
                                <Col className="mo_item">
                                  <h4 className="mb-0">
                                    {general_info.rent_count}
                                  </h4>
                                  <p className="mb-0">
                                    {language.machine_rent}
                                  </p>
                                </Col>
                              </div>
                              {/* <a
                                href="/"
                                className="btn btn-primary btn_primary"
                              >
                                CHAT
                              </a> */}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    <div className="rl_products mb-4">
                      <div className="ds_header">
                        <h2>{language.related_products}</h2>
                      </div>
                      <Carousel
                        responsive={responsive_two}
                        centerMode={false}
                        className="rl_product_slider"
                        arrows={false}
                        showDots={true}
                        autoPlay={false}
                        draggable={true}
                        swipeable={true}
                      >
                        {relatedEquipments.map((machine, key) => {
                          return (
                            <div className="cs_item box_item" key={key}>
                              <div className="rl_prods">
                                <div className="rl_prods_img">
                                  <Link
                                    to={`/equipment-details/${machine.machine_activity_hash_id}/${machine.machine_hash_id}/${machine.profile_hash_id}`}
                                    onClick={() => {
                                      let {
                                        machine_hash_id,
                                        machine_activity_hash_id,
                                        profile_hash_id,
                                      } = machine;
                                      this.setState(
                                        {
                                          machine_hash_id,
                                          machine_activity_hash_id,
                                          profile_hash_id,
                                        },
                                        () => {
                                          this.getEquipmentDetails();
                                          this.getAllRelatedEquipments();
                                        }
                                      );
                                    }}
                                  >
                                    <img
                                      alt={machine.model_name}
                                      src={
                                        MEDIA_BASE_URL +
                                        machine.machine_cover_image
                                      }
                                    />
                                  </Link>
                                </div>
                                <div className="rl_prods_data">
                                  <h2>{machine.model_name}</h2>
                                  <div className="rl_specs row">
                                    <div className="rl_specs_item">
                                      <span>
                                        {language.year}{" "}
                                        <strong>{machine.purchase_date}</strong>
                                      </span>
                                    </div>
                                    <div className="rl_specs_item">
                                      <span>
                                        {language.brand}{" "}
                                        <strong>{machine.brand_name}</strong>
                                      </span>
                                    </div>
                                    <div className="rl_specs_item">
                                      <span>
                                        Location
                                        <br />
                                        <strong>
                                          {machine.city}, {machine.state}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>

                    <div className="sp_question">
                      <Card className="hmc_card">
                        <Card.Body>
                          <Row className="align-items-center">
                            <Col md={6} className="spq_left">
                              <Row className="align-items-center">
                                <Col md={4} className="spq_img text-center">
                                  <img
                                    width={100}
                                    height={100}
                                    src={help_icon}
                                    alt="HMC"
                                  />
                                </Col>
                                {/* <Col md={8} className="spq_text">
                                  <h4>Have a specific question?</h4>
                                  <p className="mb-0">
                                    Get answers from our{" "}
                                    <Link to="/#">FAQ</Link> !
                                  </p>
                                </Col> */}
                              </Row>
                            </Col>
                            {/* <Col md={6} className="spq_right text-right">
                              <Link
                                to="/contact-us"
                                className="btn btn-primary btn_primary"
                              >
                                Contact Us
                              </Link>
                            </Col> */}
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(EquipmentDetails);
