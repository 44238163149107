import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Col } from "react-bootstrap";

export default class Advertisiment extends Component {
  render() {
    let { language } = this.props;
    return (
      <Col lg="4" md="4" sm="12">
        <div className="filter_partts addeq_vdeo_partts">
          <h4>{language.how_add_your_equipments_machine_list}</h4>
          <div className="v_player">
            <ReactPlayer
              url="https://www.youtube.com/embed/5kwEhgsNitE"
              className="react-player"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </Col>
    );
  }
}
