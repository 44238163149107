import React, { Component } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { email_ico } from "../../../assets/images";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import validation from "../../../components/Validate/validator";
import "../registration/signup.css";
import OtpInput from "react-otp-input";
import LoadingOverlay from "react-loading-overlay";
import { Login_img } from "../../../assets/images";
import { Link } from "react-router-dom";
import Seo from "../../../components/seo/Seo";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class EmailLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: {},
      isLoading: false,
      isOtp: false,
      onComplete: false,
      timer: "00",
      otp: "",
      otpError: false,

      footer_faq_data: [],
      seo_data: {},
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("api-token")) {
      this.props.history.push({
        pathname: "/dashboard",
      });
    }
    this.getMetaData();
  };

  getMetaData = () => {
    this.setState({ isLoading: true });

    axios
      .get(
        `/web-seo-content?page_key=login&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
      });
  };

  handleChange = (e) => {
    e.persist();

    let { error } = this.state;
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValidForm(this.state.error)) {
      this.getOtp();
    }
  };

  getOtp = () => {
    this.setState({ isLoading: true });
    axios
      .post("/email-login", { email: this.state.email })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.setState({ onComplete: false, isOtp: true });
          this.startTimer(30);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  startTimer = (seconds) => {
    var myTimer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(myTimer);
        this.setState({ onComplete: true });
      }
      this.setState({ timer: seconds > 9 ? seconds : "0" + seconds });
      seconds--;
    }, 1000);
  };

  getProfileDetails = (userData) => {
    const formData = {
      hash_id: userData.hash_id,
    };
    const config = {
      headers: { api_token: localStorage.getItem("api-token") },
    };

    axios
      .post("/profile-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1" && res.data.message === "Success") {
          let { media_base_url } = res.data.data;
          localStorage.setItem("media_base_url", media_base_url);
          res.data.data.profile_data.forEach((profile) => {
            if (profile.profile_type === "1") {
              localStorage.setItem("basicProfile", JSON.stringify(profile));
            } else if (profile.profile_type === "2") {
              localStorage.setItem("sellerProfile", JSON.stringify(profile));
            }
          });
        }
        window.location.pathname = "dashboard";
      })
      .catch(() => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        localStorage.clear();
      });
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });
    // if (otp.length === 4) {
    //   this.verifyOtp(otp);
    // }
  };

  verifyOtp = (otp) => {
    let formData = {
      email: this.state.email,
      otp,
      platform: "web",
    };

    this.setState({ isLoading: true });

    axios
      .post("/otp-check", formData)
      .then((res) => {
        if (res.data.status === "1") {
          if (res.data.data.need_basic_info === "1") {
            let { email } = this.state;
            let { temp_id } = res.data.data;

            // go to create profile
            window.location.pathname = `signup/${email}/${temp_id}`;
          } else {
            // get profile details page
            localStorage.setItem("api-token", res.data.data.api_token_web);
            localStorage.setItem("userdata", JSON.stringify(res.data.data));
            this.getProfileDetails(res.data.data);
          }
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  resetField = () => {
    this.setState({
      isLoading: false,
      error: "",
      isOtp: false,
      onComplete: false,
      timer: "00",
      otp: "",
      otpError: false,
    });
  };

  render() {
    let { seo_data } = this.state;
    let { loading, language } = this.props.language;
    let mobile_login = {};

    if (!loading && language) {
      mobile_login = language.mobile_login;
      language = language.email_login;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {seo_data && (
          <Helmet>
            <title>{seo_data.meta_title}</title>
            <meta name="description" content={seo_data.meta_description} />
            <meta name="keywords" content={seo_data.meta_key} />
            <link rel="canonical" href={seo_data.canonical_url} />
          </Helmet>
        )}
        <div className="home_new">
          <section className="moblie_login_partts">
            <div className="mobile_login_inner_partts">
              <Container>
                <div className="mobile_logininner_box">
                  <div className="mobile_box_left">
                    <h2>{mobile_login.welcome_to_hmc}</h2>
                    <p>{mobile_login.welcome_to_hmc_desc}</p>
                    <div className="login_boximg">
                      <img src={Login_img} alt="HMC" />
                    </div>
                  </div>

                  {this.state.isOtp ? (
                    <div className="mobile_box_right">
                      <div className="mobile_right_inner_box">
                        <h4>{language.email_varification_h}</h4>
                        <div className="otp_field">
                          <p className="verify_no">
                            {language.enter_the_code_we_have_just_sent_to_you}{" "}
                            <b>{this.state.email}</b>{" "}
                            <span onClick={this.resetField}>
                              {language.change_email}
                            </span>
                          </p>
                          <OtpInput
                            onChange={this.handleOtpChange}
                            numInputs={4}
                            inputStyle="inputStyle"
                            isInputNum={true}
                            placeholder="****"
                            hasErrored={this.state.otpError}
                            errorStyle="error"
                            value={this.state.otp}
                          />
                          <div className="countdown">
                            {language.time_left} : {this.state.timer}
                          </div>
                          {this.state.onComplete && (
                            <div className="resend">
                              {language.didnot_receive_the_code}{" "}
                              <span onClick={this.getOtp}>
                                {language.resend}
                              </span>
                            </div>
                          )}
                          <div className="otp_submit mt-2">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                this.resetField();
                                this.setState({
                                  email: "",
                                });
                              }}
                            >
                              {language.cancel}
                            </Button>
                            <Button
                              className="ml-3 btn btn-warning"
                              onClick={() => {
                                if (this.state.otp.length !== 4) {
                                  this.setState({
                                    otpError: true,
                                  });
                                } else {
                                  this.setState({
                                    otpError: false,
                                  });
                                  this.verifyOtp(this.state.otp);
                                }
                              }}
                            >
                              {language.verify}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mobile_box_right">
                      <div className="mobile_right_inner_box">
                        <h2>
                          {mobile_login.login}/
                          {language.login_using_email_register_account}
                        </h2>

                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group
                            className="login_box_partts"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              {language.login_using_email_label_email}
                              <span className="required">*</span>
                            </Form.Label>
                            <div className="react_textfield">
                              <Form.Control
                                type="email"
                                name="email"
                                className="control_field"
                                placeholder={
                                  language.login_using_email_plh_enter_email
                                }
                                required
                                validaterule={["isEmail"]}
                                validatemsg={["Enter a valid email address"]}
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                              <p className="mt-2" style={{ color: "red" }}>
                                {this.state.error.email &&
                                  language.login_using_email_errormsg_enter_valid_email}
                              </p>

                              <span className="text_111">
                                <img src={email_ico} alt="HMC" />
                              </span>
                            </div>
                          </Form.Group>

                          <Button
                            type="submit"
                            className="login_submit_but commn_button"
                            disabled={this.state.isLoading}
                          >
                            {language.login_using_email_login_btn}
                          </Button>
                        </Form>
                        <div className="login_or_partss">
                          <span>{mobile_login.login_label_or}</span>
                        </div>
                        <div className="login_using_email_id">
                          <Link to="/login">
                            <Button className="login_submit_but commn_button">
                              {
                                language.login_using_email_login_using_mobile_btn
                              }
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </section>
          <Seo {...this.state} />
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(EmailLogin);
