import React, { Component } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import {
  excel_preview,
  pdf_preview,
  Up_doc_icon_img,
  word_preview,
} from "../../../assets/images";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import { getFileExtension } from "../../../utils/Helper";
import _ from "lodash";
import config from "../../../config/apiHeader";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class PaymentInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      lead_hash_id: "",

      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let { params } = this.props.match;
    if (params) {
      let { lead_hash_id } = params;
      this.setState({ lead_hash_id }, async () => {
        let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
        if (sub_menus) {
          this.getAllPaymentInvoice();
        } else {
          message.error(
            "Access Denied: Your account does not have sufficient permissions to perform the requested action."
          );
          this.props.history.push({
            pathname: "/",
          });
        }
      });
    }
  };

  getAllPaymentInvoice = () => {
    let { lead_hash_id } = this.state;

    const formData = {
      lead_hash_id,
    };
    this.setState({ isLoading: true });

    axios
      .post("/get-lead-payment-invoice", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let old_attachment = [];
          let old_docs = [];
          res.data.data.forEach((doc) => {
            old_attachment.push(doc.id);

            let url = doc.attachment_url;
            let preview;

            if (url.endsWith(".doc") || url.endsWith(".docx")) {
              preview = word_preview;
            } else if (url.endsWith(".pdf")) {
              preview = pdf_preview;
            } else {
              preview = excel_preview;
            }

            old_docs.push({
              url,
              id: doc.id,
              name: doc.attachment_title,
              preview,
            });
          });

          this.setState({ old_attachment, old_docs });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  handleDocument = async (e) => {
    e.persist();

    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    let { files } = e.target;

    let validExtension = ["doc", "docx", "pdf", "xls", "xlsx"];

    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);

        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          let preview;
          if (extension === "docx" || extension === "doc") {
            preview = word_preview;
          } else if (extension === "pdf") {
            preview = pdf_preview;
          } else {
            preview = excel_preview;
          }
          equipment_docs.push(file);

          equipment_docs_preview.push({ name: file.name, preview });
          attachment_title.push(file.name);

          this.setState({
            equipment_docs,
            equipment_docs_preview,
          });
        } else {
          message.error(file.name + " is not a valid document");
        }
      }
    });
  };

  uploadDocuments = () => {
    let {
      hash_id,
      lead_hash_id,
      attachment_title,
      old_attachment,
      equipment_docs,
    } = this.state;

    let formData = new FormData();

    formData.append("lead_hash_id", lead_hash_id);
    formData.append("user_hash_id", hash_id);

    old_attachment.forEach((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    attachment_title.forEach((title, key) => {
      formData.append(`attachment_title[${key}]`, title);
    });

    equipment_docs.forEach((title, key) => {
      formData.append(`lead_payment_attachment[${key}]`, title);
    });

    formData.append("attachment_type", 2);
    formData.append("medium", "web");
    formData.append("section_name", "payment_invoice");

    this.setState({ isLoading: true });

    axios
      .post("/lead-payment-invoice-upload", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.getAllPaymentInvoice();
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let { old_docs, equipment_docs_preview } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.payment_invoice;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel">
                    <div className="ds_header">
                      <h2>{language.payment_invoice_h}</h2>
                    </div>

                    <Form.Group>
                      <div className="cert_box">
                        {old_docs &&
                          old_docs.map((doc, index) => {
                            return (
                              <a
                                href={doc.url}
                                target="_blank"
                                className="cert_box_inner"
                                key={index}
                              >
                                <div className="cret_file">
                                  <img alt="HMC" src={doc.preview} />
                                </div>
                                <Form.Label>{doc.name}</Form.Label>
                              </a>
                            );
                          })}
                        {equipment_docs_preview &&
                          equipment_docs_preview.map((doc, index) => {
                            return (
                              <div className="cert_box_inner" key={index}>
                                <div className="cret_file">
                                  <img alt="HMC" src={doc.preview} />
                                </div>
                                <Form.Label>{doc.name}</Form.Label>
                                <div className="click_drop"></div>
                              </div>
                            );
                          })}

                        <div className="cert_box_upload">
                          <div className="upload_btn_wrappererer">
                            <button className="btnner">
                              <img alt="HMC" src={Up_doc_icon_img} />
                            </button>
                            <Form.Control
                              type="file"
                              accept=".doc, .docx, .pdf, .xls,.xlsx"
                              onChange={this.handleDocument}
                              multiple
                            />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <div className="d-flex justify-content-end sub_container">
                      <button
                        type="submit"
                        className="submit_but"
                        onClick={this.uploadDocuments}
                      >
                        {language.submit_btn}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(PaymentInvoice);
